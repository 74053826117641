import React from 'react';
import Table from "react-bootstrap/Table";

const CashFlowDetails = ({paymentList}) => {

    const totalSumAmount = Object.values(paymentList).reduce((sum, item) => sum + item.final_amount, 0);

    // console.log(totalSumAmount)

    return (
        <div className='mt-5'>

            {Object.keys(paymentList).length > 0 ?
                <div>
                    <div>
                        {
                            Object.keys(paymentList).map((serviceName) => {
                                console.log(serviceName)
                                return (
                                    <div key={serviceName}>
                                        <h6>Cash Flow From {serviceName}</h6>

                                        <Table striped bordered hover>
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>user ID</th>
                                                <th> Name</th>
                                                <th> Transaction ID</th>
                                                <th>Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {
                                                paymentList[serviceName].data.map((paymentData, i) => (
                                                    <tr key={paymentData?.id}>
                                                        <td>{i + 1}</td>
                                                        <td>{paymentData?.user_id}</td>
                                                        <td> {paymentData?.user?.f_name} </td>
                                                        <td> {paymentData?.tran_id} </td>
                                                        <td> {paymentData?.total_amount} TK</td>
                                                    </tr>
                                                ))
                                            }


                                            </tbody>
                                        </Table>
                                        <h6 className={'text-end'}>Total {serviceName} Payment
                                            : {paymentList[serviceName]?.final_amount} TK </h6>

                                    </div>)
                            })
                        }
                    </div>

                    <hr/>

                    <h5 className={'text-end'}>Total Sum Amount : {totalSumAmount} TK</h5>
                </div> :
                <h5 className={'text-center'}>No Data Found</h5>
            }


        </div>
    );
};

export default CashFlowDetails;