import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../../Context/UserContextAPI";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function AdSectionList() {
  const { currentUser, accessPerm, loading } = useContext(UserContext);
  const [adSectionList, setAdSectionList] = useState([]);
  const [editModalData, setEditModalData] = useState({});
  const [deleteModalData, setDeleteModalData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [isModalOn, setModalOn] = useState(false);


  const handleCreateNewAdSection = async (e) => {
    e.preventDefault();

    const form = e.target;
    const name = form.name.value;
    const info = form.info.value;
    const status = form.status.value;

    const adSection = {
      name: name,
      info: info,
      status: status === "true" ? 1 : 0,
    };
    console.log("adSection", adSection);
    
    try {
        const res = await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/adsections/`, adSection)
        console.log(res)
        if (res.status === 201) {
          console.log(res.data?.data)
        toast.success("New Ad added successfully!!");
        setRefresh(!refresh);
        setModalOn(false)
      } 
    } catch (error) {
        console.log(error)
    }
  };

  const handlerOnEditFormSubmit = async (e) => {
    e.preventDefault();
    try {
        const form = e.target;
    const name = form.name.value;
    const info = form.info.value;
    const status = form.status.value;

    const adSection = {
      name: name,
      info: info,
      status: status === "true" ? 1 : 0,
    };

    const res = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/adsections/${editModalData.id}`,
        adSection,
      );
  
      console.log(res);
      if (res.status === 200) {
        toast.success("ad Updated successfully!!");
        setRefresh(!refresh);
      } else {
        toast.error(res?.data?.message);
        setRefresh(!refresh);
      }
    } catch (error) {
        toast.error('Update failed');
        console.log(error.message)
    }
  };

  const handlerOnDelete = async () => {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/adsections/${deleteModalData.id}`
    );
    // console.log(res);
    if (res.status === 200) {
      toast.success("ad Deleted successfully!!");
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    const fetchAPI = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/adsections`
      );
      const data = response.data.data;
      setAdSectionList(data);
      setRefresh(refresh);
    };
    fetchAPI();
  }, [refresh]);

  const columns = [
    {
      key: "name",
      text: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "info",
      text: "Info",
      className: "info",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      sortable: true,
      cell: (record) => {
        return <>{record.status === 1 ? "Active" : "Inactive"}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        // console.log(record);
        return (
          <>
            {/* Edit Modal Trigger Button */}
            {accessPerm(41, 2) && (
              <button
                type="button"
                onClick={() => {
                  setEditModalData(record);
                  if (record.status === 1) {
                    setIsChecked(true);
                  } else {
                    setIsChecked(false);
                  }
                }}
                className="btn btn-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target={`#editAdSectionModal-${record.id}`}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
            )}

            {/* Delete Modal Trigger Button */}
            {accessPerm(41, 3) && (
              <button
                type="button"
                onClick={() => setDeleteModalData(record)}
                className="btn btn-danger btn-sm"
                data-bs-toggle="modal"
                data-bs-target={`#deleteAdSectionModal-${record.id}`}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-trash"></i>
              </button>
            )}

            {/* Edit Modal Body */}

            <div
              className="modal fade"
              id={`editAdSectionModal-${record.id}`}
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit Ad Section 
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <form onSubmit={(e) => handlerOnEditFormSubmit(e)}>
                    <div className="modal-body">
                      <div className="row mb-3 d-flex align-items-center">
                        <label className="col-sm-3 col-form-label">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="name"
                            defaultValue={record.name}
                            className="form-control"
                            // required
                          />
                        </div>
                      </div>
                      <div className="row mb-3 ">
                        <label className="col-sm-3 col-form-label">Info</label>
                        <div className="col-sm-9">
                          <textarea
                            name="info"
                            defaultValue={record.info}
                            className="form-control w-100"
                            rows="3"
                            maxLength="200"
                          ></textarea>
                        </div>
                      </div>

                      <div className="row mb-3 d-flex align-items-center justify-content-end">
                        <div className="col-sm-9">
                          <div className="col-sm-12">
                            <div
                              className="  d-flex align-items-center  justify-content-end"
                              defaultChecked={
                                record.status === 1 ? true : false
                              }
                            >
                              <input
                                className="form-check-input mt-0 me-2"
                                type="checkbox"
                                defaultChecked={
                                  record.status === 1 ? true : false
                                }
                                name="status"
                                value={isChecked}
                                onChange={() => setIsChecked(!isChecked)}
                                id={`flexCheckChecked-${record.id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexCheckChecked-${record.id}`}
                              >
                                Active
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                      >
                        Update changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Delete Modal Body */}

            <div
              className="modal fade"
              id={`deleteAdSectionModal-${record.id}`}
              tabIndex={-1}
              style={{ display: "none" }}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Delete This Ad Section</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body pb-0">
                    <div className="mb-3 row ">
                      <div className="col-sm-10">
                        <p>Are you sure you want to delete?</p>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handlerOnDelete(deleteModalData)}
                      data-bs-dismiss="modal"
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <>
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <div className="card-box">
                <h6 className="mb-0 text-uppercase">Ad Section List</h6>
                <div className="col">
                  {/* Create Role trigger modal Button */}

                  {accessPerm(41, 1) && (
                    <button
                      type="button"
                      onClick={() => setModalOn(true)}
                      className="btn btn-primary"
                    //   data-bs-toggle="modal"
                    //   data-bs-target="#createTransectionTypeModal"
                    >
                      <i className="fa-solid fa-plus"></i> Add New
                    </button>
                  )}
                </div>
              </div>

              <hr />

              <ReactDatatable
                // config={config}
                records={adSectionList}
                columns={columns}
                // extraButtons={extraButtons}
              />
            </div>
          </div>
        </div>
      </>

      {/* Create Modal Body */}
      <>
      <Modal show={isModalOn} onHide={() => setModalOn(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Ad Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={(e) => handleCreateNewAdSection(e)}>
              <div className="modal-body">
                <div className="row mb-3 d-flex align-items-center">
                  <label className="col-sm-3 col-form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label">Info</label>
                  <div className="col-sm-9">
                    <textarea
                      name="info"
                      className="form-control w-100"
                      rows="3"
                      maxLength="200"
                      // required={true}
                    ></textarea>
                  </div>
                </div>

                <div className="row mb-3 d-flex align-items-center justify-content-end">
                  <div className="col-sm-9">
                    <div className="col-sm-12">
                      <div className=" d-flex align-items-center  justify-content-end">
                        <input
                          className="form-check-input mt-0 me-2"
                          type="checkbox"
                          checked={isChecked}
                          name="status"
                          value={isChecked}
                          onChange={() => setIsChecked(!isChecked)}
                          id="flexCheckChecked"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Active
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={()=>setModalOn(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  
                >
                  Save changes
                </button>
              </div>
            </form>
        </Modal.Body>
      </Modal>
      </>
      
     
    </>
  );
}
