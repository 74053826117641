import React from 'react';
import RoomInputForm from "./RoomInputForm";
import axios from "axios";
import {toast} from "react-hot-toast";

const UpdateRoom = ({isOpen, onClose, editData, hospitalList, accommodationList, refresh}) => {

    const updateHandler = (formData) => {
        axios
            .put(`${process.env.REACT_APP_API_BASE_URL}/room/${editData?.id}`, formData)
            .then((response) => {
                console.log(response.data);
                if (response.status === 200) {
                    toast.success("Room updated successfully");
                    onClose();
                    refresh();
                }
            })
            .catch((error) => console.log(error));
    }

    return (
        <div>
            <RoomInputForm
                isOpen={isOpen}
                onClose={onClose}
                hospitalList={hospitalList}
                accommodationList={accommodationList}
                editData={editData}
                apiHandler={updateHandler}
            />
        </div>
    );
};

export default UpdateRoom;