import React from 'react';
import useFetchData from "../../hooks/useFetchData";
import CardLayout from "../Layout/CardLayout";
import ReactTable from "../ReactDataTable/ReactTable";

const SubscriptionList = ({user_id}) => {
    const {data} = useFetchData(`health-manager/getAllSubs/${user_id}`)

    const columns = [
        {
            key: "subscription_type",
            text: "Subscription Type",
            align: "center",
            sortable: true,
        },
        {
            key: "package_id",
            text: "Package",
            align: "center",
            sortable: false,
            cell: row => <span>{row?.package?.name}</span>
        },
        {
            key: "package_id",
            text: "Package",
            align: "center",
            sortable: false,
            cell: row => <span>{row?.package?.price} TK</span>
        },
        {
            key: "purchase_date",
            text: "Purchase Date",
            align: "center",
            sortable: false,
            cell: row => <span>{row?.purchase_date?.split("T")[0]}</span>
        },
        {
            key: "expiry_date",
            text: "Expiry Date",
            align: "center",
            sortable: false,
            cell: row => <span>{row?.expiry_date?.split("T")[0]}</span>
        },
        {
            key: "status",
            text: "Status",
            align: "center",
            sortable: true,
            cell: row => row.status ? "active" : "Inactive"
        },
    ]

    console.log(data?.data);


    return (
        <section>
            <CardLayout title={"Investigation History"}>
                <ReactTable
                    data={data?.data}
                    columns={columns}
                />
            </CardLayout>

        </section>
    );
};

export default SubscriptionList;