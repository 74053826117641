const bloodReceivedStatus = [
  {
    status: 0,
    title: "Pending",
  },
  {
    status: 1,
    title: "Accepted",
  },
  {
    status: 2,
    title: "Donated",
  },
];

export const getBloodReceivedStatus = (status) => {
  return (
    bloodReceivedStatus.find((b) => b.status === status)?.title ?? "Complete"
  );
};

// test / investigation request

export const investigationStatus = [
  { status: 0, title: "Requested" },
  { status: 1, title: "Diagnosis Center Respond" },
  { status: 2, title: "User Confirm" },
  { status: 3, title: "User Canceled" },
  { status: 4, title: "User Test Scheduled" },
  { status: 5, title: "Diagnosis Collected" },
  { status: 6, title: "Report Uploaded" },
  { status: 7, title: "Diagnosis Marked Complete" },
];

// Example usage:
export const getInvestigationStatusName = (status) => {
  const statusObject = investigationStatus.find(
    (item) => item.status === status,
  );
  return statusObject ? statusObject.title : "Unknown";
};

// medicine request status

export const medicineRequestStatus = [
  { status: 0, title: "Requested" },
  { status: 1, title: "Pharmacy respond" },
  { status: 2, title: "User Confirm" },
  { status: 3, title: "User Canceled" },
  { status: 4, title: "Pharmacy Ready the Medicine" },
  { status: 5, title: "Delivery Person Accepted" },
  { status: 6, title: "Delivery Person Collected the medicine" },
  { status: 7, title: "Delivery Complete" },
  { status: 8, title: "Unknown" },
];

export const getMedicineRequestStatus = (status) => {
  const statusObject = medicineRequestStatus.find(
    (item) => item.status === status,
  );
  return statusObject ? statusObject.title : "Unknown";
};

// treatments request status

export const treatmentReqStatus = [
  { status: 0, title: "Pending", className: "" },
  { status: 1, title: "Requested", className: "text-primary" },
  { status: 2, title: "Accepted", className: "text-info" },
  { status: 3, title: "Canceled", className: "text-danger" },
  { status: 4, title: "Confirmed", className: "text-purple" },
  { status: 5, title: "Completed", className: "text-success" },
];

// Filter function to get the title by status argument
export const getTreatmentRequestTitle = (status) => {
  const matchingStatus = treatmentReqStatus.find(
    (item) => item.status === status,
  );
  return matchingStatus
    ? { title: matchingStatus.title, className: matchingStatus.className }
    : { title: "unknown", className: "" };
};

// ambulance request status

const ambulanceRequestStatus = [
  { status: 0, title: "Requested" },
  { status: 1, title: "Ambulance respond" },
  { status: 2, title: "User Confirm" },
  { status: 3, title: "Ambulance came to user destinations" },
  { status: 4, title: "Started travel" },
  { status: 5, title: "Travel Ended" },
  { status: 6, title: "Completed" },
  { status: 10, title: "Cancelled" },
];

// Example usage:

export const getAmbulanceRequestTitle = (status) => {
  const matchingStatus = ambulanceRequestStatus.find(
    (item) => item.status === status,
  );
  return matchingStatus?.title ?? "pending";
};

// appointment request status

const appointmentReqStatus = [
  { status: 0, title: "Requested" },
  { status: 1, title: "Confirmed" },
  { status: 2, title: "Completed" },
  { status: 3, title: "Completed" },
];

// Function to get the title based on status
export function getAppointmentReqTitle(status) {
  const statusObject = appointmentReqStatus.find(
    (item) => item.status === status,
  );
  return statusObject ? statusObject.title : "Unknown";
}

/// diagnosis request status

const diagnosisRequestStatus = [
  { status: 0, label: "Requested" },
  { status: 1, label: "Diagnostic center respond" },
  { status: 2, label: "User Confirm" },
  { status: 3, label: "User Canceled" },
  { status: 4, label: "Diagnostic Marked Complete" },
  { status: 5, label: "Schedule" },
  { status: 6, label: "Report Uploaded" },
  { status: 7, label: "Completed" },
];

export const getDiagnosisRequestStatus = (status) => {
  const statusObject = diagnosisRequestStatus.find(
    (item) => item.status === status,
  );
  return statusObject ? statusObject.label : "unknown";
};

