import { createBrowserRouter } from "react-router-dom";
import AccountHead from "../Pages/Accounts/AccountHead/AccountHead";
import HeadClassification from "../Pages/Accounts/HeadClassification/HeadClassification";
import HeadGroup from "../Pages/Accounts/HeadGroup/HeadGroup";
import HeadIdentifier from "../Pages/Accounts/HeadIdentifier/HeadIdentifier";
import HeadType from "../Pages/Accounts/HeadType/HeadType";
import TransectionType from "../Pages/Accounts/TransectionType/TransectionType";
import CollectionLabel from "../Pages/CollectionLabel/CollectionLabel";
import ForgetPasswordPage from "../Pages/ForgetPasswordPage/ForgetPasswordPage";
import Homepage from "../Pages/Homepage/Homepage";
import InvestigationCategory from "../Pages/Investigation/InvestigationCategory/InvestigationCategory";
import InvestigationGroup from "../Pages/Investigation/InvestigationGroup/InvestigationGoup";
import AddNewInv from "../Pages/Investigation/InvestigationManagement/AddNewInv";
import EditInvestigation from "../Pages/Investigation/InvestigationManagement/EditInvestigation";
import InvestigationManagement from "../Pages/Investigation/InvestigationManagement/InvestigationManagement";
import TestManagement from "../Pages/Investigation/Test/TestManagement";
import MailBoxPage from "../Pages/MailboxPage/MailBoxPage";
import OtpVerification from "../Pages/OtpVerification/OtpVerification";
import ProfilePage from "../Pages/ProfilePage/ProfilePage";
import ProjectsPage from "../Pages/ProjectsPage/ProjectsPage";
import ResetPasswordPage from "../Pages/ResetPasswordPage/ResetPasswordPage";
import City from "../Pages/Settings/City/City";
import Country from "../Pages/Settings/Country/Country";
import DrugGroupList from "../Pages/Settings/DrugGroupList/DrugGroupList";
import DrugList from "../Pages/Settings/DrugList/DrugList";
import GenderPage from "../Pages/Settings/GenderPage/GenderPage";
import HealthProductsCategoryList from "../Pages/Settings/HealthProductsCategoryList/HealthProductsCategoryList";
import HealthProductsPage from "../Pages/Settings/HealthProductsPage/HealthProductsPage";
import MaritialStatusSettings from "../Pages/Settings/MaritalStatus/MaritalStatusSettings";
import ProfessionSettingsPage from "../Pages/Settings/ProfessionSettingsPage/ProfessionSettingsPage";
import Room from "../Pages/Settings/RoomManagement/Room";
import SettingsPage from "../Pages/Settings/SettingsPage/SettingsPage";
import State from "../Pages/Settings/State/State";
import StaticContentGroupPage from "../Pages/Settings/StaticContentGroupPage/StaticContentGroupPage";
import StaticContentPage from "../Pages/Settings/StaticContentPage/StaticContentPage";
import UOMPage from "../Pages/Settings/UOMPage/UOMPage";
import Signin from "../Pages/Signin/Signin";
import Signup from "../Pages/Signup/Signup";
import ActivityListPage from "../Pages/UserManagement/ActivityListPage/ActivityListPage";
import ModuleListPage from "../Pages/UserManagement/ModuleListPage/ModuleListPage";
import RoleListPage from "../Pages/UserManagement/RoleListPage/RoleListPage";
import RolePermissionPage from "../Pages/UserManagement/RolePermissionPage/RolePermissionPage";
import UserListPage from "../Pages/UserManagement/UserListPage/UserListPage";
import DashboardLayout from "../components/Layout/DashboardLayout";
import PrivateRouter from "./Private/PrivateRouter";
import TermsConditions from "../Pages/UserManagement/TermsConditions/TermsConditions";
import ServiceCategory from "../Pages/UserManagement/ServiceCategory/ServiceCategory";
import ServiceCategoryList from "../Pages/UserManagement/ServiceCategoryList/ServiceCategoryList";
import ContactMessages from "../Pages/Services/ContactMessages/ContactMessages";
import CreatePackage from "../Pages/Packages/CreatePackage";
import ManagePackage from "../Pages/Packages/ManagePackage";
import EditPackage from "../Pages/Packages/EditPackage";
import FileUploadTypePage from "../Pages/Settings/FileUploadTypePage/FileUploadTypePage";
import DiagoRequestList from "../Pages/Diagnosis/DiagoRequestList";
import SubscribedUser from "../Pages/HealthManager/SubscribedUser";
import UserByRoles from "../components/Roles/UserByRoles";
import BloodRequestList from "../Pages/blood/BloodRequestList";
import PackageCategoryList from "../Pages/Packages/PakageCategory/PackageCategory";
import AdSectionList from "../Pages/Advertisement/AdSectionList";
import AdvertisementList from "../Pages/Advertisement/AdvertisementList";
import SuccessfulTransaction from "../Pages/transiction/SuccessfulTransaction";
import FailedTransaction from "../Pages/transiction/FailedTransaction";
import PaymentInvoice from "../Pages/transiction/PaymentInvoice";
import UploadDonor from "../Pages/Accounts/uploadDonor/UploadDonor";
import MedicineRequestList from "../Pages/MedicineRequest/MedicineRequestList";
import MedicineStock from "../Pages/MedicineStock/MedicineStock";
import HealthProductsRequest from "../Pages/HealthProducts/HealthProductsRequest";
import OrderedProductsList from "../Pages/HealthProducts/OrderedProductsList";
import AmbulanceRequestList from "../Pages/AmbulanceRequest/AmbulanceRequestList";
import AppointmentReqList from "../Pages/Appointments/AppointmentReqList";
import AccommodationType from "../Pages/Settings/AccommodationType/AccommodationType";
import TreatmentRequestList from "../Pages/TreatmentRequest/TreatmentRequestList";
import CashFlowStatements from "../Pages/FinancialReports/CashflowStatements";
import IncomeStatements from "../Pages/FinancialReports/IncomeStatements";
import KnowledgeBaseCategory from "../Pages/KnowledgeBase/KnowledgeBaseCategory/KnowledgeBaseCategory";
import KnowledgeBaseList from "../Pages/KnowledgeBase/KnowledgeBaseList/KnowledgeBaseList";

// account imports
import VoucherEntry from "../Pages/Accounts/VoucherEntry/VoucherEntry";
import VoucherList from "../Pages/Accounts/VoucherList/VoucherList";
import TrailBalance from "../Pages/Accounts/TrailBalance/TrailBalance";
import HeadBalance from "../Pages/Accounts/HeadBalance/HeadBalance";
import Ledger from "../Pages/Accounts/Ledger/Ledger";
import ProfitLossStatement from "../Pages/Accounts/ProfitLossStatement/ProfitLossStatement";
import StatementFinancialPosition from "../Pages/Accounts/StatementFinancialPosition/StatementFinancialPosition";
import DonorList from "../Pages/blood/DonorList";
import CashOnDelivery from "../Pages/Settings/CashOnDelivery/CashOnDelivery";
import DeliveryCharge from "../Pages/Settings/DeliveryCharge/DeliveryCharge";
import CreateKnowledgeBase from "../Pages/KnowledgeBase/KnowledgeBaseList/CreateKnowledgeBase";
import UpdateKnowledgeBase from "../Pages/KnowledgeBase/KnowledgeBaseList/UpdateKnowledgeBase";
import ManageByHub from "../Pages/Settings/ManageByHub/ManageByHub";
import AddHubManagement from "../Pages/HubManagement/AddHubManagement";
import HubList from "../Pages/HubManagement/HubList";
import UpdateHub from "../Pages/HubManagement/UpdateHub";
import UploadDoctor from "../Pages/UserManagement/UploadDoctor/UploadDoctor";

const router = createBrowserRouter([
  {
    index: true,
    path: "/",
    element: <Signin />,
  },
  {
    path: "signin",
    element: <Signin />,
  },
  {
    path: "signup",
    element: <Signup />,
  },
  {
    path: "forget-password",
    element: <ForgetPasswordPage />,
  },
  {
    path: "reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "otp-verification",
    element: <OtpVerification />,
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Homepage />,
      },
      {
        path: "profile/:id",
        element: <ProfilePage />,
      },
      {
        path: "mail",
        element: <MailBoxPage />,
      },
      {
        path: "projects",
        element: <ProjectsPage />,
      },
      {
        path: "investigation/investigation-groups",
        element: (
          <PrivateRouter props={21}>
            <InvestigationGroup />
          </PrivateRouter>
        ),
      },

      {
        path: "investigation/investigation-categories",
        element: (
          <PrivateRouter props={22}>
            <InvestigationCategory />
          </PrivateRouter>
        ),
      },
      {
        path: "investigation/investigation-management",
        element: (
          <PrivateRouter props={23}>
            <InvestigationManagement />
          </PrivateRouter>
        ),
      },
      {
        path: "investigation/test-management",
        element: (
          <PrivateRouter props={24}>
            <TestManagement />
          </PrivateRouter>
        ),
      },
      {
        path: "investigation/add-new",
        element: <AddNewInv />,
      },
      {
        path: "investigation/update",
        element: <EditInvestigation />,
      },
      {
        path: "transection-type",
        element: (
          <PrivateRouter props={26}>
            <TransectionType />
          </PrivateRouter>
        ),
      },
      {
        path: "head-type",
        element: (
          <PrivateRouter props={30}>
            <HeadType />
          </PrivateRouter>
        ),
      },
      {
        path: "head-identifier",
        element: (
          <PrivateRouter props={27}>
            <HeadIdentifier />
          </PrivateRouter>
        ),
      },
      {
        path: "head-classification",
        element: (
          <PrivateRouter props={28}>
            <HeadClassification />
          </PrivateRouter>
        ),
      },
      {
        path: "head-group",
        element: (
          <PrivateRouter props={29}>
            <HeadGroup />
          </PrivateRouter>
        ),
      },
      {
        path: "account-head",
        element: (
          <PrivateRouter props={31}>
            <AccountHead />
          </PrivateRouter>
        ),
      },
      {
        path: "account/voucher-entry",
        element: <VoucherEntry />,
      },
      {
        path: "account/voucher-list",
        element: <VoucherList />,
      },
      {
        path: "account/trail-balance",
        element: <TrailBalance />,
      },
      {
        path: "account/ledger",
        element: <Ledger />,
      },
      {
        path: "account/head-balance",
        element: <HeadBalance />,
      },
      {
        path: "account/profit-loss-statement",
        element: <ProfitLossStatement />,
      },
      {
        path: "account/statement-financial-position",
        element: <StatementFinancialPosition />,
      },
      {
        path: "users",
        element: (
          <PrivateRouter props={5}>
            <UserListPage />
          </PrivateRouter>
        ),
      },
      {
        path: "roles",
        element: (
          <PrivateRouter props={4}>
            <RoleListPage />
          </PrivateRouter>
        ),
      },
      {
        path: "user-management/role-permissions",
        element: (
          <PrivateRouter props={6}>
            <RolePermissionPage />
          </PrivateRouter>
        ),
      },
      {
        path: "user-management/terms-conditions",
        element: <TermsConditions />,
      },
      {
        path: "user-management/service-category",
        element: <ServiceCategory />,
      },
      {
        path: "user-management/service-category-list",
        element: <ServiceCategoryList />,
      },
      { path: "services/contact-messages", element: <ContactMessages /> },
      { path: "package/create-package", element: <CreatePackage /> },
      { path: "package/manage-package", element: <ManagePackage /> },
      { path: "package/edit-package", element: <EditPackage /> },
      { path: "package/package-category", element: <PackageCategoryList /> },
      {
        path: "module",
        // element: <ModuleListPage />,
        element: (
          <PrivateRouter props={3}>
            <ModuleListPage />
          </PrivateRouter>
        ),
      },
      {
        path: "activity",
        element: (
          <PrivateRouter props={2}>
            <ActivityListPage />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/file-upload-types",
        element: (
          <PrivateRouter props={7}>
            <FileUploadTypePage />
          </PrivateRouter>
        ),
      },
      {
        path: "static-content-groups",
        element: (
          <PrivateRouter props={7}>
            <StaticContentGroupPage />
          </PrivateRouter>
        ),
      },
      {
        path: "static-content",
        element: (
          <PrivateRouter props={8}>
            <StaticContentPage />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/health-products-category",
        element: (
          <PrivateRouter props={9}>
            <HealthProductsCategoryList />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/health-products",
        element: (
          <PrivateRouter props={10}>
            <HealthProductsPage />
          </PrivateRouter>
        ),
      },
      {
        path: "setting/drug-groups",
        element: (
          <PrivateRouter props={11}>
            <DrugGroupList />
          </PrivateRouter>
        ),
      },
      {
        path: "setting/drugs",
        element: (
          <PrivateRouter props={12}>
            <DrugList />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/room-management",
        element: (
          <PrivateRouter props={13}>
            <Room />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/uom",
        element: (
          <PrivateRouter props={15}>
            <UOMPage />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/gender",
        element: (
          <PrivateRouter props={14}>
            <GenderPage />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/professions",
        element: (
          <PrivateRouter props={16}>
            <ProfessionSettingsPage />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/merital-status",
        element: (
          <PrivateRouter props={17}>
            <MaritialStatusSettings />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/country",
        element: (
          <PrivateRouter props={18}>
            <Country />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/state",
        element: (
          <PrivateRouter props={19}>
            <State />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/city",
        element: (
          <PrivateRouter props={20}>
            <City />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/system-settings",
        element: (
          <PrivateRouter props={1}>
            <SettingsPage />
          </PrivateRouter>
        ),
      },
      {
        path: "collection-label",
        element: <CollectionLabel />,
      },
      {
        path: "blood/requestlist",
        element: <BloodRequestList />,
      },
      {
        path: "diagnosis/requestlist",
        element: <DiagoRequestList />,
      },
      {
        path: "health-manager/subscribed-user",
        element: <SubscribedUser />,
      },
      {
        path: "roles/:role_id",
        element: <UserByRoles />,
      },
      {
        path: "advertisement/adsection",
        element: <AdSectionList />,
      },
      {
        path: "advertisement/advertisement-list",
        element: <AdvertisementList />,
      },
      {
        path: "transaction/successful",
        element: <SuccessfulTransaction />,
      },
      {
        path: "transaction/failed",
        element: <FailedTransaction />,
      },
      {
        path: "transaction/invoice/:tranId",
        element: <PaymentInvoice />,
      },
      {
        path: "upload-donor",
        element: <UploadDonor />,
      },
      {
        path: "upload-doctor",
        element: <UploadDoctor />,
      },
      {
        path: "medicine/request",
        element: <MedicineRequestList />,
      },
      {
        path: "medicine/stock",
        element: <MedicineStock />,
      },
      {
        path: "health-products/request",
        element: <HealthProductsRequest />,
      },
      {
        path: "health-products/request/:order_no",
        element: <OrderedProductsList />,
      },
      {
        path: "ambulance/request",
        element: <AmbulanceRequestList />,
      },
      {
        path: "appointments/request",
        element: <AppointmentReqList />,
      },
      {
        path: "setting/accommodation-type",
        element: <AccommodationType />,
      },
      {
        path: "treatments/request",
        element: <TreatmentRequestList />,
      },
      {
        path: "financial/cashflow-statements",
        element: <CashFlowStatements />,
      },
      {
        path: "financial/income-statements",
        element: <IncomeStatements />,
      },
      {
        path: "knowledge-base/category",
        element: <KnowledgeBaseCategory />,
      },
      {
        path: "knowledge-base/list",
        element: <KnowledgeBaseList />,
      },
      {
        path: "knowledge-base/list/add",
        element: <CreateKnowledgeBase />,
      },
      {
        path: "knowledge-base/list/edit/:id",
        element: <UpdateKnowledgeBase />,
      },
      {
        path: "blood/donor-list",
        element: <DonorList />,
      },
      {
        path: "settings/cash-on-delivery",
        element: <CashOnDelivery />,
      },
      {
        path: "settings/delivery-charge",
        element: <DeliveryCharge />,
      },

      {
        path: "settings/manage-by-hub",
        element: <ManageByHub />,
      },
      {
        path: "hub-management/add",
        element: <AddHubManagement />,
      },
      {
        path: "hub-management",
        element: <HubList />,
      },
      {
        path: "hub-management/edit/:id",
        element: <UpdateHub />,
      },
    ],
  },
]);

export default router;
