import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../../Context/UserContextAPI";
import axios from "axios";
import formatDateTime from "../../utils/DateTimeFormate";
import {Form} from "react-bootstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import {dateFormate, format24to12} from "../../utils/timeAndDateFunction";

const AppointmentReqList = () => {
    const [getAllAmbulanceRequest, setAllAmbulanceRequest] = useState([]);
    const [filteredAmbulanceRequest, setFilteredAmbulanceRequest] = useState([]);

    const {accessPerm} = useContext(UserContext);

    useEffect(() => {
        (async function () {
            try {
                const {data} = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/request/appointment/admin/get-all`,
                );
                console.log("data", data.data);
                setAllAmbulanceRequest(data.data);
                setFilteredAmbulanceRequest(data.data);
            } catch (error) {
                console.log("error while get all req list", error);
            }
        })();
    }, []);

    const columns = [
        {
            key: "req_no",
            text: "Request No:",
            align: "center",
            sortable: true,
        },
        {
            key: "reg_no",
            text: "Registration No:",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.registration_no}</span>;
            },
        },
        {
            key: "req_by",
            text: "Request By",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.f_name}</span>;
            },
        },
        {
            key: "mobile",
            text: "Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.mobile}</span>;
            },
        },
        {
            key: "appointment_type",
            text: " Appointment Type",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{
                    data?.schedules?.chamber_info?.appointment_type === 1 ? "Online"
                        : data?.schedules?.chamber_info?.appointment_type === 2 ? "Physical"
                            : "Online and Physical"
                }</span>;
            },
        },
        {
            key: "appointment_date",
            text: "Appointment Date",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{dateFormate(data.appointment_date)} ({data?.schedules?.day?.name}) </span>;
            },
        },
        {
            key: "Schedule",
            text: "Schedule Time",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: true,
            cell: (data) => {
                return <span>{format24to12(data?.schedules?.start_time)} - {format24to12(data?.schedules?.end_time)}</span>;
            },
        },
        {
            key: "online_schedules",
            text: "Online Schedule",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.online_schedule && format24to12(data?.online_schedule)}</span>;
            },
        },
        {
            key: "chamber_name",
            text: "Chamber Name",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.schedules?.chamber_info?.name}</span>;
            },
        },
        {
            key: "chamber_address",
            text: "Chamber Address",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>
                    {data?.schedules?.chamber_info?.address} , {data?.schedules?.chamber_info?.city_info?.name}, {data?.schedules?.chamber_info?.state_info?.name}
                </span>;
            },
        },
        {
            key: "Doctor_Name",
            text: "Doctor Name",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.schedules?.chamber_info?.doctor_info?.f_name}</span>;
            },
        },
        {
            key: "doctor_mobile",
            text: "Doctor Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.schedules?.chamber_info?.doctor_info?.mobile}</span>;
            },
        },
        {
            key: "createdAt",
            text: "Request Date",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: true,
            cell: (data) => {
                return <span>{formatDateTime(data.createdAt)}</span>;
            },
        },

        {
            key: "status",
            text: "status",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return (
                    <span>
            {data.status === 0
                ? "Requested"
                : data.status === 1
                    ? "Confirmed"
                    : data.status === 2
                        ? "completed"
                        : data.status === 3
                            ? "completed"
                            : "unknown"}
          </span>
                );
            },
        },
        // {
        //   key: "action",
        //   text: "Action",
        //   // className: 'action',
        //   width: 120,
        //   align: "center",
        //   sortable: false,
        //   cell: (data) => {
        //     return (
        //       <>
        //         {accessPerm(39, 8) && data.status === 5 && (
        //           <Button
        //             onClick={() => {
        //               setShowUploadReport(true);
        //               setSingleItem(data);
        //             }}
        //             variant="success"
        //           >
        //             Upload Report
        //           </Button>
        //         )}
        //       </>
        //     );
        //   },
        // },
    ];
    const config = {
        page_size: 10,
        show_filter: false,
        show_length_menu: true,
        show_pagination: true,
        pagination: "advance",
        length_menu: [10, 50, 100],
        button: {
            extra: true,
        },
    };

    // search component

    const extraButtons = [
        {
            className: "bg-transparent border-0",

            title: "Search",
            children: [
                <Form.Group controlId="formBasicText">
                    <Form.Control
                        type={"search"}
                        placeholder={"Search"}
                        // value={value}
                        onChange={(e) => handlerFilter(e.target.value)}
                    />
                </Form.Group>,
            ],
            onClick: (event) => {
                // console.log(event);
            },
        },
    ];

    // search/filter functions

    const handlerFilter = (value) => {
        setFilteredAmbulanceRequest(
            getAllAmbulanceRequest.filter((item) => {
                return (
                    item?.req_no?.toString()?.includes(value.toLowerCase()) ||
                    item?.requester?.f_name
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    item?.schedules?.chamber_info?.doctor_info?.f_name
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||


                    item?.requester?.registration_no
                        ?.toString()
                        ?.includes(value.toLowerCase()) ||
                    item?.requester?.mobile
                        ?.toString()
                        ?.includes(value.toLowerCase())
                );
            }),
        );
    };

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">
                        <div className="card-box">
                            <h6 className="mb-0 text-uppercase">All Appointments Request List</h6>
                            <div className="col">{/* Modal */}</div>
                        </div>

                        <hr/>

                        <div>
                            <ReactDatatable
                                config={config}
                                records={filteredAmbulanceRequest}
                                columns={columns}
                                extraButtons={extraButtons}

                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppointmentReqList;