import React from "react";
import CrudLayout from "../../../components/Layout/CrudLayout";
import NameInfoFormFormik from "../../../components/Form/NameInfoFormFormik";
const ManageByHub = () => {
  const columns = [
    {
      text: "Module Name",
      cell: (row) => row?.module_name,
      sortable: true,
    },
    {
      text: "Module slug",
      cell: (row) => row?.module_name_slug,
      sortable: true,
    },
    {
      text: "Info",
      cell: (row) => row?.info,
      sortable: true,
    },
    {
      text: "Status",
      cell: (row) => (row?.status ? "Active" : "Inactive"),
      sortable: "true",
      align: "center",
    },
  ];

  const initialValues = {
    module_name: "",
    info: "",
    status: 1,
  };

  const config = {
    table_name: "Manage By Hub",
    create_modal_title: "Create New Module",
    update_modal_title: "Update Module",
    link: "manage-by-hub",
    entity_name: "manage-by-hub",
    columns,
    form: <NameInfoFormFormik firstFieldName="module_name" />,
    initialValues,
  };

  return <CrudLayout config={config} />;
};

export default ManageByHub;
