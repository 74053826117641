import { useFormikContext } from "formik";
import ReactInput from "../../../components/InputList/ReactInput/ReactInput";
import ReactSelect from "../../../components/ReactSelect/ReactSelect";
import FormLabel from "../../../components/Text/FormLabel";
import { Form, Col, Row } from "react-bootstrap";

const HealthProductsForm = ({ storeData, unitData, categoryData }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <section>
      {" "}
      <Form.Group as={Row} className="mt-3">
        <Col sm={3}>
          <FormLabel title="Choose Store" />
        </Col>

        <Col sm={9}>
          <ReactSelect
            data={storeData}
            value={values?.store_id}
            labelName={"f_name"}
            valueName={"id"}
            onChange={(val) => setFieldValue("store_id", val)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mt-3">
        <Col sm={3}>
          <FormLabel title="Name" />
        </Col>

        <Col sm={9}>
          <ReactInput name="name" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mt-3">
        <Col sm={3}>
          <FormLabel title="Unit" />
        </Col>

        <Col sm={9}>
          <ReactSelect
            data={unitData}
            value={values?.unit_id}
            labelName={"name"}
            valueName={"id"}
            onChange={(val) => setFieldValue("unit_id", val)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mt-3">
        <Col sm={3}>
          <FormLabel title="Category" />
        </Col>

        <Col sm={9}>
          <ReactSelect
            data={categoryData}
            value={values?.cat_id}
            labelName={"name"}
            valueName={"id"}
            onChange={(val) => setFieldValue("cat_id", val)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mt-3">
        <Col sm={3}>
          <FormLabel title="image" />
        </Col>

        <Col sm={9}>
          <ReactInput name="image" type="file" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mt-3">
        <Col sm={3}>
          <FormLabel title="Regular Price" />
        </Col>

        <Col sm={9}>
          <ReactInput name="regular_price" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mt-3">
        <Col sm={3}>
          <FormLabel title="Discounted Price" />
        </Col>

        <Col sm={9}>
          <ReactInput name="discounted_price" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mt-3">
        <Col sm={3}>
          <FormLabel title="info" />
        </Col>

        <Col sm={9}>
          <ReactInput name="info" />
        </Col>
      </Form.Group>
      <Form.Group className="d-flex jsustify-content-end mt-3">
        <Form.Check
          checked={values?.status === 2 ? true : false}
          onChange={(e) => setFieldValue("status", e.target.checked ? 2 : 3)}
          label="Status"
        />
      </Form.Group>
    </section>
  );
};

export default HealthProductsForm;
