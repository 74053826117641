import {useEffect, useState} from 'react';
import axios from 'axios';

const useFetchData = (url, deps) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        (async function () {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/${url}`);
                setData(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        })()

        // Cleanup function to cancel the request if the component unmounts
        // return () => {
        //     // Optionally cancel the request (if using Axios cancel token)
        // };
    }, [url, deps, refresh]);

    return {data, loading, error, setRefresh};
};

export default useFetchData;
