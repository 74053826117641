import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../../Context/UserContextAPI";
import AddNewAdvertisement from "./AddNewAdvertisement";
import EditAdvertisement from "./EditAdvertisement";
import {timeFormate, dateFormate, formateToNewDate, timeToDateFormate} from '../../utils/timeAndDateFunction'


export default function AdvertisementList() {
  const { currentUser, accessPerm, loading } = useContext(UserContext);
  const [isAddNewModalOpen, setAddNewModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [advertiseList, setAdvertiseList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [singleAdData, setSingleAdData] = useState({}); // single data for edit the ad
  const [adSection, setAdSection] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/advertisement`
        );
        console.log("ad data", res.data?.data);
        setAdvertiseList(res.data?.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [refresh]);

useEffect(()=>{
 (async function (){
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/adsections`
    );
    console.log("eshan")
    const data = response.data.data;
    setAdSection(data);
    return data;
    
  } catch (error) {
    console.log(error)
  }
 }())
},[])



  // advertise delete functions based on ad id
  const handlerOnDelete = async (id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/advertisement/${id}`
      );

      if (res.data) {
        toast.success(res.data.message);
        setRefresh(!refresh);
      }
      console.log("deleted", res);
    } catch (err) {
      toast.error(err?.message); // it will show error in frontend
      console.log("error while delete", err);
    }
  };

  //  edit function handler

  const handleEdit = (data) => {
    setSingleAdData(data);
    setEditModalOpen(true);
  };

  // tables column

  const columns = [
    {
      key: "name",
      text: "Name",
      align: "center",
      sortable: true,
    },
    {
      key: "ad_section_id",
      text: "Ad Section",
      align: "center",
      sortable: true,
      cell: (data) => {
        return <span>{data?.ad_section?.name}</span>;
      },
    },
    {
      key: "media_type",
      text: "Media Type",
      align: "center",
      sortable: true,
      cell: (data) => {
        return <span>{data.media_type ===  1 || data.media_type === "1" ? "Images" : "Video"}</span>;
      },
    },
    {
      key: "media",
      text: "Media File",
      align: "center",
      width: "10%",
      sortable: true,
      cell: (data) => {
        return <span style={{maxWidth:"10px"}}>{data.media}</span>;
      },
    },
    {
      key: "link",
      text: "Media Link",
      align: "center",
      width: 100,
      sortable: true,
    },
    {
      key: "start_date",
      text: "Start Date",
      align: "left",
      sortable: true,
      cell:(data)=> <span>{dateFormate(data.start_date)} </span>
    },
    {
      key: "end_date",
      text: "End Date",
      align: "left",
      sortable: true,
      cell:(data)=> <span>{dateFormate(data.end_date)} </span>
    },
    {
      key: "start_time",
      text: "Start Time",
      align: "left",
      sortable: true,
      cell:(data)=> <span>{timeFormate(timeToDateFormate(data.start_time))} </span>  // first made a new date with time and then formate this date .
    },
    {
      key: "end_time",
      text: "End Time",
      align: "center",
      sortable: true,
      cell:(data)=> <span>{timeFormate(timeToDateFormate(data.end_time))} </span> // first made a new date with time and then formate this date .
    },
    {
      key: "clicks",
      text: "Clicks",
      className: "info",
      align: "center",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      sortable: true,
      cell: (record) => {
        return <>{record.status === 1 ? "Active" : "Inactive"}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "center",
      sortable: false,
      cell: (record) => {
        // console.log(record);
        return (
          <>
            {/* Edit Modal Trigger Button */}
            {accessPerm(42, 2) && (
              <button
                type="button"
                onClick={() => handleEdit(record)} // sending the single ad data
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
            )}

            {/* Delete Modal Trigger Button */}
            {accessPerm(42, 3) && (
              <button
                type="button"
                // onClick={() => setDeleteModalData(record)}
                className="btn btn-danger btn-sm"
                data-bs-toggle="modal"
                data-bs-target={`#deleteAdSectionModal-${record.id}`}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-trash"></i>
              </button>
            )}

            {/* Delete Modal Body */}

            <div
              className="modal fade"
              id={`deleteAdSectionModal-${record.id}`}
              tabIndex={-1}
              style={{ display: "none" }}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Delete This Ad Section</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body pb-0">
                    <div className="mb-3 row ">
                      <div className="col-sm-10">
                        <p>Are you sure you want to delete?</p>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handlerOnDelete(record.id)}
                      data-bs-dismiss="modal"
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  //<-------------- tables column end ----------->
  
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded" style={{overflow:"auto"}}>
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">Advertisement List</h6>
              <div className="col">
                {/* Create Role trigger modal Button */}

                {accessPerm(42, 1) && (
                  <button
                    type="button"
                    onClick={() => setAddNewModalOpen(true)}
                    className="btn btn-primary"
                  >
                    <i className="fa-solid fa-plus"></i> Add New
                  </button>
                )}
              </div>
            </div>

            <hr />

            <ReactDatatable
              // config={config}
              records={advertiseList}
              columns={columns}
              // extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>

      <>
        {isAddNewModalOpen && (
          <AddNewAdvertisement
            isAddNewModalOpen={isAddNewModalOpen}
            setAddNewModalOpen={setAddNewModalOpen}
            refresh={refresh}
            setRefresh={setRefresh}
            adSection={adSection}
          />
        )}
        {isEditModalOpen && (
          <EditAdvertisement
            isEditModalOpen={isEditModalOpen}
            setEditModalOpen={setEditModalOpen}
            item={singleAdData}
            refresh={refresh}
            setRefresh={setRefresh}
            adSection={adSection}
          />
        )}
      </>
    </>
  );
}
