import React from 'react';
import useFetchData from "../../hooks/useFetchData";
import CardLayout from "../Layout/CardLayout";
import ReactTable from "../ReactDataTable/ReactTable";
import formatDateTime from "../../utils/DateTimeFormate";
import {getAmbulanceRequestTitle} from "../../utils/data/requestStatus";

const AmbulanceRequest = ({user_id, role_id}) => {
    const {data} = useFetchData(`request/ambulance-service/admin/get-all/${user_id}`)


    const firstPart = [
        {
            key: "req_no",
            text: "Request No:",
            align: "center",
            sortable: true,
        },
        {
            key: "request_cat",
            text: "Request Type",
            align: "center",
            sortable: false,
            cell: (data) =>
                <span>{role_id === 10 ? data?.service_category?.name : data?.ambulance_request?.service_category?.name}</span>
        },
    ]

    let lastPart = [
        {
            key: "createdAt",
            text: "Request Date",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: true,
            cell: (data) => {
                return <span>{formatDateTime(data.createdAt)}</span>;
            },
        },

        {
            key: "status",
            text: "status",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) =>
                <span>{getAmbulanceRequestTitle(role_id === 10 ? data?.status : data?.ambulance_request?.status)}</span>,
        },

    ];

    const userReqInfo = [

        {
            key: "req_by",
            text: "Request By",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.ambulance_request?.requester?.f_name}</span>;
            },
        },
        {
            key: "reg_no",
            text: "User Registration No:",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: false,
            cell: (data) => {
                return <span>{data?.ambulance_request?.requester?.registration_no}</span>;
            },
        },

        {
            key: "mobile",
            text: "Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.ambulance_request?.requester?.mobile}</span>;
            },
        },

    ]

    const ambulanceInfo = [

        {
            key: "ambulance",
            text: "Accepted Ambulance Name",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.accepted_ambulance?.f_name}</span>;
            },
        },
        {
            key: "ambulance_reg",
            text: "Accepted Ambulance Reg no",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data.accepted_ambulance?.registration_no}</span>;
            },
        },
        {
            key: "ambulance_mobile",
            text: "accepted Ambulance Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data.accepted_ambulance?.mobile}</span>;
            },
        },
        {
            key: "total_response",
            text: "Total Ambulance Response",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data.all_ambulance_response?.length}</span>;
            },
        },
    ]

    let columns = [];

    if (role_id === 10) {
        columns = [...columns, ...firstPart, ...ambulanceInfo, ...lastPart]
    } else if (role_id === 14) {
        columns = [...columns, ...firstPart, ...userReqInfo, ...lastPart]
    }


    return (
        <section>
            <CardLayout title={"Ambulance Request"}>
                <ReactTable
                    data={data?.data}
                    columns={columns}
                />
            </CardLayout>

        </section>
    );
};

export default AmbulanceRequest;