export const timeFormate = (date) => {
    const inputDate = new Date(date);
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    // Format the date and time as 'YY-MM-DD, h:mm AM/PM'
    const formattedDateTime = ` ${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;

    return formattedDateTime;
}

export const dateFormate = (date) => {
    // Parse the input date and time string
    const inputDate = new Date(date);

    // Get the individual date and time components
    const year = inputDate.getFullYear().toString(); // Get the last two digits of the year
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = inputDate.getDate().toString().padStart(2, '0');

    return `${day}-${month}-${year}`;
}


export const formateToNewDate = (date) => {
    const [day, month, year] = date?.split("-");
    const realDate = new Date(year, month - 1, day);
    return realDate;
}

export const timeToDateFormate = (time) => {
    const date = new Date().toLocaleDateString("en-US");
    const dateTimeString = `${date} ${time}`;
    const newDate = new Date(dateTimeString);
    return newDate;
}

export function format24to12(time24) {
    // Check if time24 is undefined or not a string
    if (!time24 || typeof time24 !== 'string') {
        return 'Invalid Time';
    }

    // Parse the input time string
    const [hours, minutes] = time24.split(':');

    // Convert hours to a number
    let hoursNum = parseInt(hours, 10);

    // Validate the input hours
    if (isNaN(hoursNum) || hoursNum < 0 || hoursNum > 23) {
        return 'Invalid Hours';
    }

    // Determine AM or PM
    const ampm = hoursNum >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hoursNum = hoursNum % 12 || 12;

    // Format the time in 12-hour format
    return `${hoursNum}:${minutes} ${ampm}`;
}
