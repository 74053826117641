import React, {useEffect, useState} from 'react';
import ReactDatatable from "@ashvin27/react-datatable";
import {Form} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {toast} from "react-hot-toast";

const HealthProductsRequest = () => {

    const [productRequestList, setProductRequestList] = useState([]);
    const [filteredProductsReqList, setFilteredProductsReqList] = useState([]);

    // fetch all requested data;

    const getAllProductsReqList = async () => {
        try {
            const {data} = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/request/health-products/admin`,
            );
            if (data.status === 'OK') {
                console.log(data.data);
                setProductRequestList(data?.data);
                setFilteredProductsReqList(data?.data);
            }

        } catch (e) {
            console.error(e.message)
        }
    };

    useEffect(() => {
        getAllProductsReqList();
    }, []);


    const columns = [
        {
            key: "order_no",
            text: "Order ID",
            align: "center",
            sortable: true,

        },
        {
            key: "user_reg_no",
            text: "User Reg No",
            // className: 'action',
            // width: 100,
            align: "center",

            cell: (data) => <span>{data?.requester?.registration_no}</span>,
        },
        {
            key: "name",
            text: "User Name",
            // className: 'action',
            // width: 100,
            align: "center",

            cell: (data) => <span>{data?.requester?.f_name}</span>,
        },
        {
            key: "user_mobile",
            text: "User Mobile",
            // className: 'action',
            // width: 100,
            align: "center",

            cell: (data) => <span>{data?.requester?.mobile}</span>,
        },
        {
            key: "delivery_address",
            text: "Delivery Address",
            align: "center",

            cell: data =>
                <span> {data?.delivery_address}, {data?.delivery_city?.name}, {data?.delivery_state?.name}, {data.delivery_country?.name}</span>
        },
        {
            key: "total_amount",
            text: "Total Amount",
            align: "center",
            sortable: true,
            cell: data =>
                <span> {data?.total_amount} TK</span>
        },
        {
            key: "paid_amount",
            text: "Paid Amount",
            align: "center",
            sortable: true,
            cell: data =>
                <span> {data?.paid_amount} TK</span>
        },
        {
            key: "due_amount",
            text: "Due Amount",
            align: "center",
            sortable: true,
            cell: data =>
                <span> {data?.due_amount} TK</span>
        },
        {
            key: "delivery_person",
            text: "Delivery  person Reg",
            align: "center",
            cell: data => <span>{data?.delivery_person_info && data?.delivery_person_info?.registration_no}</span>

        },
        {
            key: "delivery_person_name",
            text: "Delivery person Name",
            align: "center",
            cell: data => <span>{data?.delivery_person_info && data?.delivery_person_info?.f_name}</span>
        },
        {
            key: "delivery_person_mobile",
            text: "Delivery Person mobile",
            align: "center",
            cell: data => <span>{data?.delivery_person_info && data?.delivery_person_info?.mobile}</span>
        },
        {
            key: "status",
            text: "status",
            align: "center",
            sortable: true,
            cell: data =>
                <span>{
                    data?.status === 1 ? "confirmed"
                        : data?.status === 2 ? "Disbursed"
                            : data?.status === 3 ? "Accepted By Delivery"
                                : data?.status === 4 ? "Collected By Delivery"
                                    : data?.status === 5 ? "Delivery Completed"
                                        : null
                }</span>
        },
        {
            key: "action",
            text: "Action",
            align: "center",

            cell: data => <div className='d-flex justify-content-between align-items-center'>
                {
                    <Link to={`/dashboard/health-products/request/${data?.order_no}`}>
                        <button
                            type="button"

                            className="btn btn-warning btn-sm"
                            style={{marginRight: "5px"}}
                        >
                            <i className="fa-regular fa-eye"></i>
                        </button>
                    </Link>
                }
                {
                    data?.status === 1 ?
                        <>{data?.product_list.every((product) => product.status === 3) ?
                            <Button size='sm' onClick={() => handleRequestForDisbursement(data?.order_no)}>Request For
                                Disbursement</Button> :
                            <p className='p-0 m-0 text-danger'>All Items Not Received Yet</p>} </> : null
                }

            </div>

        },

    ];
    const config = {
        page_size: 10,
        show_filter: false,
        show_length_menu: true,
        show_pagination: true,
        pagination: "advance",
        length_menu: [10, 50, 100],
        button: {
            extra: true,
        },
    };

    const extraButtons = [
        {
            className: "bg-transparent border-0",

            title: "Search",
            children: [
                <Form.Group controlId="formBasicText">
                    <Form.Control
                        type={"search"}
                        placeholder={"Search"}
                        // value={value}
                        onChange={(e) => handlerFilter(e.target.value)}
                    />
                </Form.Group>,
            ],
            onClick: (event) => {
                // console.log(event);
            },
        },
    ];


    /// search

    const handlerFilter = (value) => {
        setFilteredProductsReqList(
            productRequestList.filter((item) => {
                return (
                    item.order_no?.toString()?.toLowerCase().includes(value.toLowerCase()) ||
                    item?.requester?.registration_no
                        ?.toString()
                        ?.includes(value.toLowerCase()) ||
                    item?.requester?.f_name.toLowerCase().includes(value.toLowerCase()) ||
                    item?.requester?.mobile?.toString()?.includes(value.toLowerCase())
                );
            }),
        );
    }


    /// fn handler

    const handleRequestForDisbursement = async (order_no) => {
        try {

            const {data} = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/request/health-products/admin/delivery/request/${order_no}`,
            );
            console.log(data)

            if (data) {
                toast.success("request sent successfully");
                getAllProductsReqList();
            }

        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div className="card">
            <div className="card-body">
                <div className="border p-3 rounded">
                    <div className="card-box">
                        <h6 className="mb-0 text-uppercase">
                            All Health Products Order list
                        </h6>
                        <div className="col">{/* Modal */}</div>
                    </div>

                    <hr/>

                    <ReactDatatable
                        config={config}
                        records={filteredProductsReqList}
                        columns={columns}
                        extraButtons={extraButtons}
                    />
                </div>
            </div>
        </div>
    );
};

export default HealthProductsRequest;