import React from 'react';
import {Modal} from "react-bootstrap";
import Table from "react-bootstrap/Table";

const RoomInfo = ({isOPen, onClose, data}) => {
    return (
        <Modal show={isOPen} onHide={onClose} size='lg'>
            <Modal.Header closeButton={true}>
                <h6> Room Information</h6>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {
                        data?.length > 0 ?

                            <div>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Floor</th>
                                        <th>Type</th>
                                        <th>Price</th>
                                        <th>Info</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {
                                        data.map((room) => (
                                            <tr key={room?.id}>
                                                <td>1</td>
                                                <td>{room?.name}</td>
                                                <td>{room?.floor}</td>
                                                <td> {room?.accommodation_types?.name} </td>
                                                <td>{room?.price} TK</td>
                                                <td>{room?.info} </td>


                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            </div>

                            : <p>NO Data</p>
                    }

                </div>

            </Modal.Body>

        </Modal>
    );
};

export default RoomInfo;