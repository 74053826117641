import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import ReactDatatable from "@ashvin27/react-datatable";
import Button from "react-bootstrap/Button";
import {toast} from "react-hot-toast";

const OrderedProductsList = () => {

    const {order_no} = useParams();

    //states

    const [orderedData, setOrderData] = useState({})

    // fetch single requested data;

    const getOrderedProductsData = async () => {
        try {
            const {data} = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/request/health-products/order/${order_no}`,
            );
            if (data.status === 'OK') {
                console.log(data.data);
                setOrderData(data?.data);

            }

        } catch (e) {
            console.error(e.message)
        }
    };

    useEffect(() => {
        getOrderedProductsData();
    }, [order_no]);


    // columns info
    const columns = [
        {
            key: "image",
            text: "Image",
            align: "left",
            sortable: true,
            width: 70,
            cell: (data) => {
                return (
                    <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}/HealthProducts/${data?.product_details?.image}`}
                        style={{width: "70px", height: "70px", borderRadius: "5px"}}
                        alt=""
                    />
                );
            },
        },
        {
            key: "name",
            text: "Name",
            align: "left",
            sortable: true,
            cell: (data) => <span>{data?.product_details?.name}</span>,
        },
        {
            key: "store_id",
            text: "Store",
            align: "left",
            sortable: true,
            cell: (data) => <span>{data?.product_details?.store_info?.f_name}</span>,
        },
        {
            key: "uom",
            text: "Unit",
            align: "left",
            sortable: true,
            cell: (data) => {
                return <>{data?.product_details?.uom?.name}</>;
            },
        },
        {
            key: "cat",
            text: "Category",
            align: "left",
            sortable: true,
            cell: (data) => {
                return <>{data?.product_details?.health_products_category?.name}</>;
            },
        },
        {
            key: "regular_price",
            text: "Regular Price",
            align: "left",
            sortable: true,
            cell: data => <span>{data.regular_price} TK</span>
        },
        {
            key: "discounted_price",
            text: "Discounted Price",
            align: "left",
            sortable: true,
            cell: data => <span>{data.discounted_price} TK</span>
        },

        {
            key: "status",
            text: "Status",
            className: "status",
            sortable: true,
            cell: (data) =>
                <span>{data.status === 1 ? "not ready yet" : data?.status === 2 ? "Product Ready" : data?.status === 3 ? "Product Received" : null}</span>,
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: data => <div>
                {
                    (data.status === 1 || data.status === 2) ?
                        <Button size='sm' onClick={() => handleProductReceived(data)}>Received</Button> : null
                }
            </div>

        },
    ];
    //table config
    const config = {
        page_size: 10,
        show_filter: false,
        show_length_menu: false,
        show_pagination: false,
        // pagination: "advance",
        // length_menu: [10, 50, 100],

    };


    const handleProductReceived = async (item) => {
        // console.log(item)
        try {
            const res = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/request/health-products/admin/order/received/${item.order_no}/${item.product_id}`)
            if (res.data) {

                toast.success('products updated successfully')
                getOrderedProductsData();
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="border p-3 rounded">
                    <div className='row'>
                        <div style={{fontSize: "15px"}} className='col-md-6 col-12'>
                            <p>Requester Name: {orderedData?.requester?.f_name} </p>
                            <p>Requester Reg No: {orderedData?.requester?.registration_no} </p>
                            <p>Requester Mobile: {orderedData?.requester?.mobile} </p>
                            <h6 className="mb-0 text-uppercase">
                                All Ordered Health Products Item list
                            </h6>

                        </div>
                        <div className='col-md-6 col-12'>
                            <p>Order No: {orderedData?.order_no} </p>
                            <p>Total Amount: {orderedData?.total_amount} TK</p>
                            <p>Paid Amount: {orderedData?.paid_amount} TK</p>
                            <p>Deu Amount: {orderedData?.due_amount} TK</p>

                        </div>

                    </div>

                    <hr/>

                    <ReactDatatable
                        config={config}
                        records={orderedData?.product_list}
                        columns={columns}
                        // extraButtons={extraButtons}
                    />
                </div>
            </div>
        </div>
    );
};

export default OrderedProductsList;