import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import axios from "axios";
import Button from "react-bootstrap/Button";
import {toast} from "react-hot-toast";

const AddChamber = ({userDetails, editData, onClose, refresh}) => {
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [selectedState, setSelectedState] = useState(editData ? editData?.state : '');
    const [selectedCity, setSelectedCity] = useState({});

    const [appointmentDays, setAppointmentDays] = useState([])

    //variables

    console.log(userDetails)

    const fetchStateList = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/city/state/data/${userDetails?.country?.id}`,
            )
            .then((response) => {
                setStateList(response.data.data);
            })
            .catch((err) => console.log(err));
    };
    const fetchCityList = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/city/data/${selectedState}`,
            )
            .then((response) => {
                setCityList(response.data.data);
            })
            .catch((err) => console.log(err));
    };


    const fetchAppointmentDays = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/doctors/appointment/days`,
            )
            .then((response) => {
                setAppointmentDays(response.data.data);
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        fetchAppointmentDays()
    }, []);

    useEffect(() => {
        fetchStateList()
    }, [userDetails?.country?.id]);

    useEffect(() => {
        selectedState && fetchCityList()
    }, [selectedState]);
    // console.log(selectedState, cityList)


    // form handlers fn

    const handleForm = (event) => {
        event.preventDefault();
        const form = event.target;
        const state = form.state.value;
        const city = form.city.value;
        const name = form.name.value;
        const address = form.address.value;
        const fees = form.fees.value;
        const online = form.online.checked;
        const physical = form.physical.checked;
        const status = form.status.checked;


        // eslint-disable-next-line array-callback-return
        const timeArr = appointmentDays.map((day) => {
            if (form[`${day?.name?.toLowerCase()}_start`].value || form[`${day?.name?.toLowerCase()}_end`].value) {
                return {
                    start_time: form[`${day?.name?.toLowerCase()}_start`].value,
                    end_time: form[`${day?.name?.toLowerCase()}_end`].value,
                    day_id: day?.id,
                }
            }
        }).filter(Boolean); // filter out undefined values


        const formData = {
            // user_id: userDetails?.user_id,
            state,
            city,
            name,
            address,
            fees,
            status: status === true ? 1 : 0,
            appointment_type: online === true && physical === true ? 3 : online === true ? 1 : physical === true ? 2 : 0,
            timeArr
        };
        // console.log('Form Data:', formData);

        editData ? handleUpdate(formData, form) : handlePost(formData, form)
        // Reset the form if needed
        // form.reset();
    };


    // handle post fn

    const handlePost = (formData, form) => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/doctor/chamber`, {...formData, user_id: userDetails?.user_id})
            .then(res => {
                console.log(res.data)
                if (res.data?.status === "OK") {
                    toast.success("chamber Created");
                    form.reset();
                }
            })
            .catch(err => console.log(err))
    }


    /// update chamber data

    const handleUpdate = ({timeArr, ...rest}, form) => {

        console.log(timeArr)
        console.log('cg', editData.chamber_schedule)

        const updateData = timeArr.filter(data =>
            editData.chamber_schedule.some(chamber =>
                parseInt(data.day_id) === parseInt(chamber.day_id)
            )
        );

        const newData = timeArr.filter(data =>
            !editData.chamber_schedule.some(chamber =>
                parseInt(data.day_id) === parseInt(chamber.day_id)
            )
        );


        axios.put(`${process.env.REACT_APP_API_BASE_URL}/doctor/chamber/${editData?.id}`, {
            ...rest,
            updateData,
            newData
        })
            .then(res => {
                console.log(res.data)
                if (res.data?.status === "OK") {
                    toast.success("chamber Updated");
                    form.reset();
                    onClose();
                    refresh();
                }
            })
            .catch(err => console.log(err))
    }


    return (
        <div>
            <div className={!editData && "mt-5"}>

                <Form onSubmit={handleForm}>
                    <Form.Group as={Row}>

                        <Form.Label column sm={2} lg={3} className='fs-5'>
                            Chamber Name :
                        </Form.Label>

                        <Col sm={10} lg={9}>
                            <Form.Control type={'text'} name={'name'} placeholder='Your Chamber Name'
                                          defaultValue={editData && editData?.name}/>
                        </Col>


                        <Form.Label column sm={2} lg={3} className='fs-5 mt-2'>
                            Chamber Address :
                        </Form.Label>

                        <Col sm={10} lg={9} className='mt-2 d-flex gap-2'>
                            <Form.Select
                                onChange={(e) => setSelectedState(e.target.value)}
                                name={'state'}
                                defaultValue={editData && editData?.state}
                            >
                                <option value="0">Select State</option>
                                {
                                    stateList.map(state => (
                                        <option
                                            key={state?.id}
                                            value={state?.id}
                                            selected={editData && editData?.state === parseInt(state.id)}
                                        >
                                            {state?.name}
                                        </option>
                                    ))
                                }
                            </Form.Select>

                            <Form.Select
                                onChange={(e) => setSelectedCity(e.target.value)}
                                name={'city'}
                                defaultValue={editData && editData?.city}

                            >
                                <option value="0">Select city</option>
                                {
                                    cityList.map(city => (
                                        <option
                                            key={city?.id}
                                            value={city?.id}
                                            selected={editData && editData?.city === city.id}
                                        >
                                            {city?.name}
                                        </option>
                                    ))
                                }
                            </Form.Select>

                            <Form.Control
                                type={'text'}
                                name={'address'}
                                placeholder='Address'
                                defaultValue={editData && editData?.address}
                            />
                        </Col>

                        <Form.Label column sm={2} lg={3} className='fs-5 mt-2'>
                            Appointment Fees :
                        </Form.Label>

                        <Col sm={10} lg={9} className='mt-2'>
                            <Form.Control
                                type='number'
                                name={'fees'}
                                placeholder='Your Appointment Fees'
                                defaultValue={editData && editData?.fees}
                            />
                        </Col>

                        <Form.Label column sm={2} lg={3} className='fs-5 mt-2'>
                            Appointment Type:
                        </Form.Label>

                        <Col sm={10} lg={9} className='mt-4'>
                            <Form.Check
                                type='checkbox'
                                inline={true}
                                label='Online'
                                name='online'
                                defaultChecked={editData && (editData?.appointment_type === 1 || editData?.appointment_type === 3)}
                            />
                            <Form.Check
                                type='checkbox'
                                inline={true}
                                label='Physical'
                                name='physical'
                                defaultChecked={editData && (editData?.appointment_type === 2 || editData?.appointment_type === 3)}
                            />
                        </Col>

                        <Form.Label column sm={12} lg={12} className='fs-5 mt-2'>
                            <p>Set Your Appointments Schedule -</p>

                        </Form.Label>

                        <Form.Label column sm={12} lg={3} className='fs-5 mt-2'>

                        </Form.Label>


                        <Form.Label column sm={12} lg={9} className='fs-5 mt-2'>
                            <div className='row row-cols-2'>
                                <p style={{fontSize: "16px"}}>Start Time : </p>
                                <p style={{fontSize: "16px"}}>End Time : </p>
                            </div>

                        </Form.Label>


                        {/*Schedules Start */}


                        {appointmentDays.length > 0 && appointmentDays.map((day) => {
                            const scheduleTime = editData?.chamber_schedule.find(schedule => parseInt(schedule?.day_id) === day.id)
                            // console.log('sche', editData?.chamber_schedule)

                            return (
                                <Form.Group key={day?.id} className='d-flex align-items-center'>
                                    <Form.Label column sm={2} lg={3} className='fs-5 mt-2'>
                                        {day?.name}:
                                    </Form.Label>
                                    <Col sm={10} lg={9} className='mt-3 d-lg-flex gap-2'>

                                        <Form.Control
                                            type='time'
                                            name={`${day?.name?.toLowerCase()}_start`}
                                            defaultValue={scheduleTime && scheduleTime?.start_time}
                                        />

                                        <Form.Control
                                            type='time'
                                            name={`${day?.name?.toLowerCase()}_end`}
                                            defaultValue={scheduleTime && scheduleTime?.end_time}
                                        />
                                    </Col>
                                </Form.Group>
                            )
                        })}

                        <Form.Label column sm={2} lg={3} className='fs-5 mt-2'>
                            Status (active/ inactive):
                        </Form.Label>

                        <Col sm={10} lg={9} className='mt-4'>
                            <Form.Check
                                type='checkbox'
                                label='Active'
                                name='status'
                                defaultChecked={editData && (editData?.status === 1)}
                            />

                        </Col>


                    </Form.Group>

                    <div className='d-flex justify-content-end mt-4'>
                        <Button variant="primary" type="submit">
                            {editData ? "Update" : "Submit"}
                        </Button>
                    </div>

                </Form>
            </div>
        </div>
    );
};

export default AddChamber;