import React from 'react';
import Modal from "react-bootstrap/Modal";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";

const NameInfoForm = ({isOpen, onClose, title, apiHandler, editData}) => {

    const formHandler = (e) => {
        e.preventDefault();
        const form = e.target;
        const data = {
            name: form.name.value,
            info: form.info.value,
            status: form.checkbox.checked ? 1 : 0
        }
        apiHandler(data);


    }
    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header closeButton={true}>
                <h5>{title} </h5>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={formHandler}>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>
                                Name <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text" name="name" required
                                          defaultValue={editData ? editData?.name : ""}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="info">
                            <Form.Label>Info</Form.Label>
                            <Form.Control as="textarea" name="info" rows={3} maxLength={200}
                                          defaultValue={editData ? editData?.info : ''}/>
                        </Form.Group>

                        <Form.Group className="mb-3 d-flex align-items-center justify-content-end">
                            <Form.Check
                                type="checkbox"
                                id="flexCheckChecked"
                                label="Active"
                                name='checkbox'
                                defaultChecked={editData && editData?.status === 1}
                            />
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={onClose}>
                            Close
                        </Button>
                        <Button type="submit" variant="primary">
                            Save changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>


    );
};

export default NameInfoForm;