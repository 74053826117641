import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactDatatable from "@ashvin27/react-datatable";
import ScheduleDetailsModal from "./ScheduleDetailsModal";
import AddChamber from "./AddChamber";
import Modal from "react-bootstrap/Modal";
import DeleteModal from "../../Modal/DeleteModal";
import { toast } from "react-hot-toast";
import CardLayout from "../../Layout/CardLayout";
import ReactTable from "../../ReactDataTable/ReactTable";

const ChamberList = ({ userDetails }) => {
  const [chamberList, setChamberList] = useState([]);
  const [singleChamberData, setSingleChamberData] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/doctor/chamber/${userDetails?.user_id}`,
        );

        if (res.data.status === "OK") {
          setChamberList(res.data?.data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [userDetails?.user_id, refresh]);

  console.log(chamberList);

  const columns = [
    {
      key: "name",
      text: "Chamber Name",
      align: "center",
      sortable: false,
    },
    {
      key: "address",
      text: "Chamber Address",
      align: "center",
      sortable: false,
      cell: (data) => (
        <span>
          {data?.address}, {data?.city_info?.name}, {data?.state_info?.name}
        </span>
      ),
    },

    {
      key: "fees",
      text: "Appointments Fees",
      align: "center",
      // sortable: true,
      cell: (data) => <span>{data?.fees} TK</span>,
    },
    {
      key: "appointment_type",
      text: "Appointment Type",
      align: "center",
      sortable: false,
      cell: (data) => (
        <span>
          {data?.appointment_type === 1
            ? "Online"
            : data?.appointment_type === 2
              ? "Physical"
              : "Online And Physical"}
        </span>
      ),
    },
    {
      key: "status",
      text: "Status",
      align: "center",
      sortable: false,
      cell: (data) => <span>{data?.status === 1 ? "Active" : "Inactive"}</span>,
    },
    {
      key: "action",
      text: "Action",
      align: "center",
      // sortable: true,
      cell: (data) => (
        <div className="d-flex gap-2 justify-content-center">
          <button
            type="button"
            className="btn btn-warning btn-sm"
            onClick={() => {
              setModalOpen((prevState) => !prevState);
              setSingleChamberData(data);
            }}
          >
            <i className="fa-regular fa-eye"></i>
          </button>

          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => {
              setEditMode((prevState) => !prevState);
              setSingleChamberData(data);
            }}
          >
            <i className="fa fa-edit"></i>
          </button>

          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => {
              setDeleteModalOpen((prevState) => !prevState);
              setSingleChamberData(data);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];
  const config = {
    page_size: 10,
    show_filter: false,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    // button: {
    //     extra: true,
    // },
  };
  //
  // const extraButtons = [
  //     {
  //         className: "bg-transparent border-0",
  //
  //         title: "Search",
  //         children: [
  //             <Form.Group controlId="formBasicText">
  //                 <Form.Control
  //                     type={"search"}
  //                     placeholder={"Search"}
  //                     // value={value}
  //                     onChange={(e) => handlerFilter(e.target.value)}
  //                 />
  //             </Form.Group>,
  //         ],
  //         onClick: (event) => {
  //             // console.log(event);
  //         },
  //     },
  // ];

  const handleDelete = async () => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/doctor/chamber/${singleChamberData?.id}`,
      );

      if (res.data.status === "OK") {
        toast.success("Deleted Successfully");
        setRefresh(!refresh);
        setDeleteModalOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CardLayout title="All Chamber List">
      <div className="table-responsive">
        <ReactTable
          data={chamberList}
          columns={columns}
          // extraButtons={extraButtons}
        />
      </div>

      {isModalOpen && (
        <ScheduleDetailsModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          data={singleChamberData}
        />
      )}

      {isEditMode && (
        <Modal
          show={isEditMode}
          size="xl"
          onHide={() => setEditMode(false)}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <h5>Edit {singleChamberData?.name}</h5>
          </Modal.Header>
          <Modal.Body>
            <AddChamber
              editData={singleChamberData}
              userDetails={userDetails}
              onClose={() => setEditMode(false)}
              refresh={() => setRefresh(!refresh)}
            />
          </Modal.Body>
        </Modal>
      )}

      {/*// delete modal*/}

      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          handleDelete={handleDelete}
        />
      )}
    </CardLayout>
  );
};

export default ChamberList;

