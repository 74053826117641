import CardLayout from "../../../components/Layout/CardLayout";
import FormLayout from "../../../components/Layout/FormLayout";
import { Form, Button } from "react-bootstrap";
import FormLabel from "../../../components/Text/FormLabel";
import ReactInput from "../../../components/InputList/ReactInput/ReactInput";
import request from "../../../request/request";

const UploadDoctor = () => {
  const initialValues = {
    file: "",
  };

  const fileUploader = async (values) => {
    try {
      console.log(values);
      const formatData = new FormData();
      formatData.append("file", values.file);
      const res = await request.create(
        "users/upload/excel/doctors",
        formatData,
      );
      console.log(res);
      return null;
    } catch (err) {
      return err;
    }
  };

  return (
    <CardLayout title="Upload Doctor">
      <FormLayout
        initialValues={initialValues}
        apiHandler={fileUploader}
        noButton
      >
        <Form.Group>
          <FormLabel title="Upload excel file" />
          <ReactInput name="file" type="file" accept=".xlsx" />
        </Form.Group>

        <div className="d-flex justify-content-center mt-4">
          <Button type="submit">Upload</Button>
        </div>
      </FormLayout>
    </CardLayout>
  );
};

export default UploadDoctor;
