import React, {useContext} from 'react';
import useFetchData from "../../hooks/useFetchData";
import CardLayout from "../Layout/CardLayout";
import ReactTable from "../ReactDataTable/ReactTable";
import formatDateTime from "../../utils/DateTimeFormate";
import {UserContext} from "../../Context/UserContextAPI";
import {Link} from "react-router-dom";

const PaymentHistory = ({user_id}) => {
    const {data: paymentData} = useFetchData(`services/service-history/transaction/user/${user_id}`);
    const {accessPerm} = useContext(UserContext);

    const columns = [
        // {
        //     key: "user_id",
        //     text: "User id",
        //     align: "center",
        //     // sortable: true,
        //     cell: (data) => <span>{data?.user?.id}</span>,
        // },
        // {
        //     key: "user_name",
        //     text: "User Name",
        //     align: "center",
        //     // sortable: true,
        //     cell: (data) => <span>{data?.user?.f_name}</span>,
        // },
        // {
        //     key: "user_mobile",
        //     text: "User Mobile",
        //     align: "center",
        //     // sortable: true,
        //     cell: (data) => <span>{data?.user?.mobile}</span>,
        // },
        {
            key: "product_category",
            text: "Payment Type",
            align: "center",
            // sortable: true,
        },
        {
            key: "product_name",
            text: "Service Name/ID",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
        },
        {
            key: "product_profile",
            text: "Service Type",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
        },
        {
            key: "total_amount",
            text: "Paid Amount",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => <span>{data?.total_amount} TK</span>,
        },
        {
            key: "tran_id",
            text: "transaction ID",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
        },
        {
            key: "card_brand",
            text: "Payment Method",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
        },
        {
            key: "tran_date",
            text: "Date",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: true,
            cell: (data) => {
                return <span>{formatDateTime(data.tran_date)}</span>;
            },
        },
        {
            key: "status",
            text: "status",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data.status === 1 ? "Successful" : data?.status === 2 ? "Canceled" : "Failed"}</span>;
            },
        },
        {
            key: "action",
            text: "Action",
            // className: 'action',
            width: 80,
            align: "center",
            sortable: false,
            cell: (data) => {
                return (
                    <>
                        {accessPerm(43, 9) && (
                            <Link
                                className="btn btn-primary btn-sm"
                                to={`/dashboard/transaction/invoice/${data.tran_id}`}
                            >
                                {" "}
                                Invoice
                            </Link>
                        )}
                    </>
                );
            },
        },
    ];

    console.log(paymentData?.data);


    return (
        <section>
            <CardLayout title={"Payment History"}>
                <div className='overflow-auto'>
                    <ReactTable
                        data={paymentData?.data}
                        columns={columns}
                    />
                </div>
            </CardLayout>

        </section>
    );
};

export default PaymentHistory;