import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import KnowledgeBaseForm from "./KnowledgeBaseForm";
import CardLayout from "../../../components/Layout/CardLayout";
import useFetchData from "../../../hooks/useFetchData";
import { useParams, useNavigate } from "react-router-dom";

const UpdateKnowledgeBase = () => {
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const { data: knowledgeBase, setRefresh } = useFetchData(
    `knowledgeBase/${id}`,
  );

  const createKnowledgeBase = async (formData) => {
    try {
      // console.log(formData)
      // return
      setSubmitting(true);
      const data = new FormData();
      data.append("category_id", formData.category_id);
      data.append("title", formData.title);
      data.append("info", formData.info);
      data.append("short_info", formData.short_info);
      data.append("order_by", formData.order_by);
      data.append("status", formData.status);
      data.append("image", formData.image);
      data.append("video", formData.video);
      const res = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/knowledgeBase/${id}`,
        data,
      );

      console.log(res);
      if (res.data?.status === "OK") {
        toast.success("Knowledge base category created successfully");

        navigate("/dashboard/knowledge-base/list");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CardLayout title="Update Knowledge Base">
      <KnowledgeBaseForm
        apiHandler={createKnowledgeBase}
        editData={knowledgeBase?.data}
        submitting={submitting}
      />
    </CardLayout>
  );
};

export default UpdateKnowledgeBase;

