import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import CardLayout from "../../Layout/CardLayout";
import ReactTable from "../../ReactDataTable/ReactTable";
import axios from "axios";
import { toast } from "react-hot-toast";
import DeleteModal from "../../Modal/DeleteModal";

const MinimumPaymentValue = ({ userData }) => {
  const [isModalOpen, setModalOpen] = useState("");
  const [allData, setAllData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [refresh, setRefresh] = useState(false);

  console.log(userData);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/min-payment-value/get-all/${userData?.id}`,
        );

        console.log(data);
        if (data?.status === "OK") {
          setAllData(data?.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [userData?.id, refresh]);

  const columns = [
    {
      key: "min_value",
      text: "Value",
    },
    {
      key: "description",
      text: "Description",
    },
    {
      key: "status",
      text: "Status",
      cell: (row) => (row?.status === 1 ? "Active" : "InActive"),
    },
    {
      key: "action",
      text: "Action",
      cell: (row) => (
        <div>
          <Button
            size="sm"
            onClick={() => {
              setModalOpen("update");
              setSelectedItem(row);
            }}
          >
            <i className="fa fa-edit"></i>
          </Button>

          <Button
            size="sm"
            className="ms-2"
            variant="danger"
            onClick={() => {
              setModalOpen("delete");
              setSelectedItem(row);
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  // create fn

  const formHandler = async (event) => {
    try {
      event.preventDefault(); // Prevent the default form submission behavior

      const formData = new FormData(event.currentTarget); // Create a new FormData object from the form

      const formDataObject = {
        ...Object.fromEntries(formData),
        status: event.target.status?.checked ? 1 : 0,
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/min-payment-value`,
        { user_id: userData?.id, ...formDataObject },
      );

      if (data?.status === "OK") {
        toast.success("Minimum payment value add");
        setModalOpen(false);
        setRefresh((prev) => !prev);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // modal close fn
  const onClose = () => {
    setModalOpen("");
    setSelectedItem(null);
  };

  // update fn

  const updateHandler = async (event) => {
    try {
      event.preventDefault(); // Prevent the default form submission behavior

      const formData = new FormData(event.currentTarget); // Create a new FormData object from the form

      const formDataObject = {
        ...Object.fromEntries(formData),
        status: event.target.status?.checked ? 1 : 0,
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/min-payment-value/${selectedItem?.id}`,
        formDataObject,
      );

      if (data?.status === "OK") {
        toast.success("Minimum payment value update");
        setModalOpen(false);
        setRefresh((prev) => !prev);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // delete fn

  const deleteHandler = async () => {
    try {
      const data = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/min-payment-value/${selectedItem?.id}`,
      );
      console.log(data);

      if (data?.status === 204) {
        toast.success("Delete Successfully");
        setModalOpen(false);
        setRefresh((prev) => !prev);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <CardLayout
        title={"Min Payment Value"}
        buttonTitle={"Add Min Payment Value"}
        addButtonHandler={() => setModalOpen("create")}
      >
        <div className="overflow-auto">
          <ReactTable data={allData} columns={columns} />
        </div>

        {/*============ create ===============*/}

        {isModalOpen === "create" && (
          <FormModal
            isOPen={isModalOpen === "create"}
            onClose={onClose}
            formHandler={formHandler}
          />
        )}

        {/*============ update ===============*/}

        {isModalOpen === "update" && (
          <FormModal
            isOPen={isModalOpen === "update"}
            onClose={onClose}
            selectedItem={selectedItem}
            formHandler={updateHandler}
          />
        )}

        {/*============ update ===============*/}

        {isModalOpen === "delete" && (
          <DeleteModal
            isOpen={isModalOpen === "delete"}
            onClose={onClose}
            handleDelete={deleteHandler}
          />
        )}
      </CardLayout>
    </div>
  );
};

export default MinimumPaymentValue;

const FormModal = ({ isOPen, onClose, selectedItem, formHandler }) => {
  return (
    <Modal show={isOPen} onHide={onClose}>
      <Modal.Header>
        <h6>Add Min Value</h6>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formHandler}>
          <Form.Group>
            <Form.Label>Min Value (in %)</Form.Label>

            <Form.Control
              name="min_value"
              type="number"
              required
              max={100}
              defaultValue={selectedItem ? selectedItem?.min_value : ""}
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Description</Form.Label>

            <Form.Control
              name="description"
              defaultValue={selectedItem ? selectedItem?.description : ""}
            />
          </Form.Group>

          <Form.Group className="d-flex justify-content-end mt-3">
            <Form.Check
              label="active"
              name="status"
              defaultChecked={
                selectedItem
                  ? selectedItem?.status === 1
                    ? true
                    : false
                  : true
              }
            />
          </Form.Group>

          <div className="text-end">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
