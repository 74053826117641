import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactDatatable from "@ashvin27/react-datatable";
import { Form } from "react-bootstrap";
import EditServiceCharge from "./EditServiceCharge";
import DeleteServiceCharge from "./DeleteServiceCharge";

const ServiceChargeList = ({ userDetails }) => {
  const [serviceChargeList, setServiceChargeList] = useState([]);
  const [filterServiceChargeList, setFilterChargeList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  console.log(serviceChargeList);
  const fetchServiceChargeList = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/hospital/service_charge/${userDetails?.user_id}`,
      )
      .then((res) => {
        console.log(res.data?.data);

        if (res.data.status === "OK") {
          setServiceChargeList(res.data?.data);
          setFilterChargeList(res.data?.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchServiceChargeList();
  }, [userDetails?.user_id]);

  const columns = [
    {
      key: "name",
      text: "Name",
      align: "center",
      sortable: true,
    },
    {
      key: "price",
      text: "Price",
      align: "center",
      sortable: true,
      cell: (data) => <span>{data?.price} TK</span>,
    },
    {
      key: "discounted_price",
      text: "Discounted Price",
      align: "center",
      sortable: true,
      cell: (data) => <span>{data?.discounted_price} TK</span>,
    },
    {
      key: "pre_paid",
      text: "Pre-Paid",
      align: "center",
      sortable: true,
      cell: (data) => <span>{data?.pre_paid === 1 ? "yes" : "no"} </span>,
    },

    {
      key: "status",
      text: "status",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return <span>{data.status === 1 ? "Active" : "Inactive"}</span>;
      },
    },
    {
      key: "action",
      text: "Action",
      // className: 'action',
      width: 120,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <>
            <>
              {/* Edit Drug Group Trigger Button */}

              <button
                type="button"
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setSelectedItem(data);
                  setIsModalOpen(true);
                }}
              >
                <i className="fa fa-edit"></i>
              </button>

              {/* Delete Drug Group Trigger Button */}

              <button
                type="button"
                className="btn btn-danger btn-sm"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setSelectedItem(data);
                  setDeleteModalOpen(true);
                }}
              >
                <i className="fa fa-trash"></i>
              </button>
            </>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    show_filter: false,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      extra: true,
    },
  };

  // search component

  const extraButtons = [
    {
      className: "bg-transparent border-0",

      title: "Search",
      children: [
        <Form.Group controlId="formBasicText">
          <Form.Control
            type={"search"}
            placeholder={"Search"}
            // value={value}
            onChange={(e) => handlerFilter(e.target.value)}
          />
        </Form.Group>,
      ],
      onClick: (event) => {
        // console.log(event);
      },
    },
  ];

  // search/filter functions

  const handlerFilter = (value) => {
    setFilterChargeList(
      serviceChargeList.filter((item) => {
        return (
          item?.req_no?.toString()?.includes(value.toLowerCase()) ||
          item?.name.toLowerCase().includes(value.toLowerCase()) ||
          item?.price.toString()?.toLowerCase().includes(value.toLowerCase()) ||
          item?.discounted_price
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      }),
    );
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">All Service Charge List</h6>
              <div className="col">{/* Modal */}</div>
            </div>

            <hr />

            <ReactDatatable
              config={config}
              records={filterServiceChargeList}
              columns={columns}
              extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>

      <>
        {isModalOpen && (
          <EditServiceCharge
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            editData={selectedItem}
            refresh={fetchServiceChargeList}
          />
        )}

        {isDeleteModalOpen && (
          <DeleteServiceCharge
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            deleteId={selectedItem?.id}
            refresh={fetchServiceChargeList}
          />
        )}
      </>
    </div>
  );
};

export default ServiceChargeList;

