import React from 'react';
import Modal from "react-bootstrap/Modal";
import PackageCategoryForm from "./PackageCategoryForm";
import axios from "axios";
import toast from "react-hot-toast";

const EditPackageCat = ({isOpen, onClose, editData, refresh}) => {
    const editPackageCategory = (formData) => {

        axios
            .put(`${process.env.REACT_APP_API_BASE_URL}/package-category/${editData?.id}`, formData)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    toast.success(response?.data?.message)
                    refresh();
                    onClose();
                }

            })
            .catch((error) => console.log(error));
    }
    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header><h5>Create New Package Category</h5></Modal.Header>
            <Modal.Body>
                <PackageCategoryForm onClose={onClose} apiHandler={editPackageCategory} editData={editData}/>
            </Modal.Body>

        </Modal>
    );
};

export default EditPackageCat;