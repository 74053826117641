import React from 'react';
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import {format24to12} from "../../../utils/timeAndDateFunction";

const ScheduleDetailsModal = ({isOpen, onClose, data}) => {
    return (
        <Modal show={isOpen} onHide={onClose} size='lg'>
            <Modal.Header closeButton>
                Schedule List
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Day</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data?.chamber_schedule.length > 0 ? data?.chamber_schedule?.map((schedule, i) => (
                                <tr key={schedule.id}>
                                    <td>{i + 1}</td>
                                    <td>{schedule?.day?.name}</td>
                                    <td>{format24to12(schedule?.start_time)}</td>
                                    <td>{format24to12(schedule?.end_time)}</td>
                                </tr>))
                            : <p className='text-center my-3 fs-5'>NO Schedule found</p>
                    }


                    </tbody>
                </Table>
            </Modal.Body>

        </Modal>
    );
};

export default ScheduleDetailsModal;