import React from 'react';
import axios from "axios";
import {toast} from "react-hot-toast";
import NameInfoForm from "../../../components/Form/NameInfoForm";

const AddAccommodationType = ({isOpen, onClose, refresh}) => {

    // create accommodation type

    const createAccommodationType = (formData) => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/settings/accommodation`, formData)
            .then(res => {
                if (res.data.status === "OK") {
                    toast.success("Accommodation created successfully")
                    onClose()
                    refresh()
                }
                console.log(res.data)
            })
            .catch(err => console.log(err))
    }


    return (
        <div>
            <NameInfoForm
                isOpen={isOpen}
                onClose={onClose}
                title={'Add New Accommodation Type'}
                apiHandler={createAccommodationType}
            />
        </div>
    );
};

export default AddAccommodationType;