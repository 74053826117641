import React from "react";
import AsyncSelect from "react-select/async";

const AsyncReactSelect = ({
  data,
  onChange,
  valueName,
  labelName,
  placeholder,
  value,
  isMulti,
  hasDefaultOption,
  ...rest
}) => {
  const options = data?.map((item) => ({
    value: item[valueName] || item?.id,
    label:
      labelName === "name"
        ? `${item.f_name} ${item.l_name ?? ""}`
        : item[labelName] ||
          item?.f_name?.toString() ||
          item?.title ||
          item?.id?.toString(),
  }));

  const filterColors = (inputValue) => {
    return options.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterColors(inputValue));
      }, 1000);
    });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "white", // Control background color
      borderColor: state.isFocused
        ? "#0e918a"
        : rest?.invalid
          ? "red"
          : "#0e918a", // Border color
      // borderWidth: state.isFocused ? '2px' : '1px', // Border width

      boxShadow: state.isFocused ? "0 0 0 3px rgba(14, 145, 138, 0.3)" : "none", // Box shadow on focus
      "&:hover": {
        borderColor: state.isFocused ? "#0e918a" : "#0e918a", // Hover border color
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#0e918a" : "white", // Selected option background color
      color: state.isSelected ? "white" : "#495057", // Selected option text color
      "&:hover": {
        backgroundColor: "#0e918a", // Hover background color
        color: "white", // Hover text color
      },
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  return (
    <AsyncSelect
      isMulti={isMulti}
      onChange={(e) =>
        isMulti ? onChange(e.map((item) => item)) : onChange(e?.value)
      }
      value={isMulti ? value : options?.find((item) => item.value === value)}
      cacheOptions
      defaultOptions={hasDefaultOption ? options : []}
      styles={customStyles}
      loadOptions={promiseOptions}
      {...rest}
    />
  );
};

export default AsyncReactSelect;
