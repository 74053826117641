import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { UserContext } from "../../Context/UserContextAPI";
import Navbar from "../Navbar/Navbar";
import "./DashboardLayout.scss";

const MenuItem = ({ iconClass, title, children }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (event) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <li>
      <Link
        to="#"
        // className={`bi bi-chevron-lef ${expanded ? "expanded" : ""}`}
        className="d-flex justify-content-between"
        onClick={handleToggle}
      >
        <div className="d-flex">
          <div className="parent-icon">
            <i className={iconClass} />
          </div>
          <div className="menu-title">{title}</div>
        </div>
        <i className={`bi bi-chevron-${expanded ? "down" : "left"}`}></i>
      </Link>
      {expanded && <ul className="submenu-expanded">{children}</ul>}
    </li>
  );
};
const DashboardLayout = () => {
  const { accessPerm } = useContext(UserContext);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [sidebarHovered, setSidebarHovered] = useState(false);
  const [data, setData] = useState({});
  const [activeRoles, setActiveRoles] = useState([]);
  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/settings`)
      .then((response) => {
        const allData = response.data.data[0];
        setData(allData);
        // formData.append('name', data.name)
        // console.log(formData)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log(allRoles)

  const getAllActiveRoles = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/roles/active/findAll`,
      );
      setActiveRoles(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getAllActiveRoles();
    // setUpData({...upData, [data.key] : data.value})
  }, []);

  const handleToggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <div className="dashboard-layout">
      <link
        rel="icon"
        href={`${process.env.REACT_APP_UPLOAD_URL}/${data?.logo_image}`}
      />
      <div className="navbar">
        <Navbar />
      </div>
      <div className={`wrapper ${sidebarCollapsed ? "toggled" : ""}`}>
        <aside
          className={`sidebar-wrapper ${sidebarCollapsed ? "toggled" : ""} ${
            sidebarHovered ? "sidebar-hovered" : ""
          }`}
          data-simplebar="true"
          onMouseEnter={() => setSidebarHovered(true)}
          onMouseLeave={() => setSidebarHovered(false)}
        >
          <Link to="/dashboard">
            <div onClick={handleToggleSidebar} className="sidebar-header">
              <div>
                {data?.logo_image ? (
                  <>
                    {sidebarCollapsed ? (
                      <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.fav_image}`}
                        className=""
                        alt="logo icon"
                        style={{ height: "50px", width: "100%" }}
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.logo_image}`}
                        className=""
                        alt="logo icon"
                        style={{ height: "50px", width: "100%" }}
                      />
                    )}
                  </>
                ) : (
                  // <img src={`http://localhost:5000/api/${data?.logo_image}`} alt="" />
                  `${data?.website_name}`
                )}
              </div>
              <div className="toggle-icon ms-auto">
                <i className="bi bi-chevron-double-left" />
              </div>
            </div>
          </Link>

          <ul className="metismenu" id="menu">
            {(accessPerm(38, 2) || accessPerm(38, 4)) && (
              <MenuItem iconClass="bi bi-suit-heart-fill" title="Blood Request">
                {accessPerm(38, 4) && (
                  <li>
                    <Link to="/dashboard/blood/requestlist">
                      <i className="bi bi-arrow-right-short" />
                      Request List
                    </Link>
                  </li>
                )}

                {accessPerm(38, 4) && (
                  <li>
                    <Link to="/dashboard/blood/donor-list">
                      <i className="bi bi-arrow-right-short" />
                      Donor List
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {(accessPerm(40, 2) || accessPerm(40, 4)) && (
              <MenuItem iconClass="bi bi-search" title="Health Manager">
                {accessPerm(40, 4) && (
                  <li>
                    <Link to="/dashboard/health-manager/subscribed-user">
                      <i className="bi bi-arrow-right-short" />
                      Subscriber List
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {accessPerm(39, 4) && (
              <MenuItem iconClass="'bi bi-card-checklist" title="Diagnosis">
                {accessPerm(39, 4) && (
                  <li>
                    <Link to="/dashboard/diagnosis/requestlist">
                      <i className="bi bi-arrow-right-short" />
                      Request List
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {accessPerm(39, 4) && (
              <MenuItem
                iconClass="bi bi-bag-plus-fill"
                title="Medicine Request"
              >
                {accessPerm(39, 4) && (
                  <li>
                    <Link to="/dashboard/medicine/request">
                      <i className="bi bi-arrow-right-short" />
                      Request List
                    </Link>
                  </li>
                )}
                {accessPerm(39, 4) && (
                  <li>
                    <Link to="/dashboard/medicine/stock">
                      <i className="bi bi-arrow-right-short" />
                      Medicine Stock
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {/*health products request */}

            {accessPerm(39, 4) && (
              <MenuItem iconClass="bi bi-bag-check" title="Products Request">
                {accessPerm(39, 4) && (
                  <li>
                    <Link to="/dashboard/health-products/request">
                      <i className="bi bi-arrow-right-short" />
                      Request List
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {/*Ambulance Request */}

            {accessPerm(39, 4) && (
              <MenuItem iconClass="bi bi-bag-check" title="Ambulance Request">
                {accessPerm(39, 4) && (
                  <li>
                    <Link to="/dashboard/ambulance/request">
                      <i className="bi bi-arrow-right-short" />
                      Request List
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {/*appointments request */}

            {accessPerm(39, 4) && (
              <MenuItem iconClass="bi bi-bag-check" title="Appointments">
                {accessPerm(39, 4) && (
                  <li>
                    <Link to="/dashboard/appointments/request">
                      <i className="bi bi-arrow-right-short" />
                      Request List
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {/*Treatment Request */}

            {accessPerm(39, 4) && (
              <MenuItem iconClass="bi bi-bag-check" title="Treatment Request">
                {accessPerm(39, 4) && (
                  <li>
                    <Link to="/dashboard/treatments/request">
                      <i className="bi bi-arrow-right-short" />
                      Request List
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {accessPerm(43, 4) && (
              <MenuItem iconClass="bi bi-currency-dollar" title="Transaction">
                {accessPerm(43, 4) && (
                  <li>
                    <Link to="/dashboard/transaction/successful">
                      <i className="bi bi-arrow-right-short" />
                      Successful Transaction
                    </Link>
                  </li>
                )}
                {accessPerm(43, 4) && (
                  <li>
                    <Link to="/dashboard/transaction/failed">
                      <i className="bi bi-arrow-right-short" />
                      Failed Transaction
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {(accessPerm(21, 4) ||
              accessPerm(22, 4) ||
              accessPerm(23, 4) ||
              accessPerm(24, 2) ||
              accessPerm(25, 4)) && (
              <MenuItem iconClass="bi bi-search" title="Investigation">
                {accessPerm(21, 4) && (
                  <li>
                    <Link to="/dashboard/investigation/investigation-groups">
                      <i className="bi bi-arrow-right-short" />
                      Groups
                    </Link>
                  </li>
                )}

                {accessPerm(22, 4) && (
                  <li>
                    <Link to="/dashboard/investigation/investigation-categories">
                      <i className="bi bi-arrow-right-short" />
                      Category
                    </Link>
                  </li>
                )}
                {accessPerm(23, 4) && (
                  <li>
                    <Link to="/dashboard/investigation/investigation-management">
                      <i className="bi bi-arrow-right-short" />
                      Investigation
                    </Link>
                  </li>
                )}

                {accessPerm(24, 2) && (
                  <li>
                    <Link to="/dashboard/investigation/test-management">
                      <i className="bi bi-arrow-right-short" />
                      Test
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {/* advertisement  */}
            {(accessPerm(41, 4) || accessPerm(42, 4)) && (
              <MenuItem iconClass="bi bi-file-slides" title="Advertisement">
                {accessPerm(41, 4) && (
                  <li>
                    <Link to="/dashboard/advertisement/adsection">
                      <i className="bi bi-arrow-right-short" />
                      Ad Section List
                    </Link>
                  </li>
                )}

                {accessPerm(42, 4) && (
                  <li>
                    <Link to="/dashboard/advertisement/advertisement-list">
                      <i className="bi bi-arrow-right-short" />
                      Advertisement List
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {/*Knowledge base */}

            {(accessPerm(41, 4) || accessPerm(42, 4)) && (
              <MenuItem iconClass="bi bi-file-slides" title="Knowledge Base">
                {accessPerm(41, 4) && (
                  <li>
                    <Link to="/dashboard/knowledge-base/category">
                      <i className="bi bi-arrow-right-short" />
                      Knowledge Base Category
                    </Link>
                  </li>
                )}

                {accessPerm(42, 4) && (
                  <li>
                    <Link to="/dashboard/knowledge-base/list">
                      <i className="bi bi-arrow-right-short" />
                      Knowledge Base List
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {/* Hub management section  */}

            {(accessPerm(41, 4) || accessPerm(42, 4)) && (
              <MenuItem iconClass="bi bi-file-slides" title="Hub Management">
                {accessPerm(41, 4) && (
                  <li>
                    <Link to="/dashboard/hub-management/add">
                      <i className="bi bi-arrow-right-short" />
                      Create Hub
                    </Link>
                  </li>
                )}

                {accessPerm(41, 4) && (
                  <li>
                    <Link to="/dashboard/hub-management">
                      <i className="bi bi-arrow-right-short" />
                      Hub List
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {/*Financial Report*/}

            {(accessPerm(26, 4) || accessPerm(27, 4)) && (
              <MenuItem
                iconClass="bi bi-file-earmark-person"
                title="Financial Reports"
              >
                {accessPerm(36, 4) && (
                  <li>
                    <Link to="/dashboard/financial/cashflow-statements">
                      <i className="bi bi-arrow-right-short" />
                      Cash flow Statement
                    </Link>
                  </li>
                )}

                {accessPerm(27, 4) && (
                  <li>
                    <Link to="/dashboard/financial/income-statements">
                      <i className="bi bi-arrow-right-short" />
                      Income Statement
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}

            {/*accounts sections */}

            <MenuItem iconClass="bi bi-file-earmark-person" title="Accounts">
              {accessPerm(26, 4) && (
                <li>
                  <Link to="/dashboard/transection-type" target="_blank">
                    <i className="bi bi-arrow-right-short" />
                    Transaction Type
                  </Link>
                </li>
              )}
              {accessPerm(27, 4) && (
                <li>
                  <Link to="/dashboard/head-identifier" target="_blank">
                    <i className="bi bi-arrow-right-short" />
                    Head Identifier
                  </Link>
                </li>
              )}
              {accessPerm(28, 4) && (
                <li>
                  <Link to="/dashboard/head-classification" target="_blank">
                    <i className="bi bi-arrow-right-short" />
                    Head Classification
                  </Link>
                </li>
              )}
              {accessPerm(29, 4) && (
                <li>
                  <Link to="/dashboard/head-group" target="_blank">
                    <i className="bi bi-arrow-right-short" />
                    Head Group
                  </Link>
                </li>
              )}
              {accessPerm(30, 4) && (
                <li>
                  <Link to="/dashboard/head-type" target="_blank">
                    <i className="bi bi-arrow-right-short" />
                    Head Type
                  </Link>
                </li>
              )}
              {accessPerm(31, 4) && (
                <li>
                  <Link to="/dashboard/account-head" target="_blank">
                    <i className="bi bi-arrow-right-short" />
                    Account Head
                  </Link>
                </li>
              )}
              <li>
                <Link to="/dashboard/account/voucher-entry" target="_blank">
                  <i className="bi bi-arrow-right-short" />
                  Voucher Entry
                </Link>
              </li>
              <li>
                <Link to="/dashboard/account/voucher-list" target="_blank">
                  <i className="bi bi-arrow-right-short" />
                  Voucher List
                </Link>
              </li>
              <li>
                <Link to="/dashboard/account/trail-balance" target="_blank">
                  <i className="bi bi-arrow-right-short" />
                  Trail Balance
                </Link>
              </li>
              <li>
                <Link to="/dashboard/account/ledger" target="_blank">
                  <i className="bi bi-arrow-right-short" />
                  Ledger
                </Link>
              </li>
              <li>
                <Link to="/dashboard/account/head-balance" target="_blank">
                  <i className="bi bi-arrow-right-short" />
                  Head Balance
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/account/profit-loss-statement"
                  target="_blank"
                >
                  <i className="bi bi-arrow-right-short" />
                  Profit Loss Statement
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/account/statement-financial-position"
                  target="_blank"
                >
                  <i className="bi bi-arrow-right-short" />
                  Balance Sheet
                </Link>
              </li>
            </MenuItem>

            {(accessPerm(5, 4) ||
              accessPerm(4, 4) ||
              accessPerm(2, 4) ||
              accessPerm(3, 4) ||
              accessPerm(3, 4) ||
              accessPerm(45, 1)) && (
              <MenuItem iconClass="bi bi-people" title="User Management">
                {accessPerm(5, 4) && (
                  <li>
                    <Link to="/dashboard/users">
                      <i className="bi bi-arrow-right-short" />
                      Users
                    </Link>
                  </li>
                )}
                {accessPerm(5, 4) &&
                  activeRoles &&
                  activeRoles.map((role) => (
                    <li key={role?.id}>
                      <Link to={`/dashboard/roles/${role?.id}`}>
                        <i className="bi bi-arrow-right-short" />
                        {role?.name}
                      </Link>
                    </li>
                  ))}
                {accessPerm(4, 4) && (
                  <li>
                    <Link to="/dashboard/roles">
                      <i className="bi bi-arrow-right-short" />
                      Roles
                    </Link>
                  </li>
                )}
                {accessPerm(2, 4) && (
                  <li>
                    <Link to="/dashboard/activity">
                      <i className="bi bi-arrow-right-short" />
                      Activities
                    </Link>
                  </li>
                )}
                {accessPerm(3, 4) && (
                  <li>
                    <Link to="/dashboard/module">
                      <i className="bi bi-arrow-right-short" />
                      Modules
                    </Link>
                  </li>
                )}

                {accessPerm(6, 2) && (
                  <li>
                    <Link to="/dashboard/user-management/role-permissions">
                      <i className="bi bi-arrow-right-short" />
                      Role Permissions
                    </Link>
                  </li>
                )}
                {accessPerm(6, 2) && (
                  <li>
                    <Link to="/dashboard/user-management/terms-conditions">
                      <i className="bi bi-arrow-right-short" />
                      Terms & Conditions
                    </Link>
                  </li>
                )}
                {accessPerm(6, 2) && (
                  <li>
                    <Link to="/dashboard/user-management/service-category">
                      <i className="bi bi-arrow-right-short" />
                      Service Category
                    </Link>
                  </li>
                )}

                <li>
                  <Link to="/dashboard/user-management/service-category-list">
                    <i className="bi bi-arrow-right-short" />
                    Service List
                  </Link>
                </li>
                {accessPerm(45, 1) && (
                  <li>
                    <Link to="/dashboard/upload-donor">
                      <i className="bi bi-arrow-right-short" />
                      Upload Donor
                    </Link>
                  </li>
                )}

                {accessPerm(45, 1) && (
                  <li>
                    <Link to="/dashboard/upload-doctor">
                      <i className="bi bi-arrow-right-short" />
                      Upload Doctor
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}
            {(accessPerm(33, 4) || accessPerm(34, 4) || accessPerm(34, 1)) && (
              <MenuItem iconClass="bi bi-box-seam" title="Package">
                {accessPerm(33, 4) && (
                  <li>
                    <Link to="/dashboard/package/package-category">
                      <i className="bi bi-arrow-right-short" />
                      Package Category
                    </Link>
                  </li>
                )}
                {accessPerm(34, 1) && (
                  <li>
                    <Link to="/dashboard/package/create-package">
                      <i className="bi bi-arrow-right-short" />
                      Create New Package
                    </Link>
                  </li>
                )}
                {accessPerm(34, 4) && (
                  <li>
                    <Link to="/dashboard/package/manage-package">
                      <i className="bi bi-arrow-right-short" />
                      Manage Packages
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}
            {/* {(accessPerm(5, 4) ||
              accessPerm(4, 4) ||
              accessPerm(2, 4) ||
              accessPerm(3, 4) ||
              accessPerm(3, 4)) && (
              <MenuItem iconClass="bi bi-people" title="Services">
                <li>
                  <Link to="/dashboard/services/contact-messages">
                    <i className="bi bi-arrow-right-short" />
                    Contact Messages
                  </Link>
                </li>
              </MenuItem>
            )} */}

            {(accessPerm(7, 4) ||
              accessPerm(8, 4) ||
              accessPerm(9, 4) ||
              accessPerm(10, 4) ||
              accessPerm(11, 4) ||
              accessPerm(12, 4) ||
              accessPerm(13, 4) ||
              accessPerm(14, 4) ||
              accessPerm(15, 4) ||
              accessPerm(16, 4) ||
              accessPerm(17, 4) ||
              accessPerm(18, 4) ||
              accessPerm(19, 4) ||
              accessPerm(20, 4) ||
              accessPerm(1, 2)) && (
              <MenuItem iconClass="bi bi-gear" title="Settings">
                {accessPerm(32, 4) && (
                  <li>
                    <Link to="/dashboard/settings/file-upload-types">
                      <i className="bi bi-arrow-right-short" />
                      File Upload Types
                    </Link>
                  </li>
                )}
                {accessPerm(7, 4) && (
                  <li>
                    <Link to="/dashboard/static-content-groups">
                      <i className="bi bi-arrow-right-short" />
                      Static Content Groups
                    </Link>
                  </li>
                )}
                {accessPerm(8, 4) && (
                  <li>
                    <Link to="/dashboard/static-content">
                      <i className="bi bi-arrow-right-short" />
                      Static Contents
                    </Link>
                  </li>
                )}

                {accessPerm(9, 4) && (
                  <li>
                    <Link to="/dashboard/settings/health-products-category">
                      <i className="bi bi-arrow-right-short" />
                      Health Products Category
                    </Link>
                  </li>
                )}
                {accessPerm(10, 4) && (
                  <li>
                    <Link to="/dashboard/settings/health-products">
                      <i className="bi bi-arrow-right-short" />
                      Health Products
                    </Link>
                  </li>
                )}
                {accessPerm(11, 4) && (
                  <li>
                    <Link to="/dashboard/setting/drug-groups">
                      <i className="bi bi-arrow-right-short" />
                      Drugs Group
                    </Link>
                  </li>
                )}

                {accessPerm(12, 4) && (
                  <li>
                    <Link to="/dashboard/setting/drugs">
                      <i className="bi bi-arrow-right-short" />
                      Drugs
                    </Link>
                  </li>
                )}
                {accessPerm(12, 4) && (
                  <li>
                    <Link to="/dashboard/setting/accommodation-type">
                      <i className="bi bi-arrow-right-short" />
                      Accommodation Type
                    </Link>
                  </li>
                )}

                {accessPerm(13, 4) && (
                  <li>
                    <Link to="/dashboard/settings/room-management">
                      <i className="bi bi-arrow-right-short" />
                      Room Management
                    </Link>
                  </li>
                )}

                {accessPerm(13, 4) && (
                  <li>
                    <Link to="/dashboard/settings/cash-on-delivery">
                      <i className="bi bi-arrow-right-short" />
                      Cash on Delivery
                    </Link>
                  </li>
                )}

                {accessPerm(13, 4) && (
                  <li>
                    <Link to="/dashboard/settings/delivery-charge">
                      <i className="bi bi-arrow-right-short" />
                      Delivery Charge
                    </Link>
                  </li>
                )}

                {accessPerm(13, 4) && (
                  <li>
                    <Link to="/dashboard/settings/manage-by-hub">
                      <i className="bi bi-arrow-right-short" />
                      Manage By Hub
                    </Link>
                  </li>
                )}

                {accessPerm(14, 4) && (
                  <li>
                    <Link to="/dashboard/settings/gender">
                      <i className="bi bi-arrow-right-short" />
                      Gender
                    </Link>
                  </li>
                )}
                {accessPerm(15, 4) && (
                  <li>
                    <Link to="/dashboard/settings/uom">
                      <i className="bi bi-arrow-right-short" />
                      Unit of Measurements
                    </Link>
                  </li>
                )}

                {accessPerm(16, 4) && (
                  <li>
                    <Link to="/dashboard/settings/professions">
                      <i className="bi bi-arrow-right-short" />
                      Professions
                    </Link>
                  </li>
                )}
                {accessPerm(17, 4) && (
                  <li>
                    <Link to="/dashboard/settings/merital-status">
                      <i className="bi bi-arrow-right-short" />
                      Marital Status
                    </Link>
                  </li>
                )}
                {accessPerm(18, 4) && (
                  <li>
                    <Link to="/dashboard/settings/country">
                      <i className="bi bi-arrow-right-short" />
                      Country
                    </Link>
                  </li>
                )}
                {accessPerm(19, 4) && (
                  <li>
                    <Link to="/dashboard/settings/state">
                      <i className="bi bi-arrow-right-short" />
                      State
                    </Link>
                  </li>
                )}
                {accessPerm(20, 4) && (
                  <li>
                    <Link to="/dashboard/settings/city">
                      <i className="bi bi-arrow-right-short" />
                      City
                    </Link>
                  </li>
                )}

                {accessPerm(1, 2) && (
                  <li>
                    <Link to="/dashboard/settings/system-settings">
                      <i className="bi bi-arrow-right-short" />
                      System Settings
                    </Link>
                  </li>
                )}
              </MenuItem>
            )}
          </ul>
        </aside>

        <main className="page-content">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
