import React, { useContext, useEffect, useState } from "react";
import dateTimeFormatter from "../../utils/DateTimeFormate";
import { UserContext } from "../../Context/UserContextAPI";
import axios from "axios";
import JsBarcode from "jsbarcode";
import { useNavigate, useParams } from "react-router-dom";

const PaymentInvoice = () => {
  const [paymentData, setPaymentData] = useState([]);
  const { paymentInfo, prepInfo } = paymentData;
  const { currentUser } = useContext(UserContext);
  const [systemData, setSystemData] = useState({});
  const [patientIdDataUrl, setPatientIdDataUrl] = useState("");
  const [websiteName, setWebsiteName] = useState(
    "https://www.e-relatives.com/",
  );
  const { tranId } = useParams();
  //   console.log(tranId)
  const navigate = useNavigate();

  const handlePrint = () => {
    window.print();
  };

  // fetch payment data with transaction id
  async function fetchData() {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/services/service-history/payments/invoice/${tranId}`,
      );
      setPaymentData(result.data);
    } catch (error) {
      console.log(error);
    }
  }

  //fetch system data ;

  const getSystemData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/settings`)
      .then((response) => {
        const allData = response.data.data[0];
        setSystemData(allData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
    getSystemData();

    //bar code
    const patientRegisterBarcode = document.createElement("canvas");
    JsBarcode(patientRegisterBarcode, String(paymentInfo?.tran_id));
    setPatientIdDataUrl(patientRegisterBarcode.toDataURL());

    patientRegisterBarcode.toDataURL();

    setWebsiteName(window?.location?.hostname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tranId, paymentInfo?.tran_id, prepInfo]);

  function getDateWithTimeIncrement(days, timeStr) {
    // Get the current date and time
    const now = new Date();

    // Create a new date object that is incremented by the number of days
    const newDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + days,
    );

    // Parse the time string into hours and minutes
    const [hours, minutes] = timeStr?.split(":");

    // Set the hours and minutes of the new date object to the parsed values
    newDate.setHours(hours);
    newDate.setMinutes(minutes);

    return newDate;
  }

  return (
    <>
      {/* changing */}
      <section className="patient-invoice p-2">
        {/* changing */}
        <div className="d-flex justify-content-between ">
          <div className="">
            <div className="avatar">
              {systemData?.logo_image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/${systemData?.logo_image}`}
                  alt="logo"
                  className="w-50 h-50 "
                />
              ) : (
                `${systemData?.website_name}`
              )}

              <p className="address m-0">Address : {systemData?.address}</p>
              <p className="address  mb-2 print-mx-none">
                Mobile : {systemData?.mobile}
              </p>
            </div>
          </div>
          <div className="">
            <div className="text-center patient-invoice-header-middle">
              <h4 className="text-uppercase text-bold m-0">Money Receipt</h4>
              <p className="m-0">Printed by: {paymentInfo?.user?.f_name}</p>
            </div>
          </div>
          <div className="">
            <div
              className="d-flex align-items-center invoice-bar-code mx-auto"
              style={{ width: "70%" }}
            >
              <img
                src={patientIdDataUrl}
                alt="barcode1"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-between"
          style={{ gap: "200px" }}
        >
          <div className="w-50 d-flex">
            <div>
              <div className="fw-bold">Registration No</div>
              <div className="fw-bold">Name</div>
              <div className="fw-bold">Gender</div>
            </div>
            <div className="ms-auto">
              <div className="">: {paymentInfo?.user?.registration_no}</div>

              <div className="">
                : {paymentInfo?.user?.f_name} {paymentInfo?.user?.l_name}
              </div>
              <div className="">
                : {paymentInfo?.user?.user_detail?.gender?.name}
              </div>
            </div>
          </div>
          <div className="w-50 d-flex">
            <div>
              <div className="fw-bold">Mobile</div>
              <div className="fw-bold">Address</div>
              <div className="fw-bold">User Type</div>
            </div>
            <div className="ms-auto">
              <div className="">: {paymentInfo?.user?.mobile}</div>
              <div className="">
                : {paymentInfo?.user?.address_1},{" "}
                {paymentInfo?.user?.user_detail?.city?.name},{" "}
                {paymentInfo?.user?.user_detail?.country?.name}{" "}
              </div>
              <div className="">: {paymentInfo?.user?.role?.name}</div>
            </div>
          </div>
        </div>

        <div className="row mt-2 print-mx-none">
          {paymentInfo ? (
            <table className="table table-bordered print-mx-none m-0">
              <thead>
                <tr className="table-active">
                  <th
                    colSpan="3"
                    className="text-center colspan-heading padding-none"
                  >
                    Accounts Transaction Details
                  </th>
                </tr>
                <tr>
                  <th className="col-sm-8 padding-none">Description</th>
                  <th className="text-center padding-none">
                    Amount ({paymentInfo?.currency})
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="padding-none">
                    Paid for {paymentInfo?.product_category} -{" "}
                    {paymentInfo?.product_name}
                  </td>
                  <td className="text-end padding-none">
                    {paymentInfo?.total_amount} TK
                  </td>
                </tr>
                <tr>
                  <td className="text-end padding-none">Total Amount</td>
                  <td className="text-end padding-none">
                    {paymentInfo?.total_amount} TK
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="text-center colspan-heading">
                    Accounts Transaction Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="invoice-paid">
                  <td className="text-center">
                    <h4>Paid </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div className="row mt-3 print-mx-none">
          <div className="col-7 pe-2">
            <p className="m-0">
              Receiving Date: {dateTimeFormatter(new Date())}
            </p>

            <div className="d-flex gap-2">
              <p className="m-0 system-generate-invoice">
                Thank you for your payment on {systemData?.website_name}.
                Websites : {websiteName}
              </p>
            </div>
          </div>
          <div className="col-5 ps-2">
            <div className="d-flex h-100 flex-column">
              {prepInfo && (
                <p className=" flex-grow-1">
                  Report Delivery Date:{" "}
                  {dateTimeFormatter(
                    getDateWithTimeIncrement(
                      prepInfo?.investigationDetails?.preparation_duration,
                      prepInfo?.investigationDetails?.delivery_time,
                    ),
                  )}
                </p>
              )}
              <p className="text-center m-0 system-generate-invoice">
                System Generated Invoice, No Signature Required
              </p>
              <div className="border-bottom border-dark"></div>
              <p className="text-center">Official Seal & Signature</p>
            </div>
          </div>
        </div>
        <div className="row mt-2 print-mx-none">
          <div className="col-7"></div>
          <div className="col-5 text-center"></div>
        </div>
        <div className="invoice-footer d-flex mt-5 align-items-center justify-content-end print-mx-none">
          <div className="d-flex gap-2">
            <button className="btn btn-primary" onClick={() => handlePrint()}>
              Print
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/dashboard/patient-info")}
            >
              Back
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentInvoice;
