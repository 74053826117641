import React, { useContext, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import formatDateTime from "../../utils/DateTimeFormate";
import { UserContext } from "../../Context/UserContextAPI";
import axios from "axios";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";

export default function SuccessfulTransaction() {
  const [paymentData, setPaymentData] = useState([]);
  const [filteredPaymentData, setFilteredPaymentData] = useState([]);
  const { accessPerm } = useContext(UserContext);

  // successful payment data fetch

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/services/service-history/transaction/successful`,
        );
        // console.log(data)

        setPaymentData(data?.data);
        setFilteredPaymentData(data?.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const columns = [
    {
      key: "user_id",
      text: "User id",
      align: "center",
      // sortable: true,
      cell: (data) => <span>{data?.user?.id}</span>,
    },
    {
      key: "user_name",
      text: "User Name",
      align: "center",
      // sortable: true,
      cell: (data) => <span>{data?.user?.f_name}</span>,
    },
    {
      key: "user_mobile",
      text: "User Mobile",
      align: "center",
      // sortable: true,
      cell: (data) => <span>{data?.user?.mobile}</span>,
    },
    {
      key: "product_category",
      text: "Payment Type",
      align: "center",
      // sortable: true,
    },
    {
      key: "product_name",
      text: "Service Name/ID",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
    },
    {
      key: "product_profile",
      text: "Service Type",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
    },
    {
      key: "total_amount",
      text: "Paid Amount",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => <span>{data?.total_amount} TK</span>,
    },
    {
      key: "tran_id",
      text: "transaction ID",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
    },
    {
      key: "card_brand",
      text: "Payment Method",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
    },
    {
      key: "tran_date",
      text: "Date",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: true,
      cell: (data) => {
        return <span>{formatDateTime(data.tran_date)}</span>;
      },
    },
    {
      key: "status",
      text: "status",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return <span>Successful</span>;
      },
    },
    {
      key: "action",
      text: "Action",
      // className: 'action',
      width: 120,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <>
            {accessPerm(43, 9) && (
              <Link
                className="btn btn-primary btn-sm"
                to={`/dashboard/transaction/invoice/${data.tran_id}`}
              >
                {" "}
                Invoice
              </Link>
            )}
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    show_filter: false,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      extra: true,
    },
  };

  // search component

  const extraButtons = [
    {
      className: "bg-transparent border-0",

      title: "Search",
      children: [
        <Form.Group controlId="formBasicText">
          <Form.Control
            type={"search"}
            placeholder={"Search"}
            // value={value}
            onChange={(e) => handlerFilter(e.target.value)}
          />
        </Form.Group>,
      ],
      onClick: (event) => {
        // console.log(event);
      },
    },
  ];

  // search/filter functions

  const handlerFilter = (value) => {
    setFilteredPaymentData(
      paymentData.filter((item) => {
        return (
          item?.req_no?.toString()?.includes(value.toLowerCase()) ||
          item?.user?.id?.toString()?.includes(value.toLowerCase()) ||
          item?.user?.f_name.toLowerCase().includes(value.toLowerCase()) ||
          item?.product_profile
            ?.toString()
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          item?.product_name?.toString()?.includes(value.toLowerCase()) ||
          item?.user?.mobile?.toString()?.includes(value.toLowerCase())
        );
      }),
    );
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">
                All Successful transaction
              </h6>
              <div className="col">{/* Modal */}</div>
            </div>

            <hr />

            <ReactDatatable
              config={config}
              records={filteredPaymentData}
              columns={columns}
              extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
