import React from 'react';
import useFetchData from "../../hooks/useFetchData";
import CardLayout from "../Layout/CardLayout";
import ReactTable from "../ReactDataTable/ReactTable";
import {Link} from "react-router-dom";

const ProductOrder = ({user_id, role_id}) => {
    const {data} = useFetchData(`request/health-products/get-all-request/user/${user_id}`)

    const lastpart = [

        {
            key: "delivery_address",
            text: "Delivery Address",
            align: "center",

            cell: data =>
                <span> {data?.delivery_address}, {data?.delivery_city?.name}, {data?.delivery_state?.name}, {data.delivery_country?.name}</span>
        },
        {
            key: "total_amount",
            text: "Total Amount",
            align: "center",
            sortable: true,
            cell: data =>
                <span> {data?.total_amount} TK</span>
        },
        {
            key: "paid_amount",
            text: "Paid Amount",
            align: "center",
            sortable: true,
            cell: data =>
                <span> {data?.paid_amount} TK</span>
        },
        {
            key: "due_amount",
            text: "Due Amount",
            align: "center",
            sortable: true,
            cell: data =>
                <span> {data?.due_amount} TK</span>
        },

        {
            key: "status",
            text: "status",
            align: "center",
            sortable: true,
            cell: data =>
                <span>{
                    data?.status === 1 ? "confirmed"
                        : data?.status === 2 ? "Disbursed"
                            : data?.status === 3 ? "Accepted By Delivery"
                                : data?.status === 4 ? "Collected By Delivery"
                                    : data?.status === 5 ? "Delivery Completed"
                                        : null
                }</span>
        },
        {
            key: "action",
            text: "Action",
            align: "center",

            cell: data => <div className='d-flex justify-content-between align-items-center'>
                {
                    <Link to={`/dashboard/health-products/request/${data?.order_no}`}>
                        <button
                            type="button"

                            className="btn btn-warning btn-sm"
                            style={{marginRight: "5px"}}
                        >
                            <i className="fa-regular fa-eye"></i>
                        </button>
                    </Link>
                }
                {/*{*/}
                {/*    data?.status === 1 ?*/}
                {/*        <>{data?.product_list.every((product) => product.status === 3) ?*/}
                {/*            <Button size='sm' onClick={() => handleRequestForDisbursement(data?.order_no)}>Request For*/}
                {/*                Disbursement</Button> :*/}
                {/*            <p className='p-0 m-0 text-danger'>All Items Not Received Yet</p>} </> : null*/}
                {/*}*/}

            </div>

        },

    ];

    const UserInfo = [
        {
            key: "user_reg_no",
            text: "User Reg No",
            // className: 'action',
            // width: 100,
            align: "center",

            cell: (data) => <span>{data?.requester?.registration_no}</span>,
        },
        {
            key: "name",
            text: "User Name",
            // className: 'action',
            // width: 100,
            align: "center",

            cell: (data) => <span>{data?.requester?.f_name}</span>,
        },
        {
            key: "user_mobile",
            text: "User Mobile",
            // className: 'action',
            // width: 100,
            align: "center",

            cell: (data) => <span>{data?.requester?.mobile}</span>,
        },
    ]

    const deliveryPersonInfo = [
        {
            key: "delivery_person",
            text: "Delivery  person Reg",
            align: "center",
            cell: data => <span>{data?.delivery_person_info && data?.delivery_person_info?.registration_no}</span>

        },
        {
            key: "delivery_person_name",
            text: "Delivery person Name",
            align: "center",
            cell: data => <span>{data?.delivery_person_info && data?.delivery_person_info?.f_name}</span>
        },
        {
            key: "delivery_person_mobile",
            text: "Delivery Person mobile",
            align: "center",
            cell: data => <span>{data?.delivery_person_info && data?.delivery_person_info?.mobile}</span>
        },
    ]

    let columns = [{
        key: "order_no",
        text: "Order ID",
        align: "center",
        sortable: true,

    },]

    if (role_id === 10) {
        columns = [...columns, ...deliveryPersonInfo, ...lastpart]
    } else if (role_id === 12) {
        columns = [...columns, ...UserInfo, ...lastpart]
    } else if (role_id === 16) {
        columns = [...columns, ...UserInfo, ...deliveryPersonInfo, ...lastpart]
    }

    console.log(data?.data);


    return (
        <section>
            <CardLayout title={"Product Order List"}>
                <div className={columns?.length > 12 && "overflow-auto"}>
                    <ReactTable
                        data={data?.data}
                        columns={columns}
                    />
                </div>
            </CardLayout>

        </section>
    );
};

export default ProductOrder;