import React, {useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {toast} from "react-hot-toast";


const AddServiceCharge = ({userDetails}) => {
    const initialFormData = {
        id: 1,
        name: '',
        price: '',
        discounted_price: '',
        pre_paid: false,
        status: false,
        user_id: userDetails?.user_id
    };

    const [serviceChargeData, setServiceChargeData] = useState([initialFormData]);

    const incrementServiceField = () => {
        setServiceChargeData(prevState => [
            ...prevState,
            {
                ...initialFormData,
                id: prevState.length + 1,
            }
        ])
    }

    const decrementServiceField = (index) => {
        setServiceChargeData(prevState => prevState.filter((_, i) => i !== index));
    }

    const formValueController = (data, index) => {

        setServiceChargeData(prevState => {
            prevState[index][data.name] = data.type === 'checkbox' ? data.checked : data.value;
            return [...prevState]
        })
    }
    // console.log(serviceChargeData)

    const handleSaveData = async () => {
        try {
            const {data} = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/hospital/service_charge`,
                serviceChargeData,
            );
            console.log(data)
            if (data.status === 'OK') {
                toast.success("Service Charge Saved Successfully")
                setServiceChargeData([initialFormData])
            }

        } catch (err) {
            console.log(err)
        }
    }
    console.log(serviceChargeData)

    return (
        <div>
            <Form>
                <Form.Group className='row'>
                    <div className='row row-cols-4'>
                        <Form.Label>Name</Form.Label>
                        <Form.Label>Price</Form.Label>
                        <Form.Label>Discounted Price</Form.Label>
                        <div className='d-flex gap-3 text-center'>
                            {/*<Form.Label>Pre-paid</Form.Label>*/}
                            {/*<Form.Label>status</Form.Label>*/}
                        </div>
                    </div>

                </Form.Group>

                {
                    serviceChargeData.map((service, index) => (
                        <Form.Group key={service?.id} className='my-3'>

                            <div className='d-flex'>
                                <Row className='align-items-center'>
                                    <Col sm={2} md={3}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter service name"
                                            name='name'
                                            value={service.name}
                                            onChange={(e) => formValueController(e.target, index)}
                                        />
                                    </Col>
                                    <Col sm={2} md={3}>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter Price"
                                            name='price'
                                            value={service.price}
                                            onChange={(e) => formValueController(e.target, index)}
                                        />
                                    </Col>
                                    <Col sm={2} md={3}>
                                        <Form.Control
                                            type="number"
                                            placeholder="Discounted Price"
                                            name='discounted_price'
                                            value={service.discounted_price}
                                            onChange={(e) => formValueController(e.target, index)}
                                        />
                                    </Col>
                                    <Col sm={2} md={3}>
                                        <Form.Check
                                            inline={true}
                                            label='Pre-paid'
                                            name={'pre_paid'}
                                            checked={service.pre_paid}
                                            onChange={(e) => formValueController(e.target, index)}
                                        />
                                        <Form.Check
                                            inline={true}
                                            label='Status'
                                            name={'status'}
                                            checked={service.status}
                                            onChange={(e) => formValueController(e.target, index)}
                                        />
                                    </Col>
                                </Row>
                                <div className='d-flex gap-2'>
                                    <Button onClick={incrementServiceField}>
                                        +
                                    </Button>
                                    <Button variant='danger' onClick={() => decrementServiceField(index)}>
                                        -
                                    </Button>
                                </div>
                            </div>


                        </Form.Group>

                    ))
                }

                {
                    serviceChargeData.length < 1 && <div className='text-end mt-5'>
                        <Button onClick={incrementServiceField}>
                            +
                        </Button>
                    </div>
                }

                <div className='text-end mt-5'>
                    <Button onClick={handleSaveData}>
                        Save
                    </Button>
                </div>

            </Form>
        </div>
    );
};

export default AddServiceCharge;