import React from 'react';
import Modal from "react-bootstrap/Modal";
import CategoryForm from "./CategoryForm";
import axios from "axios";
import toast from "react-hot-toast";

const UpdateKnowledgeBaseCat = ({onClose, isOpen, editData, refresh}) => {

    const updateHandler = async (formData) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/knowledge-base-cat/${editData?.id}`, formData);

            console.log(res)
            if (res.data?.status === "OK") {
                toast.success("Updated knowledge base category successfully");
                onClose();
                refresh();
            }

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header closeButton>
                <h5>Update Knowledge category</h5>
            </Modal.Header>

            <Modal.Body>
                <CategoryForm apiHandler={updateHandler} editData={editData} onClose={onClose}/>
            </Modal.Body>

        </Modal>
    );
};

export default UpdateKnowledgeBaseCat;