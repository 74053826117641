import { useParams, useNavigate } from "react-router-dom";
import CardLayout from "../../components/Layout/CardLayout";
import useFetch from "../../hooks/useFetch";
import request from "../../request/request";
import LoaderLayout from "../../components/Loader/LoaderLayout";
import HubManagementFormContainer from "../../components/Form/HubManagement/HubManagementFormContainer";

const UpdateHub = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { result, isLoading } = useFetch(
    () => request.getOne("hub-management", id),
    id,
  );

  const updateHub = (formData) => {
    return request.updateOne("hub-management", id, formData, () => {
      navigate("/dashboard/hub-management");
    });
  };

  const hubDataSortingForForm = {
    ...result,
    service_area: result?.service_area_info?.map((item) => ({
      label: item?.name,
      value: item?.id,
    })),
    service_name: result?.service_name_info?.map((item) => ({
      label: item?.module_name,
      value: item?.id,
    })),
    hub_users: result?.hub_users_info?.map((item) => ({
      label: item?.f_name,
      value: item?.id,
    })),
  };
  return (
    <LoaderLayout isPending={isLoading}>
      <CardLayout title="Update Hub">
        <HubManagementFormContainer
          editData={hubDataSortingForForm}
          apiHandler={updateHub}
        />
      </CardLayout>
    </LoaderLayout>
  );
};

export default UpdateHub;
