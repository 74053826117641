import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import formatDateTime from "../../utils/DateTimeFormate";
import { UserContext } from "../../Context/UserContextAPI";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import request from "../../request/request";

export default function MedicineRequestList() {
  const [getAllMedicineRequest, setAllMedicineRequest] = useState([]);
  const [filteredMedicineRequest, setFilteredMedicineRequest] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const { accessPerm, currentUser } = useContext(UserContext);

  const { result: hasMedicineHub } = useFetch(() =>
    request.getOne("manage-by-hub/is-active", "medicine"),
  );

  console.log(hasMedicineHub);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/services/request/drug`,
        );
        console.log("data", data.data);
        setAllMedicineRequest(data.data);
        setFilteredMedicineRequest(data.data);
      } catch (error) {
        console.log("error while get all req list", error);
      }
    })();
  }, [refresh]);

  const reqForDestribution = async (id) => {
    try {
      request.updateOne(
        "services/request/drug/hub/delivery/distribute",
        id,
        "",
        () => {
          setRefresh(!refresh);
        },
      );
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      key: "req_no",
      text: "Request No:",
      align: "center",
      sortable: true,
    },
    {
      key: "req_type",
      text: "Request Type",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <span>
            {data.req_type === 1
              ? "General"
              : data.req_type === 2
                ? "Selective"
                : "unknown"}
          </span>
        );
      },
    },
    {
      key: "select_type",
      text: "Select Type",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <span>
            {data.req_type === 2
              ? "Prescription Pictures"
              : data.req_type === 1
                ? "Medicines"
                : "unknown"}
          </span>
        );
      },
    },
    {
      key: "reg_no",
      text: "Registration No:",
      // className: 'action',
      // width: 100,
      align: "center",
      // sortable: false,
      cell: (data) => {
        return <span>{data?.drug_requester?.registration_no}</span>;
      },
    },
    {
      key: "req_by",
      text: "Request By",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return <span>{data?.drug_requester?.f_name}</span>;
      },
    },
    {
      key: "mobile",
      text: "Mobile",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return <span>{data?.drug_requester?.mobile}</span>;
      },
    },
    {
      key: "confirm_medicine_shop_id",
      text: "Pharmacy",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return <span>{data?.confirm_medicine_shop?.f_name}</span>;
      },
    },
    {
      key: "delivery_person_id",
      text: "Delivery Person",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: true,
      cell: (data) => {
        return <span>{data.delivery_person?.f_name}</span>;
      },
    },
    {
      key: "createdAt",
      text: "Request Date",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: true,
      cell: (data) => {
        return <span>{formatDateTime(data.createdAt)}</span>;
      },
    },

    {
      key: "status",
      text: "status",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <div>
            <span>
              {data.status === 0
                ? "Requested"
                : data.status === 1
                  ? "Pharmacy respond"
                  : data.status === 2
                    ? "User Confirm"
                    : data.status === 3
                      ? "User Canceled"
                      : data.status === 4
                        ? "Pharmacy Ready the Medicine"
                        : data.status === 5
                          ? "Delivery Person Accepted"
                          : data.status === 6
                            ? "Delivery Person Collected the medicine"
                            : data.status === 7
                              ? "Delivery Complete"
                              : data?.status === 10
                                ? "Reqested for Destribution"
                                : "unknown"}
            </span>

            <div>
              {hasMedicineHub && data?.status === 2 && (
                <Button onClick={() => reqForDestribution(data?.req_no)}>
                  Request for Destribution
                </Button>
              )}
            </div>
          </div>
        );
      },
    },
    // {
    //   key: "action",
    //   text: "Action",
    //   // className: 'action',
    //   width: 120,
    //   align: "center",
    //   sortable: false,
    //   cell: (data) => {
    //     return (
    //       <>
    //         {accessPerm(39, 8) && data.status === 5 && (
    //           <Button
    //             onClick={() => {
    //               setShowUploadReport(true);
    //               setSingleItem(data);
    //             }}
    //             variant="success"
    //           >
    //             Upload Report
    //           </Button>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];
  const config = {
    page_size: 10,
    show_filter: false,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      extra: true,
    },
  };

  // search component

  const extraButtons = [
    {
      className: "bg-transparent border-0",

      title: "Search",
      children: [
        <Form.Group controlId="formBasicText">
          <Form.Control
            type={"search"}
            placeholder={"Search"}
            // value={value}
            onChange={(e) => handlerFilter(e.target.value)}
          />
        </Form.Group>,
      ],
      onClick: (event) => {
        // console.log(event);
      },
    },
  ];

  // search/filter functions

  const handlerFilter = (value) => {
    setFilteredMedicineRequest(
      getAllMedicineRequest.filter((item) => {
        return (
          item?.req_no?.toString()?.includes(value.toLowerCase()) ||
          item?.drug_requester?.f_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item?.service_center_name?.f_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item?.confirm_medicine_shop?.f_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item?.delivery_person?.f_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item?.drug_requester?.registration_no
            ?.toString()
            ?.includes(value.toLowerCase()) ||
          item?.drug_requester?.mobile
            ?.toString()
            ?.includes(value.toLowerCase())
        );
      }),
    );
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">All Medicine Request List</h6>
              <div className="col">{/* Modal */}</div>
            </div>

            <hr />

            <ReactDatatable
              config={config}
              records={filteredMedicineRequest}
              columns={columns}
              extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
