import React, {useState} from 'react';
import CardLayout from "../../../components/Layout/CardLayout";
import CreateKnowledgeBaseCat from "./CreateKnowledgeBaseCat";
import ReactTable from "../../../components/ReactDataTable/ReactTable";
import useFetchData from "../../../hooks/useFetchData";
import Button from "react-bootstrap/Button";
import UpdateKnowledgeBaseCat from "./UpdateKnowledgeBaseCat";
import axios from "axios";
import toast from "react-hot-toast";
import DeleteModal from "../../../components/Modal/DeleteModal";

const KnowledgeBaseCategory = () => {

    const [modelOpen, setModalOpen] = useState('');
    const [selectedItem, setSelectedItem] = useState('');

    const {data: knowledgeBaseCat, setRefresh} = useFetchData('knowledge-base-cat');


    const columns = [
        {
            key: "name",
            text: "Name",
            align: "left",
            sortable: true,
        },
        {
            key: "info",
            text: "Info",
            className: "info",
            align: "left",
            sortable: false,
        },
        {
            key: "status",
            text: "Status",
            className: "status",
            sortable: true,
            cell: (record) => {
                return <>{record.status === 1 ? "Active" : "Inactive"}</>;
            },
        },
        {
            key: "action",
            text: "Action",
            sortable: false,
            width: 150,
            cell: (record) => {
                return <>
                    <Button
                        type="button"
                        size='sm'
                        className='me-2'
                        onClick={() => {
                            setModalOpen('update');
                            setSelectedItem(record)
                        }}
                    >
                        <i className="fa fa-edit"></i>
                    </Button>
                    <Button
                        type="button"
                        variant='danger'
                        size='sm'
                        className='me-2'
                        onClick={() => {
                            setModalOpen('delete');
                            setSelectedItem(record)
                        }}
                    >
                        <i className="fa fa-trash"></i>
                    </Button>
                </>;
            },
        },
    ]


    const deleteHandler = async () => {
        try {
            const res = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/knowledge-base-cat/${selectedItem?.id}`);

            console.log(res)
            if (res.data?.status === "OK") {
                toast.success("Updated knowledge base category successfully");
                setModalOpen('')
                setRefresh(prevState => !prevState)
            }
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <CardLayout title='Knowledge Base Category' buttonTitle='Create New Category'
                    addButtonHandler={() => setModalOpen('create')}>

            <ReactTable
                columns={columns}
                data={knowledgeBaseCat?.data}
            />


            {
                modelOpen === 'create' &&
                <CreateKnowledgeBaseCat isOpen={modelOpen === 'create'} onClose={() => setModalOpen('')}
                                        refresh={() => setRefresh(prevState => !prevState)}/>
            }

            {
                modelOpen === 'update' &&
                <UpdateKnowledgeBaseCat isOpen={modelOpen === 'update'} onClose={() => setModalOpen('')}
                                        editData={selectedItem} refresh={() => setRefresh(prevState => !prevState)}/>
            }

            {
                modelOpen === 'delete' &&
                <DeleteModal isOpen={modelOpen === 'delete'} onClose={() => setModalOpen('')}
                             handleDelete={() => deleteHandler()}/>
            }

        </CardLayout>
    );
};

export default KnowledgeBaseCategory;