import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import formatDateTime from "../../utils/DateTimeFormate";
import { Form } from "react-bootstrap";

export default function SubscribedUser() {
  const [allSubsUser, setAllSubsUser] = useState([]);
  const [filteredSubsUser, setFilteredSubsUser] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/health-manager/getAllSubs`,
        );
        console.log("data", data);
        setAllSubsUser(data?.data);
        setFilteredSubsUser(data?.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  // console.log("l", filteredSubsUser);

  const columns = [
    {
      key: "image",
      text: "Image",
      className: "image",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record?.user_info?.image ? (
              <img
                className=" img-fluid"
                style={{ width: "50px" }}
                src={`${process.env.REACT_APP_UPLOAD_URL}/users/${record?.user_info?.image}`}
                // src={`${process.env.REACT_APP_UPLOAD_URL}/users/${record?.image}`}
                alt="Profile Pic"
              />
            ) : (
              <img
                className=" img-fluid"
                style={{ width: "50px" }}
                src="./dummy.jpg"
                alt="Profile Pic"
              />
            )}
          </>
        );
      },
    },
    {
      key: "registration_no",
      text: "Registration No",
      className: "registration_no",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record?.user_info?.registration_no}</>;
      },
    },
    {
      key: "name",
      text: "Name / Company",
      className: "info",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record?.user_info?.f_name} {record?.user_info?.l_name}
          </>
        );
      },
    },
    {
      key: "mobile",
      text: "Mobile",
      className: "mobile",
      sortable: true,
      cell: (record) => <span>{record?.user_info?.mobile}</span>,
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      sortable: true,
      cell: (record) => {
        return <>{record?.user_info?.email}</>;
      },
    },
    {
      key: "package",
      text: "Package",
      className: "info",
      align: "center",
      // sortable: true,
      cell: (data) => <span>{data?.package?.name}</span>,
    },
    {
      key: "package_cat",
      text: "Package Category",
      className: "info",
      align: "center",
      // sortable: true,
      cell: (data) => <span>{data?.package?.packageCat?.name}</span>,
    },
    {
      key: "purchase_date",
      text: "Subscription Date",
      className: "info",
      align: "center",
      sortable: true,
      cell: (data) => {
        return <span>{formatDateTime(data?.purchase_date)}</span>;
      },
    },
    {
      key: "expiry_date",
      text: "Expiry Date",
      className: "info",
      align: "center",
      sortable: true,
      cell: (data) => {
        return <span>{formatDateTime(data?.expiry_date)}</span>;
      },
    },
  ];
  const config = {
    page_size: 10,
    show_filter: false,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      extra: true,
    },
  };

  const extraButtons = [
    {
      className: "bg-transparent border-0",

      title: "Search",
      children: [
        <Form.Group controlId="formBasicText">
          <Form.Control
            type={"search"}
            placeholder={"Search"}
            // value={value}
            onChange={(e) => handlerFilter(e.target.value)}
          />
        </Form.Group>,
      ],
      onClick: (event) => {
        // console.log(event);
      },
    },
  ];

  // search/filter functions

  const handlerFilter = (value) => {
    setFilteredSubsUser(
      allSubsUser.filter((item) => {
        return (
          item?.user_info?.registration_no
            ?.toString()
            ?.includes(value.toLowerCase()) ||
          item?.user_info?.f_name.toLowerCase().includes(value.toLowerCase()) ||
          item?.user_info?.mobile?.toString()?.includes(value.toLowerCase())
        );
      }),
    );
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="border p-3 rounded">
          <div className="card-box">
            <h6 className="mb-0 text-uppercase">
              All Health Manager Subscribers List
            </h6>
            <div className="col">{/* Modal */}</div>
          </div>

          <hr />

          <ReactDatatable
            config={config}
            records={filteredSubsUser}
            columns={columns}
            extraButtons={extraButtons}
          />
        </div>
      </div>
    </div>
  );
}
