const bloodGroupsArray = [
    {status: 0, title: "unknown"},
    {status: 1, title: "A+"},
    {status: 2, title: "A-"},
    {status: 3, title: "B+"},
    {status: 4, title: "B-"},
    {status: 5, title: "O+"},
    {status: 6, title: "O-"},
    {status: 7, title: "AB+"},
    {status: 8, title: "AB-"},
];

export function getBloodGroup(status) {
    const selectedBloodGroup = bloodGroupsArray.find(group => group.status === parseInt(status));
    return selectedBloodGroup ? selectedBloodGroup.title : "unknown";
}

export function getBloodGroupNumber(title) {
    const selectedBloodGroup = bloodGroupsArray.find(group => group.title.toLowerCase()?.includes(title));
    return selectedBloodGroup ? selectedBloodGroup.status : 10;
}