import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const CategoryForm = ({ apiHandler, editData, onClose }) => {
  const formHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {
      ...Object.fromEntries(formData),
      status: event.target?.status.checked ? 1 : 0,
    };

    apiHandler(data);
  };

  return (
    <Form onSubmit={formHandler}>
      <Form.Group as={Row}>
        <Form.Label as={Col} sm={3}>
          Name <span className="text-danger">*</span>
        </Form.Label>
        <Col>
          <Form.Control
            name="name"
            type="text"
            placeholder="Type Category Name"
            required
            defaultValue={editData && editData?.name}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mt-3">
        <Form.Label as={Col} sm={3}>
          Info
        </Form.Label>
        <Col>
          <Form.Control
            as="textarea"
            name="info"
            placeholder="Type Category info"
            defaultValue={editData && editData?.info}
          />
        </Col>
      </Form.Group>

      <Form.Group className="d-flex justify-content-end my-3">
        <Form.Check
          name="status"
          label="Active"
          defaultChecked={editData && editData?.status === 1}
        />
      </Form.Group>

      <div className="d-flex gap-2 justify-content-end">
        <Button type="button" variant="secondary">
          Close
        </Button>
        <Button type="submit" variant="primary">
          Save Changes
        </Button>
      </div>
    </Form>
  );
};

export default CategoryForm;

