import axios from 'axios';
import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import './Ledger.scss';
import {format} from 'date-fns';
import Table from "react-bootstrap/Table";

const Ledger = () => {
    const [Ledger, setLedger] = useState([]);
    const [total, setTotal] = useState([]);
    const [transectionFrom, setTransectionFrom] = useState([]);
    const [transectionTo, setTransectionTo] = useState([]);
    const [headGroup, setHeadGroup] = useState([]);
    const [accountHead, setAccountHead] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [balance, setBalance] = useState(false);

    const handlerOnSearch = (e) => {
        e.preventDefault();

        const form = e.target;
        const fromDate = form.from_date.value;
        const toDate = form.to_date.value;
        const account_head_id = form.account_head_id.value;

        const filterData = {
            fromDate,
            toDate,
            account_head_id: account_head_id.trim(),
        };
        console.log(filterData);

        methodFilterPatientsVoucherList(filterData);
    };

    const methodFilterPatientsVoucherList = async (filterData) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/voucher/ledger/search`,
                filterData
            );
            console.log(response?.data);
            let initialBalance = 0;

            const updatedLedger = response?.data?.map((entry) => {
                if (entry.transection_for === 'Initial Balance') {
                    initialBalance = parseFloat(entry.amount) || 0;
                } else {
                    const entryAmount = parseFloat(entry.amount);
                    if (!isNaN(entryAmount)) {
                        // Check if entryAmount is a valid number
                        initialBalance += entryAmount;
                        entry.account_head.initial_balance = parseFloat(initialBalance);
                    }
                }
                setBalance(initialBalance);
                return entry;
            });

            setLedger(updatedLedger);
            setTotal();
        } catch (error) {
            console.log(error);
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            textAlign: 'left',
        }),
        menu: (provided) => ({
            ...provided,
            textAlign: 'left',
        }),
    };

    useEffect(() => {
        const fetchAPI = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/voucher/all`);
            const accountHead = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/account-head`
            );
            const data = response?.data?.data;
            const accHeadData = accountHead?.data?.data;
            setAccountHead(accHeadData);
            const transectionFromData = data?.map(
                (item) =>
                    `${item?.from_account_head?.code.trim()} - ${item?.from_account_head?.name.trim()}`
            );

            const uniqueTransectionFromData = [...new Set(transectionFromData)];

            const transectionToData = data?.map((item) => item?.to_account_head?.name.trim());

            const uniqueTransectionToData = [...new Set(transectionToData)];

            setTransectionFrom(uniqueTransectionFromData);
            setTransectionTo(uniqueTransectionToData);

            setLedger(data);
            setRefresh(refresh);
        };

        const fetchHeadGroup = async () => {
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/head-group/all`);
            const data = res?.data?.data;
            setHeadGroup(data);
        };
        fetchHeadGroup();
        fetchAPI();
    }, [refresh]);

    const columns = [
        {
            text: 'Date',
            align: 'left',
            sortable: true,
            cell: (record) => <>{record?.createdAt?.split('T')[0]}</>,
        },
        {
            text: 'Head Group',
            align: 'left',
            sortable: true,
            cell: (record) => <>{record?.head_group?.text}</>,
        },
        {
            text: 'Account Head',
            align: 'left',
            sortable: true,
            cell: (record) => (
                <>
                    {record?.account_head?.code} - {record?.account_head?.text}
                </>
            ),
        },
        {
            text: 'Transection For',
            className: '',
            align: 'left',
            sortable: true,
            cell: (record) => <>{record?.transection_for}</>,
        },
        {
            text: 'Debit',
            className: 'Debit',
            align: 'right',
            sortable: true,
            cell: (record) => (
                <div className='text-end'>{record?.amount <= 0 ? 0 : record?.amount}</div>
            ),
        },
        {
            text: 'Credit',
            className: 'Credit',
            align: 'right',
            sortable: true,
            cell: (record) => (
                <div className='text-end'>{record?.amount < 0 ? record?.amount * -1 : 0}</div>
            ),
        },
        {
            text: 'Balance',
            className: 'Balance',
            sortable: true,
            cell: (record) => (
                <div className='text-end'>{record?.account_head?.initial_balance}</div>
            ),
        },
        {
            text: 'Remarks',
            className: 'Remarks',
            sortable: true,
            cell: (record) => <>{record?.info}</>,
        },
    ];

    const config = {
        page_size: 10,
        show_filter: false,
        show_length_menu: false,
        show_pagination: false,
        button: {
            excel: false,
            print: false,
            extra: false,
        },
    };

    const extraButtons = [];

    const tableCustomStyles = {
        headRow: {
            style: {
                fontSize: '15px', // Increase the font size of header titles
                border: '1px solid #e0e0e0', // Add a border to the cells
                fontWeight: 'bold',
                backgroundColor: '#0d6efd',
                color: 'white',
            },
        },
        headCells: {
            style: {
                border: '1px solid #e0e0e0', // Add a border to every cell of the head row
                overflowWrap: 'break-word', // Wrap and break text to the next line
            },
        },
        cells: {
            style: {
                fontSize: '14px', // Adjust the font size of cell content as needed
                border: '1px solid #e0e0e0', // Add a border to the cells
                padding: '4px',
                whiteSpace: 'pre-wrap', // Set white space to pre-wrap for all cells
                fontWeight: 'bold',
            },
        },
    };


    return (
        <div className='card'>
            <div className='card-body'>
                <div className='border p-3 rounded'>
                    <div className='card-box'>
                        <h6 className='mb-0 text-uppercase'>Ledger</h6>
                    </div>

                    <hr/>
                    <div className='shadow-none'>
                        <div className='border p-1 rounded'>
                            <div className=''>
                                <form onSubmit={(e) => handlerOnSearch(e)}>
                                    <div className='d-flex justify-content-start gap-3'>
                                        <div className='w-25'>
                                            <label className='col-form-label'>
                                                From <span className='text-danger'>*</span>
                                            </label>
                                            <div className=''>
                                                <input
                                                    type='date'
                                                    data-date-format='YYYY MM DD'
                                                    name='from_date'
                                                    className='form-control'
                                                    placeholder='Date Picker...'
                                                    required
                                                    defaultValue={format(new Date(), 'yyyy-MM-dd')}
                                                />
                                            </div>
                                        </div>
                                        <div className='w-25'>
                                            <label className=' col-form-label'>
                                                To <span className='text-danger'>*</span>
                                            </label>
                                            <div className=''>
                                                <input
                                                    type='date'
                                                    data-date-format='YYYY MM DD'
                                                    name='to_date'
                                                    className='form-control'
                                                    placeholder='Date Picker...'
                                                    required
                                                    defaultValue={format(new Date(), 'yyyy-MM-dd')}
                                                />
                                            </div>
                                        </div>
                                        <div className='w-25'>
                                            <label className='col-form-label'>
                                                Account Head <span className='text-danger'>*</span>
                                            </label>
                                            <div className=''>
                                                <Select
                                                    className='patient-form-select'
                                                    name='account_head_id'
                                                    options={[
                                                        {
                                                            value: '',
                                                            label: 'Choose One...',
                                                        }, // Default option
                                                        ...accountHead?.map((accHead, i) => ({
                                                            value: accHead.id,
                                                            label: accHead.name,
                                                        })),
                                                    ]}
                                                    placeholder='Choose One...'
                                                    isSearchable
                                                    required
                                                    styles={customStyles}
                                                />
                                            </div>
                                        </div>

                                        <div className='d-flex justify-content-end align-items-end patient-info-search'>
                                            <button
                                                type='submit'
                                                className='btn btn-primary'
                                                style={{width: '6rem'}}
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div
                        className='d-flex justify-content-end mt-3'
                        style={{paddingRight: '15.6rem'}}
                    />
                    <div className='datatable-scroll-container'>
                        {/*<DataTable*/}
                        {/*    columns={[]}*/}
                        {/*    data={Ledger}*/}
                        {/*    // highlightOnHover*/}
                        {/*    // striped*/}
                        {/*    // dense*/}
                        {/*    // config={config}*/}
                        {/*    // extraButtons={extraButtons}*/}
                        {/*    // customStyles={tableCustomStyles}*/}
                        {/*/>*/}


                        <Table striped bordered hover className='mt-3'>
                            <thead>
                            <tr>

                                <th>Date</th>
                                <th>Head Group</th>
                                <th>Account Head</th>
                                <th>Transaction For</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Balance</th>
                                <th>Remarks</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Ledger?.length > 0 && Ledger.map((led, i) => (<tr>
                                    <td>{i + 1}</td>
                                    <td>{led?.createdAt?.split('T')[0]}</td>
                                    <td>{led?.head_group?.text}</td>
                                    <td>{led?.account_head?.code} - {led?.account_head?.text}</td>
                                    <td>{led?.transection_for}</td>
                                    <td>{led?.amount <= 0 ? 0 : led?.amount}</td>
                                    <td>{led?.amount < 0 ? led?.amount * -1 : 0}</td>
                                    <td>{led?.account_head?.initial_balance}</td>
                                    <td>{led?.info}</td>

                                </tr>))
                            }

                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Total Balance</td>
                                <td>{balance}</td>
                            </tr>


                            </tbody>
                        </Table>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ledger;
