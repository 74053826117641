import React, {useState} from 'react';
import axios from 'axios';
import './TrailBalance.scss';
import {format} from 'date-fns';
import Table from "react-bootstrap/Table";

const TrailBalance = () => {
    const [trailInfo, setTrailInfo] = useState([]);
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);

    const [transactionFrom, setTransactionFrom] = useState(null);
    const [transactionTo, setTransactionTo] = useState(null);
    const [initialDateFetched, setInitialDateFetched] = useState(false);

    console.log('trailInfo', trailInfo)

    const columns = [
        {
            text: 'SL NO',
            cell: (row, index) => index + 1,
            sortable: true,
        },
        {
            text: 'Code',
            cell: (row) => row?.accountHead?.code,
            sortable: true,
        },
        {
            text: 'Head Name',
            cell: (row) => row?.accountHead?.name,
            sortable: true,
        },
        {
            text: 'Debit',
            sortable: true,
            cell: (record) => <div className='w-100 text-end'>{record?.debit}</div>,
        },
        {
            text: 'Credit',
            sortable: true,
            cell: (record) => <div className='w-100 text-end'>{record?.credit}</div>,
        },
    ];

    const config = {
        page_size: 10,
        show_filter: false,
        show_length_menu: false,
        show_pagination: false,
        button: {
            excel: false,
            print: false,
            extra: false,
        },
    };

    const extraButtons = [];

    const customStyles = {
        headRow: {
            style: {
                fontSize: '15px', // Increase the font size of header titles
                border: '1px solid #000000', // Add a border to the cells
                fontWeight: 'bold',
                backgroundColor: '#0d6efd',
                color: 'white',
            },
        },
        headCells: {
            style: {
                border: '1px solid #000000', // Add a border to every cell of the head row
                overflowWrap: 'break-word', // Wrap and break text to the next line
            },
        },
        cells: {
            style: {
                fontSize: '14px', // Adjust the font size of cell content as needed
                border: '1px solid #000000', // Add a border to the cells
                padding: '4px',
                whiteSpace: 'pre-wrap', // Set white space to pre-wrap for all cells
                fontWeight: 'bold',
            },
        },
    };

    function formatDateToDisplay(dateString) {
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return new Date(dateString).toLocaleDateString('en-US', options);
    }

    const handlerOnSearch = (e) => {
        e.preventDefault();

        const form = e.target;
        const fromDate = form.from_date.value;
        const toDate = form.to_date.value;

        setTransactionFrom(fromDate);
        setTransactionTo(toDate);

        const filterData = {
            fromDate,
            toDate,
        };

        methodFilterPatientsTrailList(filterData);
    };

    const methodFilterPatientsTrailList = async (filterData) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/voucher/search/trail`,
                filterData
            );
            console.log('found', response?.data);
            const sortedDates = response?.data.map((item) => new Date(item?.createdAt));
            sortedDates.sort((a, b) => a - b);
            setInitialDateFetched(true);
            setTrailInfo(response?.data);

            // Calculate total debit for selected date range
            const debitSum = response?.data.reduce(
                (sum, item) => sum + parseFloat(item.debit || 0),
                0
            );
            const creditSum = response?.data.reduce(
                (sum, item) => sum + parseFloat(item.credit || 0),
                0
            );
            setTotalDebit(debitSum);
            setTotalCredit(creditSum);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='trail-balance'>
            <div className='card'>
                <div className='card-body'>
                    <div className='border p-2 rounded'>
                        <div className='card-box'>
                            <h6 className='mb-0 text-uppercase'>Trail Balance</h6>
                        </div>

                        <hr/>

                        <div className='shadow-none'>
                            <div className='border p-2 rounded'>
                                <div className=''>
                                    <form onSubmit={(e) => handlerOnSearch(e)}>
                                        <div className='d-flex gap-4 pb-2'>
                                            <div className='w-25'>
                                                <label className='col-form-label fw-bold'>
                                                    From Date
                                                </label>
                                                <div className=''>
                                                    <input
                                                        type='date'
                                                        data-date-format='YYYY MM DD'
                                                        name='from_date'
                                                        className='form-control'
                                                        placeholder='Date Picker...'
                                                        defaultValue={format(
                                                            new Date(),
                                                            'yyyy-MM-dd'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-25'>
                                                <label className='col-form-label fw-bold'>
                                                    To Date
                                                </label>
                                                <div className=''>
                                                    <input
                                                        type='date'
                                                        data-date-format='YYYY MM DD'
                                                        name='to_date'
                                                        className='form-control'
                                                        placeholder='Date Picker...'
                                                        defaultValue={format(
                                                            new Date(),
                                                            'yyyy-MM-dd'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-end'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-success border border-3 pt-1'
                                                    style={{
                                                        fontSize: '15px', // Increase the font size of header titles
                                                        backgroundColor: '#0d6efd',
                                                        color: 'white',
                                                        border: 'none',
                                                    }}
                                                >
                                                    Generate Trail Balance
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='print-datatable-scroll-container'>
                            <div className='datatable-scroll-container'>

                                {/*<DataTable*/}
                                {/*    columns={columns}*/}
                                {/*    data={trailInfo}*/}
                                {/*    highlightOnHover*/}
                                {/*    striped*/}
                                {/*    dense*/}
                                {/*    subHeader*/}
                                {/*    subHeaderAlign='right'*/}
                                {/*    subHeaderComponent={*/}
                                {/*        <div className='w-100 d-flex justify-content-start'>*/}
                                {/*            <div className='fw-bold fs-6'>*/}
                                {/*                {initialDateFetched === true ? (*/}
                                {/*                    <>*/}
                                {/*                        Date:{' '}*/}
                                {/*                        {formatDateToDisplay(transactionFrom) ===*/}
                                {/*                        'Invalid Date'*/}
                                {/*                            ? ''*/}
                                {/*                            : formatDateToDisplay(*/}
                                {/*                                transactionFrom*/}
                                {/*                            )}{' '}*/}
                                {/*                        {(transactionFrom !== '' &&*/}
                                {/*                            transactionTo !== '') === true*/}
                                {/*                            ? 'to'*/}
                                {/*                            : ''}{' '}*/}
                                {/*                        {formatDateToDisplay(transactionTo) ===*/}
                                {/*                        'Invalid Date'*/}
                                {/*                            ? ''*/}
                                {/*                            : formatDateToDisplay(*/}
                                {/*                                transactionTo*/}
                                {/*                            )}{' '}*/}
                                {/*                    </>*/}
                                {/*                ) : (*/}
                                {/*                    'Date : '*/}
                                {/*                )}*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    }*/}
                                {/*    noDataComponent='Select Dates to Check Records'*/}
                                {/*    subHeaderWrap*/}
                                {/*    fixedHeader*/}
                                {/*    fixedHeaderScrollHeight*/}
                                {/*    {...config}*/}
                                {/*    extraButtons={extraButtons}*/}
                                {/*    customStyles={customStyles}*/}
                                {/*/>*/}
                            </div>
                            {/*{initialDateFetched ? (*/}
                            {/*    <div className='sc-jrcSHq kbohHU rdt_TableRow border-borrom border-dark'>*/}
                            {/*        <div className='sc-hLBatu sc-eDvShL sc-jSVask esyJSl hkpyhU lmTzXr rdt_TableCell'/>*/}
                            {/*        <div className='sc-hLBatu sc-eDvShL sc-jSVask esyJSl hkpyhU lmTzXr rdt_TableCell'/>*/}
                            {/*        <div*/}
                            {/*            className='sc-hLBatu sc-eDvShL sc-jSVask esyJSl hkpyhU lmTzXr rdt_TableCell amount-table'>*/}
                            {/*            Total*/}
                            {/*        </div>*/}
                            {/*        <div*/}
                            {/*            className='sc-hLBatu sc-eDvShL sc-jSVask esyJSl hkpyhU lmTzXr rdt_TableCell amount-table'>*/}
                            {/*            {totalDebit}*/}
                            {/*        </div>*/}
                            {/*        <div*/}
                            {/*            className='sc-hLBatu sc-eDvShL sc-jSVask esyJSl hkpyhU lmTzXr rdt_TableCell amount-table'>*/}
                            {/*            {totalCredit}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*) : (*/}
                            {/*    ''*/}
                            {/*)}*/}


                            {/*tables */}

                            <Table striped bordered hover className='mt-3'>
                                <thead>
                                <tr>

                                    <th>SL NO</th>
                                    <th>Code</th>
                                    <th>Head Name</th>
                                    <th>Debit</th>
                                    <th>Credit</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    trailInfo?.length > 0 && trailInfo.map((trail, i) => (<tr>
                                        <td>{i + 1}</td>
                                        <td>{trail?.accountHead?.code}</td>
                                        <td>{trail?.accountHead?.name}</td>
                                        <td>{trail?.debit}</td>
                                        <td>{trail?.credit}</td>

                                    </tr>))
                                }

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>total</td>
                                    <td>{totalDebit}</td>
                                    <td>{totalCredit}</td>
                                </tr>


                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-end gap-2 mt-2 unfit-button p-2'>
                    <button className='btn btn-primary' onClick={() => window.print()}>
                        Print
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TrailBalance;
