import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../Context/UserContextAPI";
import CreatePackageCat from "./CreatePackageCat";
import EditPackageCat from "./editPackageCat";
import DeleteModal from "../../../components/Modal/DeleteModal";
import toast from "react-hot-toast";

const instCatUrl = `${process.env.REACT_APP_API_BASE_URL}/package-category`;

const PackageCategoryList = () => {
    const {accessPerm} = useContext(UserContext);
    const [selectedId, setSelectedId] = useState('')

    const [modalOpen, setModalOpen] = useState('') // create, update, delete

    const [data, setData] = useState([]);


    const columns = [
        {
            key: "name",
            text: "Name",
            align: "left",
            sortable: true,
        },
        {
            key: "info",
            text: "Info",
            align: "left",
            sortable: true,
        },
        {
            key: "icon",
            text: "icon",
            align: "left",
            sortable: true,
        },
        {
            key: "multi_time_subscription",
            text: "Multi Time Subscription",
            className: "status",
            sortable: true,
            cell: (data) => (
                <span>{data.multi_time_subscription === 1 ? "Yes" : "No"}</span>
            ),
        },
        {
            key: "status",
            text: "Status",
            className: "status",
            sortable: true,
            cell: (record) => <span>{record.status === 1 ? "active" : "inactive"}</span>,
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: (record) => {
                return (
                    <>
                        <div className="col">
                            {/* Button trigger modal */}
                            {accessPerm(33, 2) && (
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    style={{marginRight: "5px"}}
                                    onClick={() => {
                                        setSelectedId(record.id);
                                        setModalOpen('update');
                                    }}
                                >
                                    <i className="fa fa-edit"></i>
                                </button>
                            )}


                        </div>
                        <div className="col">
                            {/* Button trigger modal */}
                            {accessPerm(33, 3) && (
                                <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    style={{marginRight: "5px"}}
                                    onClick={() => {
                                        setSelectedId(record.id);
                                        setModalOpen('delete');
                                    }}
                                >
                                    <i className="fa fa-trash"></i>
                                </button>
                            )}

                            {/* Activity */}

                        </div>
                    </>
                );
            },
        },
    ];
    const config = {
        page_size: 10,
        show_filter: true,
        show_length_menu: true,
        show_pagination: true,
        pagination: "advance",
        length_menu: [10, 50, 100],
        button: {
            excel: true,
            print: true,
            extra: true,
        },
    };


    const fetchData = () => {
        axios
            .get(instCatUrl)
            .then((response) => {

                setData(response.data.data);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, []);


    const deleteData = async (id) => {
        await axios
            .delete(`${process.env.REACT_APP_API_BASE_URL}/package-category/${selectedId}`)
            .then((response) => {
                console.log(response)
                if (response?.status === 200) {
                    toast.success("Successfully deleted")
                    setModalOpen('')
                    fetchData();
                } else {
                    toast.error("There was Some server side error")
                }

            })
            .catch((error) => console.log(error));
    };


    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">
                        <div className="card-box">
                            <h6 className="mb-0 text-uppercase">Package Category List</h6>
                            <div className="col">
                                {/* Button trigger modal */}
                                {accessPerm(33, 1) && (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => setModalOpen('create')}
                                    >
                                        <i className="fa-solid fa-plus"></i> Add New
                                    </button>
                                )}
                            </div>

                        </div>

                        <hr/>

                        <ReactDatatable
                            config={config}
                            records={data}
                            columns={columns}
                            extraButtons={[]}
                        />
                    </div>
                </div>
            </div>

            {
                modalOpen === 'create' &&
                <CreatePackageCat isOpen={modalOpen === 'create'} onClose={() => setModalOpen('')}
                                  refresh={() => fetchData()}/>
            }

            {
                modalOpen === 'update' &&
                <EditPackageCat isOpen={modalOpen === 'update'} onClose={() => setModalOpen('')}
                                editData={data?.find(item => item.id === selectedId)} refresh={() => fetchData()}/>
            }

            {
                modalOpen === 'delete' &&
                <DeleteModal isOpen={modalOpen === 'delete'} onClose={() => setModalOpen('')}
                             handleDelete={deleteData}/>
            }
        </>
    );
};

export default PackageCategoryList;
