export default function formatDateTime(inputDateTime) {
  // Parse the input date and time string
  const inputDate = new Date(inputDateTime);

  // Get the individual date and time components
  const year = inputDate.getFullYear().toString().slice(-2); // Get the last two digits of the year
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const day = inputDate.getDate().toString().padStart(2, "0");
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Format the date and time as 'YY-MM-DD, h:mm AM/PM'
  const formattedDateTime = `${day}-${month}-${year}, ${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;

  return formattedDateTime;
}