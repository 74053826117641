import React, { useContext, useEffect, useState } from 'react'
import ReactDatatable from "@ashvin27/react-datatable";
import formatDateTime from "../../utils/DateTimeFormate";
import { UserContext } from '../../Context/UserContextAPI';
import Button from 'react-bootstrap/esm/Button';
import axios from 'axios';

export default function FailedTransaction() {
  const [paymentData, setPaymentData] = useState([])


  // successful payment data fetch

  useEffect(()=>{
    (async function(){
      try {
        const {data} = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/services/service-history/transaction/failed`);
        // console.log(data)

        setPaymentData(data?.data)
        
      } catch (error) {
        console.log(error)
      }
    }())
    
  },[])


  const columns = [
    {
      key: "user_id",
      text: "User id",
      align: "center",
      // sortable: true,
      cell : data => <span>{data?.user?.id}</span>
    },
    {
      key: "user_name",
      text: "User Name",
      align: "center",
      // sortable: true,
      cell : data => <span>{data?.user?.f_name}</span>
    },
    {
      key: "user_mobile",
      text: "User Mobile",
      align: "center",
      // sortable: true,
      cell : data => <span>{data?.user?.mobile}</span>
    },
    {
      key: "product_category",
      text: "Payment Type",
      align: "center",
      // sortable: true,
    },
    {
      key: "product_name",
      text: "Service Name/ID",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      
    },
    {
      key: "product_profile",
      text: "Service Type",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
    
    },
    {
      key: "total_amount",
      text: "Paid Amount",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell : data => <span>{data?.total_amount} TK</span>
    
    },
    {
      key: "tran_id",
      text: "transaction ID",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      
    },
    {
      key: "card_brand",
      text: "Payment Method",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      
    },
    {
      key: "tran_date",
      text: "Date",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: true,
      cell: (data) => {
        return <span>{formatDateTime(data.tran_date)}</span>;
      },
    },
    {
      key: "status",
      text: "status",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <span>
            Failed
          </span>
        );
      },
    },
    
  ];


  return (
    <div>
        <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">
                All Failed transaction
              </h6>
              <div className="col">{/* Modal */}</div>
            </div>

            <hr />

            <ReactDatatable
              // config={config}
              records={paymentData}
              columns={columns}
              // extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
