import { Form, Modal, Button } from "react-bootstrap";

const CashOnDeliveryForm = ({ isOpen, onClose, apiHandler, defaultData }) => {
  const formHandler = (event) => {
    event.preventDefault();
    const target = event.currentTarget;
    const formData = new FormData(target);
    const formValue = {
      ...Object.fromEntries(formData),
      status: event.target.status.checked ? 1 : 0,
    };
    if (apiHandler) {
      return apiHandler(formValue);
    }
  };
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header>
        <h5> Add New Cash On delivery</h5>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={formHandler}>
          <Form.Group>
            <Form.Label>Module Name</Form.Label>

            <Form.Control
              name="module_name"
              required
              defaultValue={defaultData?.module_name}
            />
          </Form.Group>

          <div className="d-flex justify-content-end mt-4">
            <Form.Group>
              <Form.Check
                name="status"
                label="Active"
                defaultChecked={
                  defaultData
                    ? defaultData?.status === 1
                      ? true
                      : false
                    : true
                }
              />
            </Form.Group>
          </div>

          <div className="d-flex justify-content-end mt-2">
            <Button type="submit">Save</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CashOnDeliveryForm;
