import React, {useState} from 'react';
import axios from "axios";
import FilterForm from "../../components/FinancialReports/FilterForms";
import ReactDatatable from "@ashvin27/react-datatable";

const IncomeStatements = () => {

    const [incomeList, setIncomeList] = useState(null);

    const filterController = async (formData) => {
        try {
            console.log(formData);
            const {data} = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/financial-reports/income-reports`, formData);

            if (data.status === 'OK') {
                setIncomeList(data.data)
            }
            console.log(data)

        } catch (e) {
            console.log(e);
        }

    };


    const columns = [
        {
            key: "product_category",
            text: "Product Category",
            align: "center",
            // sortable: true,
            // cell: (data) => <span>{data?.pharmacy?.registration_no}</span>,
        },
        {
            key: "final_amount",
            text: "Total Revenue ",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => <span>{data?.final_amount} TK</span>,
        }
    ];


    const config = {
        // page_size: 10,
        show_filter: false,
        show_length_menu: false,
        show_pagination: false,
        // pagination: "advance",
        // length_menu: [10, 50, 100],
        // button: {
        //     extra: false,
        // },
    };

    // calculate total sum of total_amount ;

    const net_income = incomeList && incomeList.reduce((acc, item) => acc + Number(item?.final_amount), 0)
    console.log(incomeList)

    return (
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">
                        <div className="card-box">
                            <h6 className="mb-0 text-uppercase">Income Statement List</h6>

                        </div>

                        <div>

                            <FilterForm apiHandler={filterController}/>
                        </div>

                        <hr/>


                        {
                            (incomeList && incomeList.length > 0) ?
                                <div>
                                    <h5>Revenues : </h5>
                                    <ReactDatatable
                                        config={config}
                                        records={incomeList}
                                        columns={columns}
                                    />

                                    <hr/>

                                    <h5 className='text-end'>Net Income : {net_income} TK </h5>
                                </div> :
                                (incomeList && incomeList.length === 0) ?
                                    <h6 className='text-center text-uppercase'>No Data FOUND ! </h6> :
                                    <h6 className='text-center text-uppercase'>Search with Any date Range</h6>
                        }

                    </div>
                </div>
            </div>

        </section>
    );
};

export default IncomeStatements;