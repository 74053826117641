import React from 'react';
import useFetchData from "../../hooks/useFetchData";
import CardLayout from "../Layout/CardLayout";
import ReactTable from "../ReactDataTable/ReactTable";
import {dateFormate, format24to12} from "../../utils/timeAndDateFunction";
import formatDateTime from "../../utils/DateTimeFormate";
import {getAppointmentReqTitle} from "../../utils/data/requestStatus";

const AppointmentRequest = ({user_id, role_id}) => {
    const {data: appointmentreq} = useFetchData(`request/appointment/admin/get-all/${user_id}`)

    const userInfo = [

        {
            key: "req_by",
            text: "Request By",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.f_name}</span>;
            },
        },
        {
            key: "reg_no",
            text: " Registration No:",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.registration_no}</span>;
            },
        },
        {
            key: "mobile",
            text: "Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.mobile}</span>;
            },
        },
    ]

    const doctorInfo = [
        {
            key: "Doctor_Name",
            text: "Doctor Name",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.schedules?.chamber_info?.doctor_info?.f_name}</span>;
            },
        },
        {
            key: "doctor_mobile",
            text: "Doctor Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.schedules?.chamber_info?.doctor_info?.mobile}</span>;
            },
        },
    ]

    const lastpart = [
        {
            key: "appointment_type",
            text: " Appointment Type",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{
                    data?.schedules?.chamber_info?.appointment_type === 1 ? "Online"
                        : data?.schedules?.chamber_info?.appointment_type === 2 ? "Physical"
                            : "Online and Physical"
                }</span>;
            },
        },
        {
            key: "appointment_date",
            text: "Appointment Date",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{dateFormate(data.appointment_date)} ({data?.schedules?.day?.name}) </span>;
            },
        },
        {
            key: "Schedule",
            text: "Schedule Time",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: true,
            cell: (data) => {
                return <span>{format24to12(data?.schedules?.start_time)} - {format24to12(data?.schedules?.end_time)}</span>;
            },
        },
        {
            key: "online_schedules",
            text: "Online Schedule",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.online_schedule && format24to12(data?.online_schedule)}</span>;
            },
        },
        {
            key: "chamber_name",
            text: "Chamber Name",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.schedules?.chamber_info?.name}</span>;
            },
        },
        {
            key: "chamber_address",
            text: "Chamber Address",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>
                    {data?.schedules?.chamber_info?.address} , {data?.schedules?.chamber_info?.city_info?.name}, {data?.schedules?.chamber_info?.state_info?.name}
                </span>;
            },
        },

        {
            key: "createdAt",
            text: "Request Date",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: true,
            cell: (data) => {
                return <span>{formatDateTime(data.createdAt)}</span>;
            },
        },

        {
            key: "status",
            text: "status",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return (
                    <span>{getAppointmentReqTitle(data?.status)}</span>
                );
            },
        },
        // {
        //   key: "action",
        //   text: "Action",
        //   // className: 'action',
        //   width: 120,
        //   align: "center",
        //   sortable: false,
        //   cell: (data) => {
        //     return (
        //       <>
        //         {accessPerm(39, 8) && data.status === 5 && (
        //           <Button
        //             onClick={() => {
        //               setShowUploadReport(true);
        //               setSingleItem(data);
        //             }}
        //             variant="success"
        //           >
        //             Upload Report
        //           </Button>
        //         )}
        //       </>
        //     );
        //   },
        // },
    ];

    let columns = [
        {
            key: "req_no",
            text: "Request No:",
            align: "center",
            sortable: true,
        },
    ]

    if (role_id === 10) {
        columns = [...columns, ...doctorInfo, ...lastpart]
    } else if (role_id === 11) {
        columns = [...columns, ...userInfo, ...lastpart]
    }

    return (
        <section>
            <CardLayout title={"Appointments History"}>
                <div className='overflow-auto'>
                    <ReactTable
                        data={appointmentreq?.data}
                        columns={columns}
                    />
                </div>
            </CardLayout>

        </section>
    );
};

export default AppointmentRequest;