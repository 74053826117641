import React, {useState} from 'react';
import useFetchData from "../../hooks/useFetchData";
import CardLayout from "../Layout/CardLayout";
import ReactTable from "../ReactDataTable/ReactTable";
import {Button} from "react-bootstrap";
import formatDateTime from "../../utils/DateTimeFormate";
import RoomInfo from "../../Pages/TreatmentRequest/RoomInfo";
import ServiceChargeinfo from "../../Pages/TreatmentRequest/ServiceChargeinfo";
import {getTreatmentRequestTitle} from "../../utils/data/requestStatus";

const TreatmentRequestList = ({user_id, role_id}) => {
    const {data: treatmentData} = useFetchData(`request/treatments/admin/get-all/${user_id}`);
    console.log(user_id);

    const [modalOpen, setModalOpen] = useState('');
    const [selectedData, setSelectedData] = useState([]);

    const lastPart = [

        {
            key: "treatment_date",
            text: "Treatment Date",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            // cell: data => <span>{dateFormate(data.appointment_date)}</span>
        },
        {
            key: "specification_fields",
            text: "Specification Fields",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.specific_fields.map(spec => spec.service_cat_info?.name)?.join(',')}</span>;
            },
        },
        {
            key: "room_info",
            text: "Room Information",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <Button
                    onClick={() => {
                        setModalOpen('room_info');
                        setSelectedData([data.room])
                    }}
                    size='sm'
                >
                    See Details
                </Button>;
            },
        },
        {
            key: "service_charges",
            text: "Service Charges Info",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <Button
                    variant='primary'
                    onClick={() => {
                        const serviceCharge = data.service_charges.map(item => item.service_charge_info)
                        setModalOpen('service');
                        setSelectedData(serviceCharge)
                    }}
                    size='sm'
                >
                    See Details
                </Button>;
            },
        },
        {
            key: "total_amount",
            text: "Total Amount",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.total_amount} TK</span>;
            },
        },
        {
            key: "paid_amount",
            text: "Paid Amount",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.paid_amount ? `${data?.paid_amount} TK` : ''}</span>;
            },
        },
        {
            key: "due_amount",
            text: "Due Amount",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.due_amount ? `${data?.due_amount} TK` : ''}</span>;
            },
        },
        {
            key: "createdAt",
            text: "Request Date",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{formatDateTime(data.createdAt)}</span>;
            },
        },

        {
            key: "status",
            text: "Status",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                const {title, className} = getTreatmentRequestTitle(data.status)
                return (
                    <p className={className}>{title}</p>
                );
            },
        },
        // {
        //   key: "action",
        //   text: "Action",
        //   // className: 'action',
        //   width: 120,
        //   align: "center",
        //   sortable: false,
        //   cell: (data) => {
        //     return (
        //       <>
        //         {accessPerm(39, 8) && data.status === 5 && (
        //           <Button
        //             onClick={() => {
        //               setShowUploadReport(true);
        //               setSingleItem(data);
        //             }}
        //             variant="success"
        //           >
        //             Upload Report
        //           </Button>
        //         )}
        //       </>
        //     );
        //   },
        // },
    ];

    const userInfoColumn = [
        {
            key: "reg_no",
            text: "Registration No:",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.registration_no}</span>;
            },
        },
        {
            key: "req_by",
            text: "Request By",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.f_name}</span>;
            },
        },
        {
            key: "mobile",
            text: "Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.mobile}</span>;
            },
        },
    ]

    const hospitalInfo = [

        {
            key: "hospital_name",
            text: "Hospitals Name",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.hospital?.f_name}</span>;
            },
        },
        {
            key: "hospital_address",
            text: "Hospital Address",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>
                    {data?.hospital?.address_1}, {data?.hospital?.user_detail?.city?.name} , {data?.hospital?.user_detail?.state?.name} , {data?.hospital?.user_detail?.country?.name}
                </span>;
            },
        },
        {
            key: "hospital_mobile",
            text: "Hospital Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.hospital?.mobile}</span>;
            },
        },
    ]

    let columns = [{
        key: "req_no",
        text: "Request No:",
        align: "center",
        sortable: true,
    }]

    if (role_id === 10) {
        columns = [...columns, ...hospitalInfo, ...lastPart]
    } else if (role_id === 13) {
        columns = [...columns, ...userInfoColumn, ...lastPart]
    }
    console.log(role_id)

    return (
        <section>
            <CardLayout title={"Investigation History"}>
                <div className='overflow-auto'>
                    <ReactTable
                        data={treatmentData?.data}
                        columns={columns}
                    />
                </div>
            </CardLayout>

            {
                modalOpen === "room_info" &&
                <RoomInfo isOPen={modalOpen === 'room_info'} onClose={() => setModalOpen('')} data={selectedData}/>
            }

            {
                modalOpen === "service" &&
                <ServiceChargeinfo isOPen={modalOpen === 'service'} onClose={() => setModalOpen('')}
                                   data={selectedData}/>
            }

        </section>
    );
};

export default TreatmentRequestList;