import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Col, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {toast} from "react-hot-toast";

const EditServiceCharge = ({isOpen, onClose, editData, refresh}) => {

    const [updateData, setUpdateData] = useState(editData)

    const formValueController = (data) => {

        setUpdateData(prevState => {
            prevState[data.name] = data.type === 'checkbox' ? data.checked : data.value;
            return {...prevState}
        })
    }

    const updateDataHandler = async () => {
        try {
            const {data} = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/hospital/service_charge/${editData?.id}`,
                updateData,
            );
            console.log(data)
            if (data.status === 'OK') {
                toast.success("Service Charge Updated Successfully")
                refresh();
                onClose();
            }

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Modal show={isOpen} onHide={onClose} size='lg'>
            <Modal.Header closeButton={true}>
                Edit Service Charge
            </Modal.Header>
            <Modal.Body>
                <Form.Group className='my-3'>

                    <div className='d-flex'>
                        <Row className='align-items-center'>
                            <Col sm={2} md={3}>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter editData name"
                                    name='name'
                                    value={updateData.name}
                                    onChange={(e) => formValueController(e.target)}
                                />
                            </Col>
                            <Col sm={2} md={3}>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter Price"
                                    name='price'
                                    value={updateData.price}
                                    onChange={(e) => formValueController(e.target)}
                                />
                            </Col>
                            <Col sm={2} md={3}>
                                <Form.Control
                                    type="number"
                                    placeholder="Discounted Price"
                                    name='discounted_price'
                                    value={updateData.discounted_price}
                                    onChange={(e) => formValueController(e.target)}
                                />
                            </Col>
                            <Col sm={2} md={3}>
                                <Form.Check
                                    inline={true}
                                    label='Pre-paid'
                                    name={'pre_paid'}
                                    checked={updateData.pre_paid}
                                    onChange={(e) => formValueController(e.target)}
                                />
                                <Form.Check
                                    inline={true}
                                    label='Status'
                                    name={'status'}
                                    checked={updateData.status}
                                    onChange={(e) => formValueController(e.target)}
                                />
                            </Col>
                        </Row>
                    </div>


                </Form.Group>


                <div className='text-end mt-5'>
                    <Button onClick={updateDataHandler}>
                        Update
                    </Button>
                </div>

            </Modal.Body>

        </Modal>
    );
};

export default EditServiceCharge;