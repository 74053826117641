import { RouterProvider } from 'react-router-dom';
import router from './Routers/Router';
import './index.css';
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from 'react';
import axios from 'axios';

function App() {
  useEffect(()=>{
    (async function (){ 
      try { 
        const settings = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/settings`);
        settings && document.getElementById('favicon').setAttribute('href',`${process.env.REACT_APP_UPLOAD_URL}/${settings?.data?.data[0]?.fav_image}`)
        // console.log('first' , settings.data.data[0].fav_image)
        
      } catch (error) {
        
      } 
    }())    
    
  },[])
  return (
    <>
      <div className='App'>{<RouterProvider router={router} />}</div>
    </>
  );
}

export default App;
