import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../../Context/UserContextAPI";
import axios from "axios";
import formatDateTime from "../../utils/DateTimeFormate";
import {Form} from "react-bootstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import {getAmbulanceRequestTitle} from "../../utils/data/requestStatus";

const AmbulanceRequestList = () => {
    const [getAllAmbulanceRequest, setAllAmbulanceRequest] = useState([]);
    const [filteredAmbulanceRequest, setFilteredAmbulanceRequest] = useState([]);

    const {accessPerm} = useContext(UserContext);

    useEffect(() => {
        (async function () {
            try {
                const {data} = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/request/ambulance-service/admin/get-all`,
                );
                console.log("data", data.data);
                setAllAmbulanceRequest(data.data);
                setFilteredAmbulanceRequest(data.data);
            } catch (error) {
                console.log("error while get all req list", error);
            }
        })();
    }, []);

    const columns = [
        {
            key: "req_no",
            text: "Request No:",
            align: "center",
            sortable: true,
        },
        {
            key: "request_cat",
            text: "Request Type",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => <span>{data?.service_category?.name}</span>
        },
        {
            key: "reg_no",
            text: "Registration No:",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.registration_no}</span>;
            },
        },
        {
            key: "req_by",
            text: "Request By",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.f_name}</span>;
            },
        },
        {
            key: "mobile",
            text: "Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.mobile}</span>;
            },
        },
        {
            key: "ambulance",
            text: " Ambulance Name",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.accepted_ambulance?.f_name}</span>;
            },
        },
        {
            key: "ambulance_reg",
            text: " Ambulance Reg no",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: true,
            cell: (data) => {
                return <span>{data.accepted_ambulance?.registration_no}</span>;
            },
        },
        {
            key: "ambulance_mobile",
            text: "Ambulance Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: true,
            cell: (data) => {
                return <span>{data.accepted_ambulance?.mobile}</span>;
            },
        },
        {
            key: "total_response",
            text: "Total Ambulance Response",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data.all_ambulance_response?.length}</span>;
            },
        },
        {
            key: "createdAt",
            text: "Request Date",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: true,
            cell: (data) => {
                return <span>{formatDateTime(data.createdAt)}</span>;
            },
        },

        {
            key: "status",
            text: "status",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return (
                    <span>{getAmbulanceRequestTitle(data?.status)}</span>
                );
            },
        },
        // {
        //   key: "action",
        //   text: "Action",
        //   // className: 'action',
        //   width: 120,
        //   align: "center",
        //   sortable: false,
        //   cell: (data) => {
        //     return (
        //       <>
        //         {accessPerm(39, 8) && data.status === 5 && (
        //           <Button
        //             onClick={() => {
        //               setShowUploadReport(true);
        //               setSingleItem(data);
        //             }}
        //             variant="success"
        //           >
        //             Upload Report
        //           </Button>
        //         )}
        //       </>
        //     );
        //   },
        // },
    ];
    const config = {
        page_size: 10,
        show_filter: false,
        show_length_menu: true,
        show_pagination: true,
        pagination: "advance",
        length_menu: [10, 50, 100],
        button: {
            extra: true,
        },
    };

    // search component

    const extraButtons = [
        {
            className: "bg-transparent border-0",

            title: "Search",
            children: [
                <Form.Group controlId="formBasicText">
                    <Form.Control
                        type={"search"}
                        placeholder={"Search"}
                        // value={value}
                        onChange={(e) => handlerFilter(e.target.value)}
                    />
                </Form.Group>,
            ],
            onClick: (event) => {
                // console.log(event);
            },
        },
    ];

    // search/filter functions

    const handlerFilter = (value) => {
        setFilteredAmbulanceRequest(
            getAllAmbulanceRequest.filter((item) => {
                return (
                    item?.req_no?.toString()?.includes(value.toLowerCase()) ||
                    item?.requester?.f_name
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    item?.accepted_ambulance?.f_name
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||


                    item?.requester?.registration_no
                        ?.toString()
                        ?.includes(value.toLowerCase()) ||
                    item?.requester?.mobile
                        ?.toString()
                        ?.includes(value.toLowerCase())
                );
            }),
        );
    };

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">
                        <div className="card-box">
                            <h6 className="mb-0 text-uppercase">All Ambulance Request List</h6>
                            <div className="col">{/* Modal */}</div>
                        </div>

                        <hr/>

                        <ReactDatatable
                            config={config}
                            records={filteredAmbulanceRequest}
                            columns={columns}
                            extraButtons={extraButtons}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AmbulanceRequestList;