import React from 'react';
import useFetchData from "../../hooks/useFetchData";
import CardLayout from "../Layout/CardLayout";
import ReactTable from "../ReactDataTable/ReactTable";
import formatDateTime from "../../utils/DateTimeFormate";
import {getDiagnosisRequestStatus} from "../../utils/data/requestStatus"

const InvestigationRequest = ({user_id, role_id}) => {
    const {data: testreqData} = useFetchData(`services/request/test/admin/getAll/${user_id}`)

    const firstPartOfColumn = [
        {
            key: "req_no",
            text: "Request No:",
            align: "center",
            sortable: true,
        },
        {
            key: "req_type",
            text: "Request Type",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return (
                    <span>
            {data.req_type === 1
                ? "General"
                : data.req_type === 2
                    ? "Selective"
                    : "unknown"}
          </span>
                );
            },
        },
        {
            key: "select_type",
            text: "Select Type",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return (
                    <span>
            {data.req_type === 2
                ? "Prescription Pictures"
                : data.req_type === 1
                    ? "Investigation"
                    : "unknown"}
          </span>
                );
            },
        },
    ]

    const lastPart = [

        {
            key: "createdAt",
            text: "Request Date",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: true,
            cell: (data) => {
                return <span>{formatDateTime(data.createdAt)}</span>;
            },
        },
        {
            key: "schedule_date",
            text: "Schedule Date",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: true,
            cell: (data) => {
                return (
                    <span>
            {data.schedule_date && formatDateTime(data.schedule_date)}
          </span>
                );
            },
        },
        {
            key: "status",
            text: "status",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return (
                    <span> {getDiagnosisRequestStatus(data?.status)}</span>
                );
            },
        },
        // {
        //     key: "action",
        //     text: "Action",
        //     // className: 'action',
        //     width: 120,
        //     align: "center",
        //     sortable: false,
        //     cell: (data) => {
        //         return (
        //             <>
        //                 {accessPerm(39, 7) && data.status === 2 && (
        //                     <button
        //                         onClick={() => {
        //                             setShowSchedule(true);
        //                             setSingleItem(data);
        //                         }}
        //                         className="btn btn-primary btn-sm"
        //                     >
        //                         Set Schedule
        //                     </button>
        //                 )}
        //                 {accessPerm(39, 8) && data.status === 5 && (
        //                     <Button
        //                         onClick={() => {
        //                             setShowUploadReport(true);
        //                             setSingleItem(data);
        //                         }}
        //                         variant="success"
        //                     >
        //                         Upload Report
        //                     </Button>
        //                 )}
        //             </>
        //         );
        //     },
        // },
    ];

    const userInfoColumns = [
        {
            key: "req_by",
            text: "Request By",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.test_requester?.f_name}</span>;
            },
        },
        {
            key: "reg_no",
            text: "Registration No:",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.test_requester?.registration_no}</span>;
            },
        },
        {
            key: "mobile",
            text: "Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.test_requester?.mobile}</span>;
            },
        },
    ]

    const serviceCenterInfoColumn = [
        {
            key: "service_center_id",
            text: "Service Center",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return (
                    <span>
            {data?.confirm_service_center?.f_name}
          </span>
                );
            },
        },
        {
            key: "service_center_reg",
            text: "Service Center Reg.",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return (
                    <span>
            {data?.confirm_service_center?.registration_no}
          </span>
                );
            },
        },
        {
            key: "service_center_mobile",
            text: "Service Center Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return (
                    <span>
            {data?.confirm_service_center?.mobile}
          </span>
                );
            },
        },
    ]

    let columns = [];

    if (role_id === 10) {
        columns = [...columns, ...firstPartOfColumn, ...serviceCenterInfoColumn, ...lastPart]
    } else if (role_id === 13) {
        columns = [...columns, ...firstPartOfColumn, ...userInfoColumns, ...lastPart]
    }

    console.log((testreqData.data))
    return (
        <section>
            <CardLayout title={"Investigation History"}>
                <ReactTable
                    data={testreqData?.data}
                    columns={columns}
                />
            </CardLayout>

        </section>
    );
};

export default InvestigationRequest;