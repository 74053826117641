import React from 'react';
import axios from "axios";
import {toast} from "react-hot-toast";
import NameInfoForm from "../../../components/Form/NameInfoForm";

const UpdateAccommodationType = ({isOpen, onClose, editData, refresh}) => {

    const updateHandler = (formData) => {
        // console.log(formData);

        axios.put(`${process.env.REACT_APP_API_BASE_URL}/settings/accommodation/${editData?.id}`, formData)
            .then(res => {
                if (res.data.status === "OK") {
                    toast.success("Accommodation updated successfully")
                    onClose();
                    refresh();
                }
                console.log(res.data)
            })
            .catch(err => console.log(err))


    }

    return (
        <div>
            <NameInfoForm
                isOpen={isOpen}
                onClose={onClose}
                title={'Update Accommodation Type'}
                apiHandler={updateHandler}
                editData={editData}
            />
        </div>
    );
};

export default UpdateAccommodationType;