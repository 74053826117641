import axios from "axios";
import React, { useEffect, useState } from "react";
import { format, startOfYear } from "date-fns";
import "../ProfitLossStatement/ProfitLossStatement.scss";

const StatementFinancialPosition = () => {
  const [transactionFrom, setTransactionFrom] = useState(
    format(startOfYear(new Date()), "yyyy-MM-dd")
  );
  const [transactionTo, setTransactionTo] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [financialStatement, setFinancialStatement] = useState();

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;

    setTransactionFrom(fromDate);
    setTransactionTo(toDate);

    const filterData = {
      fromDate,
      toDate,
    };

    methodFilterFinancialStatement(filterData);
  };

  const methodFilterFinancialStatement = async (filterData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/voucher/search/financial-statement`,
        filterData
      );
      console.log("found", response?.data);
      setFinancialStatement(response?.data);
      const sortedDates = response?.data.map(
        (item) => new Date(item?.createdAt)
      );
      sortedDates.sort((a, b) => a - b);
    } catch (error) {
      console.log(error);
    }
  };

  const totalAmountForNonCurrentAssets = financialStatement
    ?.filter((item) => item?.classification_id === 1)
    .reduce(
      (accumulator, item) => accumulator + parseFloat(item?.amount || 0),
      0
    );
  const totalAmountForCurrentAssets = financialStatement
    ?.filter((item) => item?.classification_id === 2)
    .reduce(
      (accumulator, item) => accumulator + parseFloat(item?.amount || 0),
      0
    );
  const totalAmountForNonCurrentLiabilities = financialStatement
    ?.filter((item) => item?.classification_id === 3)
    .reduce(
      (accumulator, item) => accumulator + parseFloat(item?.amount || 0),
      0
    );
  const totalAmountForCurrentLiabilities = financialStatement
    ?.filter((item) => item?.classification_id === 4)
    .reduce(
      (accumulator, item) => accumulator + parseFloat(item?.amount || 0),
      0
    );
  const totalAmountForEquity = financialStatement
    ?.filter((item) => item?.classification_id === 5)
    .reduce(
      (accumulator, item) => accumulator + parseFloat(item?.amount || 0),
      0
    );

  const grossAsset =
    totalAmountForNonCurrentAssets + totalAmountForCurrentAssets;
  const grossLiability =
    totalAmountForCurrentLiabilities + totalAmountForNonCurrentLiabilities;
  const grossEquity = totalAmountForEquity;
  const netTotal = grossAsset - (grossLiability + grossEquity);

  const hasNonCurrentAssetsClassification = financialStatement?.some(
    (item) => item?.classification_id === 1
  );
  const hasCurrentAssetsClassification = financialStatement?.some(
    (item) => item?.classification_id === 2
  );
  const hasNonCurrentLiabilityClassification = financialStatement?.some(
    (item) => item?.classification_id === 3
  );
  const hasCurrentLiabilityClassification = financialStatement?.some(
    (item) => item?.classification_id === 4
  );
  const hasEquityClassification = financialStatement?.some(
    (item) => item?.classification_id === 5
  );

  useEffect(() => {
    const defaultSearch = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/voucher/search/financial-statement`,
          { fromDate: transactionFrom, toDate: transactionTo }
        );
        console.log("found", response?.data);
        setFinancialStatement(response?.data);
        const sortedDates = response?.data.map(
          (item) => new Date(item?.createdAt)
        );
        sortedDates.sort((a, b) => a - b);
      } catch (error) {
        console.log(error);
      }
    };
    defaultSearch();
  }, []);

  return (
    <div className="profit-loss">
      <div className="d-flex flex-column w-100 align-items-stretch">
        <div className="card shadow-none">
          <div className="statement-financial">
            <div className="border p-2 rounded">
              <div className="">
                <form onSubmit={(e) => handlerOnSearch(e)}>
                  <div className="d-flex gap-4 pb-2">
                    <div className="w-25">
                      <label className="col-form-label fw-bold">
                        From Date
                      </label>
                      <div className="">
                        <input
                          type="date"
                          data-date-format="YYYY MM DD"
                          name="from_date"
                          className="form-control"
                          placeholder="Date Picker..."
                          defaultValue={transactionFrom}
                        />
                      </div>
                    </div>
                    <div className="w-25">
                      <label className="col-form-label fw-bold">To Date</label>
                      <div className="">
                        <input
                          type="date"
                          data-date-format="YYYY MM DD"
                          name="to_date"
                          className="form-control"
                          placeholder="Date Picker..."
                          defaultValue={transactionTo}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-end">
                      <button
                        type="submit"
                        className="btn btn-success border border-3 pt-1"
                        style={{
                          fontSize: "15px", // Increase the font size of header titles
                          backgroundColor: "#0d6efd",
                          color: "white",
                          border: "none",
                        }}
                      >
                        Generate Balance Sheet
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="w-25 d-flex flex-column align-items-center w-100">
          <h5>EUREKA DIAGNOSTIC & MEDICAL CENTER</h5>
          <p>Statement of Financial Position</p>
          <p className="m-0 p-0">
            From {transactionFrom} To {transactionTo}
          </p>
          <p className="p-0">(All values in BDT)</p>
        </div>
        <div className="container mt-2">
          <table className="w-75 mx-auto table table-bordered">
            <thead>
              <tr>
                <th className="text-center">Particulars</th>
                <th className="text-center">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong className="font-weight">Asset</strong>
                </td>
                <td></td>
              </tr>

              {hasNonCurrentAssetsClassification &&
                totalAmountForNonCurrentAssets !== 0 && (
                  <tr>
                    <td  className="font-weight">
                      NON-CURRENT ASSET
                    </td>
                    <td
                      style={{ textAlign: "end", fontStyle: "italic" }}
                      className="font-weight"
                    >
                      {totalAmountForNonCurrentAssets}
                    </td>
                  </tr>
                )}

              {financialStatement?.map(
                (item) =>
                  item.classification_id === 1 &&
                  item.amount !== 0 && (
                    <tr key={item?.head_group_id}>
                      <td className=" font-weight">
                        {item.head_group_name}
                      </td>
                      <td style={{ textAlign: "end" }} className="font-weight">
                        {item.amount}
                      </td>
                    </tr>
                  )
              )}

              {hasCurrentAssetsClassification &&
                totalAmountForCurrentAssets !== 0 && (
                  <tr>
                    <td  className="font-weight">
                      CURRENT ASSET
                    </td>
                    <td
                      style={{ textAlign: "end", fontStyle: "italic" }}
                      className="font-weight"
                    >
                      {totalAmountForCurrentAssets}
                    </td>
                  </tr>
                )}

              {financialStatement?.map(
                (item) =>
                  item.classification_id === 2 &&
                  item.amount !== 0 && (
                    <tr key={item?.head_group_id}>
                      <td className=" font-weight">
                        {item.head_group_name}
                      </td>
                      <td style={{ textAlign: "end" }} className="font-weight">
                        {item.amount}
                      </td>
                    </tr>
                  )
              )}

              <tr>
                <td>
                  <strong>Gross Asset</strong>
                </td>
                <td style={{ textAlign: "end" }}>{grossAsset}</td>
              </tr>
              <tr>
                <td>
                  <strong className="font-weight">Liability</strong>
                </td>
                <td></td>
              </tr>
              {hasNonCurrentLiabilityClassification &&
                totalAmountForNonCurrentLiabilities !== 0 && (
                  <tr>
                    <td  className="font-weight">
                      NON-CURRENT LIABILITY
                    </td>
                    <td
                      style={{ textAlign: "end", fontStyle: "italic" }}
                      className="font-weight"
                    >
                      {totalAmountForNonCurrentLiabilities
                        ? totalAmountForNonCurrentLiabilities
                        : ""}
                    </td>
                  </tr>
                )}

              {financialStatement?.map(
                (item) =>
                  item?.classification_id === 3 &&
                  item?.amount !== 0 && (
                    <tr key={item?.head_group_id}>
                      <td className=" font-weight">
                        {item?.head_group_name}
                      </td>
                      <td className="font-weight">{item?.amount}</td>
                    </tr>
                  )
              )}

              {hasCurrentLiabilityClassification &&
                totalAmountForCurrentLiabilities !== 0 && (
                  <tr>
                    <td className="font-weight" >
                      CURRENT LIABILITY
                    </td>
                    <td
                      className="font-weight"
                      style={{ textAlign: "end", fontStyle: "italic" }}
                    >
                      {totalAmountForCurrentLiabilities
                        ? totalAmountForCurrentLiabilities
                        : ""}
                    </td>
                  </tr>
                )}

              {financialStatement?.map(
                (item) =>
                  item?.classification_id === 4 &&
                  item?.amount !== 0 && (
                    <tr key={item?.head_group_id}>
                      <td className=" font-weight">
                        {item?.head_group_name}
                      </td>
                      <td className="font-weight" style={{ textAlign: "end" }}>
                        {item?.amount}
                      </td>
                    </tr>
                  )
              )}

              <tr>
                <td>
                  <strong>Gross Liability</strong>
                </td>
                <td style={{ textAlign: "end" }}>{grossLiability}</td>
              </tr>
              <tr>
                <td>
                  <strong className="font-weight">Equity</strong>
                </td>
                <td></td>
              </tr>
              {hasCurrentLiabilityClassification &&
                totalAmountForCurrentLiabilities !== 0 && (
                  <tr>
                    <td className="font-weight" >
                      EQUITY
                    </td>
                    <td
                      className="font-weight"
                      style={{ textAlign: "end", fontStyle: "italic" }}
                    >
                      {totalAmountForEquity ? totalAmountForEquity : ""}
                    </td>
                  </tr>
                )}

              {financialStatement?.map(
                (item) =>
                  item?.classification_id === 5 &&
                  item?.amount !== 0 && (
                    <tr key={item?.head_group_id}>
                      <td className=" font-weight">
                        {item?.head_group_name}
                      </td>
                      <td className="font-weight" style={{ textAlign: "end" }}>
                        {item?.amount}
                      </td>
                    </tr>
                  )
              )}

              <tr>
                <td>
                  <strong>Gross Equity</strong>
                </td>
                <td style={{ textAlign: "end" }}>{grossEquity}</td>
              </tr>
              <tr>
                <td>
                  <strong>Net Balance</strong>
                </td>
                <td style={{ textAlign: "end" }}>{netTotal}</td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-center mt-5 unfit-button p-2">
            <button
              className="btn btn-primary btn-lg w-25"
              onClick={() => window.print()}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatementFinancialPosition;
