import React from 'react';
import DeleteModal from "../../Modal/DeleteModal";
import axios from "axios";
import {toast} from "react-hot-toast";

const DeleteServiceCharge = ({isOpen, onClose, deleteId, refresh}) => {

    const handleDelete = async () => {
        try {
            const {data} = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/hospital/service_charge/${deleteId}`,);
            console.log(data)
            if (data.status === 'OK') {
                toast.success("DELETED Successfully")
                refresh();
                onClose();
            }

        } catch (err) {
            console.log(err)
        }
    }
    return (
        <div>
            <DeleteModal
                isOpen={isOpen}
                onClose={onClose}
                handleDelete={handleDelete}
            />
        </div>
    );
};

export default DeleteServiceCharge;