const bloodGroups = {
    1: "A+",
    2: "A-",
    3: "B+",
    4: "B-",
    5: "O+",
    6: "O-",
    7: "AB+",
    8: "AB-",
};
export default bloodGroups;

export const getBloodGroupsName = (id) => bloodGroups[id];